import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Grid, TextField, IconButton, CircularProgress } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { CheckBoxOutlined } from "@material-ui/icons";
import {
  firestoreGetTemplateListByContact,
  firestoreAddTemplateItemToContact,
} from "../../../store/actions/firebase.action";

class TemplateDetailAdd extends Component {
  // ====================== Inits ====================== //

  constructor() {
    super();
    this.state = {
      loading: false,
      quantity: "1",
      unitAmount: "",
      selectedProduct: null,
    };
  }

  // ====================== Functions ====================== //

  selectedProduct = (selectedProduct) => {
    const salesUnitPrice = (selectedProduct && selectedProduct.salesUnitPrice) || 0;
    this.setState({
      selectedProduct,
      unitAmount: salesUnitPrice,
    });
  };

  handleChangeQuantity = (event) => {
    const value = event.target.value;
    const regex = /^[0-9]{0,3}$/;
    const valid = regex.test(value);
    if (!valid) {
      return;
    }
    this.setState({
      quantity: value,
    });
  };

  handleChangeUnitAmount = (event) => {
    const value = event.target.value;
    const regex = /^[0-9.]{0,10}$/;
    const valid = regex.test(value);
    if (!valid) {
      return;
    }
    this.setState({
      unitAmount: value,
    });
  };

  // ====================== Firebase Functions ====================== //

  handleAddTemplateItem = () => {
    const { selectedEditContact, branch } = this.props;
    const { selectedProduct, quantity, unitAmount } = this.state;
    this.setState(
      {
        loading: true,
      },
      () => {
        const contact = selectedEditContact;
        const product = selectedProduct;
        this.props.firestoreAddTemplateItemToContact(
          { contact, product, quantity, unitAmount, branch },
          () => {
            this.props.firestoreGetTemplateListByContact({ contact, branch }, () => {
              this.setState({
                loading: false,
                quantity: "1",
                unitAmount: "",
                selectedProduct: null,
              });
            });
          }
        );
      }
    );
  };

  // ====================== Render Component ====================== //

  renderAddAutocomplete = () => {
    const { products, contactTemplates, edit } = this.props;
    const { selectedProduct } = this.state;
    const disabled = !products;
    if (!edit) {
      return (
        <Autocomplete
          id="product-search"
          onChange={(e, value) => this.selectedProduct(value)}
          value={selectedProduct}
          getOptionSelected={(option) => option}
          getOptionDisabled={(option) => {
            const itemCode = option.itemCode;
            const exist =
              contactTemplates && contactTemplates.find((t) => t.inventoryItemCode === itemCode);
            return exist ? true : false;
          }}
          getOptionLabel={(option) => {
            const itemCode = option.itemCode;
            const itemName = option.itemName;
            return `${itemCode} - ${itemName}`;
          }}
          options={products || []}
          disabled={disabled}
          renderInput={(params) => (
            <TextField {...params} margin="dense" label="Code" variant="outlined" />
          )}
        />
      );
    }
    return (
      <TextField
        variant="outlined"
        margin="dense"
        required
        fullWidth
        type="text"
        autoFocus
        value=""
        disabled
      />
    );
  };

  renderCheckButton = () => {
    const { edit } = this.props;
    const { loading, selectedProduct, quantity } = this.state;
    const disabled = edit || !selectedProduct || !quantity || quantity === "0" || loading;
    if (loading && !edit) {
      return (
        <IconButton aria-label="loading" disabled={true} size="small">
          <CircularProgress size={30} />
        </IconButton>
      );
    }
    return (
      <IconButton
        aria-label="check"
        size="small"
        onClick={() => this.handleAddTemplateItem()}
        disabled={disabled}
      >
        <CheckBoxOutlined style={{ color: disabled ? "#c8c8c8" : "green", fontSize: 30 }} />
      </IconButton>
    );
  };

  renderItemName = () => {
    const { selectedProduct } = this.state;
    const { edit } = this.props;
    if (!edit) {
      const itemName = (selectedProduct && selectedProduct.itemName) || "";
      return (
        <TextField
          variant="outlined"
          margin="dense"
          required
          fullWidth
          type="text"
          autoFocus
          disabled
          value={itemName}
          className="template-item-description"
        />
      );
    }
    return (
      <TextField
        variant="outlined"
        margin="dense"
        required
        fullWidth
        type="text"
        autoFocus
        disabled
        value=""
        className="template-item-description"
      />
    );
  };

  renderQuantity = () => {
    const { edit } = this.props;
    const { quantity } = this.state;
    if (!edit) {
      return (
        <TextField
          variant="outlined"
          margin="dense"
          required
          fullWidth
          type="text"
          autoFocus
          value={quantity}
          onChange={this.handleChangeQuantity}
          className="template-item-quantity"
        />
      );
    }
    return (
      <TextField
        variant="outlined"
        margin="dense"
        required
        fullWidth
        type="text"
        autoFocus
        value=""
        disabled
        className="template-item-quantity"
      />
    );
  };

  renderPrice = () => {
    const { edit } = this.props;
    const { unitAmount } = this.state;
    if (!edit) {
      return (
        <TextField
          variant="outlined"
          margin="dense"
          required
          fullWidth
          type="text"
          autoFocus
          value={unitAmount}
          onChange={this.handleChangeUnitAmount}
          className="template-item-price"
        />
      );
    }
    return (
      <TextField
        variant="outlined"
        margin="dense"
        required
        fullWidth
        type="text"
        autoFocus
        value=""
        disabled
        className="template-item-price"
      />
    );
  };

  // ====================== Render Main ====================== //

  render() {
    const { selectedEditContact, contactTemplates, loading } = this.props;
    if (selectedEditContact && contactTemplates && !loading) {
      return (
        <Grid
          container
          spacing={1}
          direction="row"
          justify="flex-start"
          alignItems="center"
          className="template-list"
        >
          <Grid item lg={3} md={12} sm={12} xs={12}>
            {this.renderAddAutocomplete()}
          </Grid>
          <Grid item lg={4} md={12} sm={12} xs={12}>
            {this.renderItemName()}
          </Grid>
          <Grid item lg={1} md={12} sm={12} xs={12}>
            {this.renderQuantity()}
          </Grid>
          <Grid item lg={2} md={12} sm={12} xs={12}>
            {this.renderPrice()}
          </Grid>
          <Grid item lg={2} md={12} sm={12} xs={12}>
            {this.renderCheckButton()}
          </Grid>
        </Grid>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => ({
  products: state.firebaseWeb.products,
  contactTemplates: state.firebaseWeb.contactTemplates,
});

const mapDispatchToProps = {
  firestoreGetTemplateListByContact,
  firestoreAddTemplateItemToContact,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(TemplateDetailAdd);
