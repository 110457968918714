export const allProgress = (proms, progress_cb) => {
    let d = 0;
    progress_cb(0);
    for (const p of proms) {
      // eslint-disable-next-line no-loop-func
      p.then(()=> {    
        d ++;
        progress_cb( (d * 100) / proms.length );
      });
    }
    return Promise.all(proms);
}