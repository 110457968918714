import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import firebase from "firebase";
import Resizer from "react-image-file-resizer";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Button,
  CircularProgress,
} from "@material-ui/core";
import "react-aspect-ratio/aspect-ratio.css";
import AspectRatio from "react-aspect-ratio";
import {
  firestoreAddUser,
  firestoreUpdateUser,
  firestoreGetUserList,
  firestoreUploadAvatar,
} from "../../store/actions/firebase.action";
import { InfoOutlined } from "@material-ui/icons";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import placeholder from "../../asset/img/avatar.png";
import UserDialogDelete from "./dialog/user_dialog_delete";
import UserAvatarDialogSelect from "./dialog/user_avatar_dialog_select";
import UserAvatarDialogUpload from "./dialog/user_avatar_dialog_upload";
import UserAvatarDialogCamera from "./dialog/user_avatar_dialog_camera";
import {
  MODE_ADD,
  MODE_EDIT,
  MODE_SELECT,
  MODE_NONE,
  CAMERA,
  UPLOAD,
} from "../../config/stringConfig";

class UserDetail extends Component {
  // ====================== Inits ====================== //

  constructor() {
    super();
    this.state = {
      loading: false,
      showPincode: false,
      mode: MODE_NONE, // 'add', 'select', 'update', 'none'
      user: {
        id: "",
        branch: "",
        group: "",
        avatar: "",
        active: true,
        break: false,
        username: "",
        pincode: "",
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        payRate: "",
        salary: "",
      },
      error: {
        branch: "",
        group: "",
        username: "",
        pincode: "",
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        payRate: "",
        salary: "",
      },
      require: {
        branch: true,
        group: true,
        username: true,
        pincode: true,
        firstName: true,
        lastName: true,
        phone: false,
        email: false,
        payRate: true,
        salary: true,
      },
      regex: {
        username: /^[a-zA-Z0-9]{4,32}$/, // 4-32 không kí tự, space
        pincode: /^[0-9]{4}$/, // 4 số
        firstName: /^[a-zA-Z\s]{1,32}$/, // 32 không kí tự không số
        lastName: /^[a-zA-Z\s]{1,32}$/, // 32 không kí tự không số
        phone: /^[0-9.+\-,()\s]{9,11}$/, // 9 tới 11 số , kèm . + - ()
        // eslint-disable-next-line no-useless-escape
        email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // Email format include +
      },
      inputRegex: {
        username: /^[a-zA-Z0-9]{0,32}$/,
        pincode: /^[0-9]{0,4}$/,
        firstName: /^[a-zA-Z\s]{0,32}$/,
        lastName: /^[a-zA-Z\s]{0,32}$/,
        phone: /^[0-9.+\-,()\s]{0,11}$/,
      },
      errorMess: {
        username: "Must contain 4-32 characters, numbers and alphabets.",
        pincode: "Must contain 4 characters, numbers only.",
        firstName: "Must contain 1-32 characters, alphabets only.",
        lastName: "Must contain 1-32 characters, alphabets only.",
        phone: "Invalid Phone Number.",
        email: "Invalid Email Addresss.",
      },
      tooltip:
        "If the total break time is less than 30mins per working day, it will be rounded to 30mins.",
      userDialogDelete: false,
      avatarDialogSelect: false,
      avatarDialogUpload: false,
      avatarDialogCamera: false,
    };
  }

  resetDefaultData = () => {
    // nếu new thì mở edit + default
    this.setState({
      showPincode: false,
      mode: MODE_NONE,
      user: {
        id: null,
        branch: "",
        group: "",
        avatar: "",
        active: true,
        break: false,
        username: "",
        pincode: "",
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        payRate: "20",
        salary: "",
      },
      error: {
        branch: "",
        group: "",
        username: "",
        pincode: "",
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        payRate: "",
        salary: "",
      },
    });
  };

  // Chuyển state từ props
  static getDerivedStateFromProps(nextProps, prevState) {
    const nextUser = nextProps.user;
    const preUser = prevState.user;
    const nextId = nextUser && nextUser.id;
    const preId = preUser && preUser.id;
    // Có id mới, id này là new
    if (nextId && nextId !== preId && nextId === "new") {
      return {
        user: {
          id: "new",
          branch: "",
          group: "",
          avatar: "",
          active: true,
          break: false,
          username: "",
          pincode: "",
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          payRate: "20",
          salary: "hours",
        },
        error: {
          branch: "",
          group: "",
          username: "",
          pincode: "",
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          payRate: "",
          salary: "",
        },
        mode: MODE_ADD,
      };
    }
    // Có id mới, id này là cũ
    if (nextId && nextId !== preId && nextId !== "new") {
      return {
        user: nextUser,
        mode: MODE_SELECT,
        error: {
          branch: "",
          group: "",
          username: "",
          pincode: "",
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          payRate: "",
          salary: "",
        },
      };
    }
    return null;
  }

  // ====================== Functions ====================== //

  handleClickShowPincode = () => {
    const { showPincode } = this.state;
    this.setState({
      showPincode: !showPincode,
    });
  };

  handleChange = (e) => {
    const { user, error, inputRegex } = this.state;
    const id = e.target.id;
    const value = e.target.value;
    const inputCondition = inputRegex && inputRegex[id];
    if (!inputCondition || (inputCondition && inputCondition.test(value))) {
      this.setState({
        user: {
          ...user,
          [id]: value,
        },
        error: {
          ...error,
          [id]: this.handleValidate({ id, value }),
        },
      });
    }
  };

  handleSelect = (e) => {
    const { user, error } = this.state;
    const value = e.target.value;
    const id = e.target.name;
    this.setState({
      user: {
        ...user,
        [id]: value,
      },
      error: {
        ...error,
        [id]: this.handleValidate({ id, value }),
      },
    });
  };

  handleChangePhone = (value) => {
    const { user, error } = this.state;
    this.setState({
      user: {
        ...user,
        phone: value,
      },
      error: {
        ...error,
        phone: this.handleValidate({ id: "phone", value }),
      },
    });
  };

  toggleCheckBreakTime = () => {
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        break: !user.break,
      },
    });
  };

  // ====================== Avatar ====================== //
  openAvatarSelect = () => {
    const { mode } = this.state;
    const disabled = mode === MODE_NONE || mode === MODE_SELECT;
    if (disabled) {
      return;
    }
    this.setState({
      avatarDialogSelect: true,
    });
  };

  closeAvatarSelect = () => {
    this.setState({
      avatarDialogSelect: false,
    });
  };

  confirmAvatarSelect = (type) => {
    if (type === CAMERA) {
      this.setState({
        avatarDialogSelect: false,
        avatarDialogCamera: true,
      });
    } else if (type === UPLOAD) {
      this.setState({
        avatarDialogSelect: false,
        avatarDialogUpload: true,
      });
    }
  };
  // ====================== Camera ====================== //
  closeCameraSelect = () => {
    this.setState({
      avatarDialogCamera: false,
    });
  };

  confirmCameraSelect = (uri) => {
    const { user } = this.state;
    this.setState({
      avatarDialogCamera: false,
      user: {
        ...user,
        avatar: uri,
      },
    });
  };
  // ====================== Upload ====================== //

  closeUploadSelect = () => {
    this.setState({
      avatarDialogUpload: false,
    });
  };

  confirmUploadSelect = (files) => {
    const { user } = this.state;
    this.setState(
      {
        avatarDialogUpload: false,
      },
      () => {
        Resizer.imageFileResizer(
          files[0],
          512,
          512,
          "jpg",
          96,
          0,
          (uri) => {
            this.setState({
              user: {
                ...user,
                avatar: uri,
              },
            });
          },
          "base64"
        );
      }
    );
  };
  // ====================== Delete ====================== //
  openUserDelete = () => {
    this.setState({
      userDialogDelete: true,
    });
  };
  closeUserDelete = () => {
    this.setState({
      userDialogDelete: false,
    });
  };
  confirmUserDelete = () => {
    this.setState(
      {
        userDialogDelete: false,
      },
      () => {
        this.resetDefaultData();
      }
    );
  };
  // ====================== Hàm xử lý validation ====================== //

  handleValidate = ({ id, value }) => {
    // xử lý validate
    if (id === "id") {
      return;
    }
    const { require, regex, errorMess } = this.state;
    const condition = regex[id];
    const errorString = errorMess[id];
    // Xử lý validate
    if (value && value.length > 0) {
      if (condition && condition.test(value)) {
        // valid
        return false;
      } else {
        // lỗi
        return errorString;
      }
    } else {
      if (require[id]) {
        // require
        return "Please fill out this field";
      } else {
        // empty
        return false;
      }
    }
  };

  handleAddOrUpdateUser = () => {
    const { user } = this.state;
    let error = {};
    let allValid = true;
    Object.entries(user).forEach((data, index) => {
      const id = data[0];
      const value = data[1];
      if (id === "id") {
        return;
      }
      const isError = this.handleValidate({ id, value });
      error = {
        ...error,
        [id]: isError,
      };
      if (isError) {
        allValid = false;
      }
    });
    if (!allValid) {
      // Ko hợp lệ tất cả các field
      this.setState({
        error,
      });
    } else {
      // Hợp lệ
      const id = user && user.id;
      console.log(this.state.user);
      if (id && id === "new") {
        this.handleFirestoreAddUser();
      } else if (id && id !== "new") {
        this.handleFirestoreUpdateUser();
      }
    }
  };

  handleEditUser = () => {
    this.setState({
      mode: MODE_EDIT,
    });
  };

  // ====================== Firebase Functions ====================== //

  handleFirestoreAddUser = () => {
    this.setState(
      {
        loading: true,
      },
      () => {
        const { user, error } = this.state;
        const username = user && user.username;
        const owner = firebase.auth().currentUser.uid;
        const userRef = firebase.firestore().collection("user");
        userRef
          .where("username", "==", username)
          .where("owner", "==", owner)
          .get()
          .then((snapshot) => {
            if (snapshot.empty) {
              // Nếu không trùng username
              this.props.firestoreUploadAvatar(user, (imgUrl) => {
                const newData = {
                  ...user,
                  avatar: imgUrl,
                };
                this.props.firestoreAddUser(newData, (data) => {
                  this.props.firestoreGetUserList(() => {
                    this.setState({
                      loading: false,
                      user: data,
                      mode: MODE_SELECT,
                    });
                  });
                });
              });
            } else {
              this.setState({
                // Xử lý lỗi trùng user name
                loading: false,
                error: {
                  ...error,
                  username: "This username has been created. Please use other name.",
                },
              });
            }
          });
      }
    );
  };

  handleFirestoreUpdateUser = () => {
    console.log("update");
    this.setState(
      {
        loading: true,
      },
      () => {
        const { user, error } = this.state;
        const username = user && user.username;
        const owner = firebase.auth().currentUser.uid;
        const userRef = firebase.firestore().collection("user");
        userRef
          .where("username", "==", username)
          .where("owner", "==", owner)
          .get()
          .then((snapshot) => {
            if (snapshot.empty) {
              // Nếu không trùng user name
              this.props.firestoreUploadAvatar(user, (imgUrl) => {
                const newData = {
                  ...user,
                  avatar: imgUrl,
                };
                this.props.firestoreUpdateUser({ user: newData }, () => {
                  this.setState({
                    loading: false,
                    mode: MODE_SELECT,
                  });
                  this.props.firestoreGetUserList();
                });
              });
            } else {
              // Xử lý lỗi trùng user name
              const dataList = snapshot.docs.map((doc) => doc.data());
              const id = user && user.id;
              if (dataList.length === 1 && dataList[0].id === id) {
                // trùng nhưng là tên cũ? tức là tự update
                this.props.firestoreUploadAvatar(user, (imgUrl) => {
                  const newData = {
                    ...user,
                    avatar: imgUrl,
                  };
                  this.props.firestoreUpdateUser({ user: newData }, () => {
                    this.setState({
                      loading: false,
                      mode: "select",
                    });
                    this.props.firestoreGetUserList();
                  });
                });
              } else {
                this.setState({
                  // Xử lý lỗi trùng user name
                  loading: false,
                  error: {
                    ...error,
                    username: "This username has been created. Please use other name.",
                  },
                });
              }
            }
          });
      }
    );
  };

  // ====================== Render Component ====================== //

  // ====================== Render Left Side ====================== //

  renderLeftSide = () => {
    return (
      <Grid item xs={3}>
        {this.renderAvatar()}
        <Grid item xs={12}>
          {this.renderActiveDropdown()}
          {this.renderBreakDropdown()}
        </Grid>
      </Grid>
    );
  };

  renderAvatar = () => {
    const { user, mode } = this.state;
    const avatar = user && user.avatar;
    const disabled = mode === MODE_NONE || mode === MODE_SELECT;
    return (
      <div className="avatar-container">
        <AspectRatio
          ratio="1/1"
          style={{ maxWidth: "200px", maxHeight: "200px", opacity: disabled ? "0.3" : 1 }}
        >
          <img
            src={avatar || placeholder}
            className="avatar-logo"
            alt="avatar-logo"
            onClick={() => this.openAvatarSelect()}
          />
        </AspectRatio>
      </div>
    );
  };

  renderActiveDropdown = () => {
    const { user, mode } = this.state;
    const active = (user && user.active) || true;
    const disabled = mode === MODE_NONE || mode === MODE_SELECT;
    return (
      <FormControl
        variant="outlined"
        margin="dense"
        required
        fullWidth
        style={{ marginTop: 10, marginBottom: 10 }}
        disabled={disabled}
      >
        <InputLabel id="activeLabel">Active</InputLabel>
        <Select
          labelId="activeLabel"
          id="active"
          name="active"
          value={active}
          onChange={this.handleSelect}
          label="Active"
        >
          <MenuItem value={true}>Active</MenuItem>
          <MenuItem value={false}>Deactivated</MenuItem>
        </Select>
      </FormControl>
    );
  };

  renderBreakDropdown = () => {
    const { user, tooltip, mode } = this.state;
    const disabled = mode === MODE_NONE || mode === MODE_SELECT;
    const id = user && user.id;
    if (id) {
      return (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={user && user.break}
                onChange={this.toggleCheckBreakTime}
                name="break"
                color="primary"
                disabled={disabled}
              />
            }
            label="30 min break"
            style={{ marginRight: 0 }}
          />
          <Tooltip title={tooltip} placement="bottom" arrow>
            <span>
              <InfoOutlined style={{ fontSize: 15 }} />
            </span>
          </Tooltip>
        </div>
      );
    }
    return null;
  };

  // ====================== Render Right Side ====================== //

  renderRightSide = () => {
    return (
      <Grid item xs={9}>
        <Grid container spacing={2}>
          {this.renderBranchDropDown()}
          {this.renderGroupDropDown()}
          {this.renderUserName()}
          {this.renderPincode()}
          {this.renderFirstName()}
          {this.renderLastName()}
          {this.renderPhone()}
          {this.renderEmail()}
          {this.renderSalaryDropDown()}
          {this.renderSalaryPayRate()}
          {this.renderButtonBar()}
        </Grid>
      </Grid>
    );
  };

  renderBranchDropDown = () => {
    const { branchs } = this.props;
    const { user, error, mode } = this.state;
    const disabled = mode === MODE_NONE || mode === MODE_SELECT;
    const selected = (user && user.branch) || "";
    const errorBranch = error && error.branch;
    const menuItem =
      branchs &&
      branchs.map((branch, index) => {
        return (
          <MenuItem key={index} value={branch.id}>
            {branch.tradingName}
          </MenuItem>
        );
      });
    return (
      <Grid item xs={6}>
        <FormControl
          variant="outlined"
          margin="dense"
          required
          fullWidth
          error={!!errorBranch}
          disabled={disabled}
        >
          <InputLabel id="branchLabel">Branch</InputLabel>
          <Select
            labelId="branchLabel"
            id="branch"
            name="branch"
            value={selected}
            onChange={this.handleSelect}
            label="Branch"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {menuItem}
          </Select>
          <FormHelperText>{errorBranch}</FormHelperText>
        </FormControl>
      </Grid>
    );
  };

  renderGroupDropDown = () => {
    const { userGroups } = this.props;
    const { user, error, mode } = this.state;
    const disabled = mode === MODE_NONE || mode === MODE_SELECT;
    const selected = (user && user.group) || "";
    const errorGroup = error && error.group;
    const menuItem =
      userGroups &&
      userGroups.map((group, index) => {
        return (
          <MenuItem key={index} value={group.id}>
            {group.name}
          </MenuItem>
        );
      });
    return (
      <Grid item xs={6}>
        <FormControl
          variant="outlined"
          margin="dense"
          required
          fullWidth
          error={!!errorGroup}
          disabled={disabled}
        >
          <InputLabel id="groupLabel">Group</InputLabel>
          <Select
            labelId="groupLabel"
            id="group"
            name="group"
            value={selected}
            onChange={this.handleSelect}
            label="Group"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {menuItem}
          </Select>
          <FormHelperText>{errorGroup}</FormHelperText>
        </FormControl>
      </Grid>
    );
  };

  renderUserName = () => {
    const { user, error, mode } = this.state;
    const username = (user && user.username) || "";
    const lengthUsername = 32 - (username && username.length) || 0;
    const errorUsername = (error && error.username) || "";
    const disabled = mode === MODE_NONE || mode === MODE_SELECT;
    return (
      <Grid item xs={6} className="count-string">
        <TextField
          variant="outlined"
          margin="dense"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          type="text"
          value={username}
          error={!!errorUsername}
          helperText={errorUsername}
          autoFocus
          disabled={disabled}
          onChange={this.handleChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">{lengthUsername}</InputAdornment>,
          }}
        />
      </Grid>
    );
  };

  renderPincode = () => {
    const { user, error, mode, showPincode } = this.state;
    const pincode = (user && user.pincode) || "";
    const errorPincode = (error && error.pincode) || "";
    const disabled = mode === MODE_NONE || mode === MODE_SELECT;
    return (
      <Grid item xs={6}>
        <FormControl
          fullWidth
          variant="outlined"
          margin="dense"
          error={!!errorPincode}
          disabled={disabled}
        >
          <InputLabel htmlFor="pincode" required>
            Pincode
          </InputLabel>
          <OutlinedInput
            id="pincode"
            value={pincode}
            onChange={this.handleChange}
            type={showPincode ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={this.handleClickShowPincode}
                >
                  {showPincode ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText>{errorPincode}</FormHelperText>
        </FormControl>
      </Grid>
    );
  };

  renderFirstName = () => {
    const { user, error, mode } = this.state;
    const firstName = (user && user.firstName) || "";
    const errorFirstName = (error && error.firstName) || "";
    const lengthFirstName = 32 - (user && user.firstName && user.firstName.length) || 0;
    const disabled = mode === MODE_NONE || mode === MODE_SELECT;
    return (
      <Grid item xs={6} className="count-string">
        <TextField
          variant="outlined"
          margin="dense"
          required
          fullWidth
          id="firstName"
          label="First Name"
          name="firstName"
          type="text"
          disabled={disabled}
          value={firstName}
          autoFocus
          error={!!errorFirstName}
          helperText={errorFirstName}
          onChange={this.handleChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">{lengthFirstName}</InputAdornment>,
          }}
        />
      </Grid>
    );
  };

  renderLastName = () => {
    const { user, error, mode } = this.state;
    const lastName = (user && user.lastName) || "";
    const errorLastName = (error && error.lastName) || "";
    const lengthLastName = 32 - (user && user.lastName && user.lastName.length) || 0;
    const disabled = mode === MODE_NONE || mode === MODE_SELECT;
    return (
      <Grid item xs={6} className="count-string">
        <TextField
          variant="outlined"
          margin="dense"
          required
          fullWidth
          id="lastName"
          label="Last Name"
          name="lastName"
          type="text"
          disabled={disabled}
          value={lastName}
          autoFocus
          error={!!errorLastName}
          helperText={errorLastName}
          onChange={this.handleChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">{lengthLastName}</InputAdornment>,
          }}
        />
      </Grid>
    );
  };

  renderPhone = () => {
    const { user, error, mode } = this.state;
    const phone = (user && user.phone) || "";
    const errorPhone = (error && error.phone) || "";
    const disabled = mode === MODE_NONE || mode === MODE_SELECT;
    return (
      <Grid item xs={6}>
        <PhoneInput
          onlyCountries={["vn", "nz", "us", "au"]}
          country="nz"
          onChange={this.handleChangePhone}
          autoFormat={false}
          value={phone}
          disabled={disabled}
          inputProps={{
            name: "phone",
            required: true,
            autoFocus: true,
            label: "Phone",
            id: "phone",
            placeholder: "Phone number",
            margin: "normal",
          }}
          inputStyle={{
            width: "100%",
            borderColor: !!errorPhone ? "red" : "rgba(0, 0, 0, 0.23)",
            color: disabled ? "#c8c8c8" : "#333",
          }}
        />
        <FormHelperText style={{ color: "red" }}>{errorPhone}</FormHelperText>
      </Grid>
    );
  };

  renderEmail = () => {
    const { user, error, mode } = this.state;
    const email = (user && user.email) || "";
    const errorEmail = error && error.email;
    const disabled = mode === MODE_NONE || mode === MODE_SELECT;
    return (
      <Grid item xs={6}>
        <TextField
          variant="outlined"
          margin="dense"
          fullWidth
          id="email"
          label="Email"
          name="email"
          type="email"
          disabled={disabled}
          value={email}
          autoFocus
          error={!!errorEmail}
          helperText={errorEmail}
          onChange={this.handleChange}
        />
      </Grid>
    );
  };

  renderSalaryDropDown = () => {
    const { user, mode } = this.state;
    const salary = (user && user.salary) || "";
    const disabled = mode === MODE_NONE || mode === MODE_SELECT;
    return (
      <Grid item xs={6}>
        <FormControl variant="outlined" margin="dense" required fullWidth disabled={disabled}>
          <InputLabel id="salaryLabel">Salary Type</InputLabel>
          <Select
            labelId="salaryLabel"
            id="salary"
            name="salary"
            value={salary}
            onChange={this.handleSelect}
            label="Salary Type"
          >
            <MenuItem value="hours">By Hours</MenuItem>
            <MenuItem value="fixed">Fixed</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    );
  };

  renderSalaryPayRate = () => {
    const { user, mode } = this.state;
    const payRate = (user && user.payRate) || "";
    const disabled = mode === MODE_NONE || mode === MODE_SELECT;
    return (
      <Grid item xs={6}>
        <TextField
          id="payRate"
          fullWidth
          margin="dense"
          variant="outlined"
          label="Pay Rate"
          type="number"
          required
          value={payRate}
          onChange={this.handleChange}
          disabled={disabled}
          InputProps={{
            endAdornment: <InputAdornment position="end">$/hr</InputAdornment>,
          }}
        />
      </Grid>
    );
  };

  // ====================== Render Buttons ====================== //

  renderButtonBar = () => {
    const { mode } = this.state;
    if (mode === MODE_SELECT) {
      return (
        <Grid item md={12} xs={12}>
          {this.renderEditButton()}
        </Grid>
      );
    }
    if (mode === MODE_EDIT) {
      return (
        <Grid item md={12} xs={12}>
          {this.renderDeleteButton()}
          {this.renderSaveButton()}
        </Grid>
      );
    }
    if (mode === MODE_ADD) {
      return (
        <Grid item md={12} xs={12}>
          {this.renderCancelButton()}
          {this.renderSaveButton()}
        </Grid>
      );
    }
    return null;
  };

  renderCancelButton = () => {
    const { loading } = this.state;
    return (
      <Button
        variant="contained"
        color="default"
        style={{ float: "left" }}
        disabled={loading}
        disableElevation
        onClick={() => this.resetDefaultData()}
      >
        Cancel
      </Button>
    );
  };

  renderSaveButton = () => {
    const { loading } = this.state;
    if (loading) {
      return (
        <Button
          variant="contained"
          color="default"
          disabled
          style={{ float: "right" }}
          disableElevation
          startIcon={<CircularProgress size={15} />}
        >
          Save
        </Button>
      );
    }
    return (
      <Button
        variant="contained"
        color="primary"
        style={{ backgroundColor: "green", float: "right" }}
        disableElevation
        onClick={() => this.handleAddOrUpdateUser()}
      >
        Save
      </Button>
    );
  };

  renderDeleteButton = () => {
    const { loading } = this.state;
    return (
      <Button
        variant="contained"
        color="secondary"
        style={{ backgroundColor: "red", float: "left" }}
        disabled={loading}
        disableElevation
        onClick={() => this.openUserDelete()}
      >
        Delete
      </Button>
    );
  };

  renderEditButton = () => {
    return (
      <Button
        variant="contained"
        color="primary"
        style={{ float: "right" }}
        disableElevation
        onClick={() => this.handleEditUser()}
      >
        Edit
      </Button>
    );
  };

  // ====================== Render Main ====================== //

  render() {
    const {
      user,
      userDialogDelete,
      avatarDialogSelect,
      avatarDialogUpload,
      avatarDialogCamera,
    } = this.state;
    console.log(this.state.mode);
    return (
      <Grid container spacing={2}>
        {this.renderLeftSide()}
        {this.renderRightSide()}
        <UserDialogDelete
          dialogOpen={userDialogDelete}
          handleClose={this.closeUserDelete}
          handleConfirm={this.confirmUserDelete}
          user={user}
        />
        <UserAvatarDialogSelect
          dialogOpen={avatarDialogSelect}
          handleClose={this.closeAvatarSelect}
          handleSelect={this.confirmAvatarSelect}
        />
        <UserAvatarDialogUpload
          dialogOpen={avatarDialogUpload}
          handleClose={this.closeUploadSelect}
          handleConfirm={this.confirmUploadSelect}
        />
        <UserAvatarDialogCamera
          dialogOpen={avatarDialogCamera}
          handleClose={this.closeCameraSelect}
          handleTakePhoto={this.confirmCameraSelect}
        />
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  userGroups: state.firebaseWeb.userGroups,
  users: state.firebaseWeb.users,
  branchs: state.firebaseWeb.branchs,
});

const mapDispatchToProps = {
  firestoreAddUser,
  firestoreUpdateUser,
  firestoreGetUserList,
  firestoreUploadAvatar,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(UserDetail);
