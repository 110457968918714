import React, { Component} from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { 
    ResponsiveContainer,
    BarChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Bar
} from 'recharts';
import { Loading } from '../../../component/loading';

class ReportSaleLabor30Day extends Component {

// ====================== Inits ====================== //

    constructor() {
        super();
        this.state = {
            loading: false,
        };
    }
    // ====================== Functions ====================== //

    labelFormatter = ({ value, name }) => {
        if (name === 'Sale') {
            return `${value} nzd`; 
        } else if (name === 'Labour') {
            return `${value} hrs`; 
        }
        return value;
    }

    // ====================== Firebase Functions ====================== //
    // ====================== Render Component ====================== //
    // ====================== Render Main ====================== //

    render() {
        const { report30Day, loading } = this.props;
        const data = report30Day && report30Day.data;
        if (data) {
            return (
                <ResponsiveContainer width='100%' height={350}>
                    <BarChart 
                        data={data}
                        barGap={1}
                    >
                        <CartesianGrid 
                            strokeDasharray='1 1'
                            vertical={false}
                        />
                        <XAxis 
                            dataKey='dateString'
                            angle={-15}
                            textAnchor='end'
                            tick={{fontSize: 12}}
                        />
                        <YAxis 
                            yAxisId='left'
                            label = {{
                                // angle: -90,
                                value: 'Sale',
                                position: 'insideTopRight'
                            }}
                            tick={{fontSize: 9}}
                        />
                        <YAxis 
                            yAxisId='right'
                            orientation='right'
                            label = {{
                                // angle: 90,
                                value: 'Labour',
                                position: 'insideTopRight'
                            }}
                            tick={{fontSize: 9}}
                        />
                        <Tooltip 
                            formatter={(value, name) => this.labelFormatter({ value, name })}
                        />
                        <Legend 
                            verticalAlign='top'
                            height={36}
                        />
                        <Bar 
                            dataKey='orderTotal' 
                            fill='green' 
                            name='Sale'
                            yAxisId='left'
                        />
                        <Bar 
                            dataKey='trackingTotal'
                            fill='#1976d2'
                            name='Labour'
                            yAxisId='right'
                        />
                    </BarChart>
                </ResponsiveContainer>
          );
        }
        return <Loading loading={loading} />
    }
}

const mapStateToProps = (state) => ({
    report30Day: state.firebaseWeb.report30Day,
});

// const mapDispatchToProps = {
//     firestoreGetBranchList,
// }

export default compose(connect(mapStateToProps,null))(ReportSaleLabor30Day)
