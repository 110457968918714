import FileSaver from "file-saver";
import moment from "moment";
import { jsonToCSV } from "react-papaparse";

const columns = [
  "contactName",
  "emailAddress",
  "pOAddressLine1",
  "pOAddressLine2",
  "pOAddressLine3",
  "pOAddressLine4",
  "pOCity",
  "pORegion",
  "pOPostalCode",
  "pOCountry",
  "invoiceNumber",
  "reference",
  "invoiceDate",
  "dueDate",
  "total",
  "taxTotal",
  "taxAmount",
  "invoiceAmountPaid",
  "invoiceAmountDue",
  "inventoryItemCode",
  "description",
  "quantity",
  "unitAmount",
  "discount",
  "lineAmount",
  "accountCode",
  "taxType",
  "trackingName1",
  "currency",
  "type",
  "sent",
  "status",
];

export const exportOrdersToCSV = ({ orders, from, to, branchs, branch, contacts }) => {
  // =========================  Provider =========================
  const result = convertOrderData({ orders, contacts });
  // // =========================  Row Data =========================
  const rowTableHeader =
    "ContactName,EmailAddress,POAddressLine1,POAddressLine2,POAddressLine3,POAddressLine4,POCity,PORegion,POPostalCode,POCountry,InvoiceNumber,Reference,InvoiceDate,DueDate,Total,TaxTotal,TaxAmount,InvoiceAmountPaid,InvoiceAmountDue,InventoryItemCode,Description,Quantity,UnitAmount,Discount,LineAmount,AccountCode,TaxType,TrackingName1,Currency,Type,Sent,Status\n";
  const rowTableBody = jsonToCSV(result, { columns: columns, header: false, quotes: true });
  // // =========================  CSV =========================
  const csvBuffer = `${rowTableHeader}${rowTableBody}`;
  const fromName = moment(from).format("DD");
  const toName = moment(to).format("DDMMYY");
  const currentTime = moment().format("HH:mm");
  const branchObj = branchs && branchs.find((item) => item.id === branch);
  const branchName = branchObj.tradingName;
  const fileName = `${branchName} - SalesInvoices - ${fromName}-${toName}-${currentTime}`;
  const fileType = "text/csv;charset=utf-8;";
  const fileExtension = ".csv";
  const data = new Blob([csvBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

const convertOrderData = ({ orders, contacts }) => {
  let result = [];
  orders &&
    orders.forEach((item) => {
      const contactId = item.contactId;
      const contactObj = contacts && contacts.find((item) => item.id === contactId);
      const contactName = contactObj && contactObj.contactName;
      const emailAddress = contactObj && contactObj.emailAddress;
      const poAddressLine1 = contactObj && contactObj.poAddressLine1;
      const poAddressLine2 = contactObj && contactObj.poAddressLine2;
      const poAddressLine3 = contactObj && contactObj.poAddressLine3;
      const poAddressLine4 = contactObj && contactObj.poAddressLine4;
      const poCity = contactObj && contactObj.poCity;
      const poRegion = contactObj && contactObj.poRegion;
      const poCountry = contactObj && contactObj.poCountry;
      const orderId = item && item.orderId;
      const invoiceNumber = `INV-${orderId}`;
      const reference = item && item.reference;
      const scheduledFor = item && item.scheduledFor;
      const scheduledDate = scheduledFor && scheduledFor.seconds && scheduledFor.toDate();
      const invoiceDate = moment(scheduledDate).format("DD/MM/YY");
      const dueDate = moment(scheduledDate).add(1, "month").format("20/MM/YY");
      const total = "";
      const taxTotal = item && item.tax;
      const invoiceAmountPaid = "";
      const invoiceAmountDue = item && item.total;
      const discount = "";
      const accountCode = "4100";
      const taxType = "15% GST on Income";
      const trackingName1 = "LOCATIONS";
      const currency = "NZD";
      const type = "Sales invoice";
      const sent = "Unsent";
      const status = "Awaiting Approval";
      const data = {
        contactName,
        emailAddress,
        poAddressLine1,
        poAddressLine2,
        poAddressLine3,
        poAddressLine4,
        poCity,
        poRegion,
        poCountry,
        invoiceNumber,
        reference,
        invoiceDate,
        dueDate,
        total,
        taxTotal,
        invoiceAmountPaid,
        invoiceAmountDue,
        discount,
        accountCode,
        taxType,
        trackingName1,
        currency,
        type,
        sent,
        status,
      };
      const products = item && item.products;
      products.forEach((product) => {
        const quantity = (product && product.quantity) || 0;
        const unitAmount = (product && product.unitAmount) || 0;
        const lineAmount = parseFloat(quantity) * parseFloat(unitAmount);
        const inventoryItemCode = product && product.inventoryItemCode;
        const description = product && product.description;
        const taxAmount = (lineAmount * 15) / 100;
        const detail = {
          ...data,
          quantity,
          unitAmount,
          lineAmount,
          inventoryItemCode,
          description,
          taxAmount,
        };
        result = [...result, detail];
      });
    });
  return result;
};
