import React, { Component} from 'react';
import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle,
    TableHead,
    TableRow,
    TableCell,
    Grid
} from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { 
    TextField,
    CircularProgress,
    InputAdornment,
    IconButton,
} from '@material-ui/core';
import { 
    AddCircleOutline,
    RemoveCircleOutline,
} from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import {
    DatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { sum } from 'lodash';
import { styles } from './styles';
import { firestoreUpdateOrder } from '../../../store/actions/firebase.action';

class ReportOrderDialogEdit extends Component {

  // ====================== Inits ====================== //
    
    constructor() {
        super();
        // state
        this.state = {
            header: [
                { id: 'inventoryItemCode', label: 'Code', align: 'left', sortable: false },
                { id: 'description', label: 'Name', align: 'left', sortable: true },
                { id: 'quantity', label: 'Quantity', align: 'right', sortable: true },
                { id: 'unitAmount', label: 'Unit Amount', align: 'right', sortable: true },
                { id: 'amount', label: 'Total', align: 'right', sortable: true },
            ],
            scheduledFor: moment(),
            note: '',
            reference: '',
            loading: false,
            error: '',
            orderList: null,
            newProduct: null,
            quantity: '1',
            unitAmount: '0',
            autocomplete: null,
            dialogSuccess: false,
            dialogError: false,
            orderId: null,
            loadingId: false,
            inputLength: {
                reference: 20,
                note: 300,
            },
            quantityRegex: /^[0-9]{0,3}$/,
            amountRegex: /^[0-9.\s]{0,10}$/,
        };
    }

    resetData = () => {
        const { data } = this.props;
        // Lấy ngày schedule
        // const scheduledFor = data && data.scheduledFor;
        // const seconds = scheduledFor && scheduledFor.seconds;
        // const schedule = seconds && moment(scheduledFor.toDate());
        // Lấy ngày order
        const createAt = data && data.createAt;
        const createSeconds = createAt && createAt.seconds;
        const createSchedule = createSeconds && moment(createAt.toDate()).format('DD/MM/YYYY hh:mmA');
        // Lấy thông tin khác
        const note = data && data.note;
        const reference = data && data.reference;
        const orderId = data && data.orderId;
        const orderList = data && data.products;
        this.setState({
            // scheduledFor: schedule,
            note,
            reference,
            orderId,
            orderList,
            orderDate: createSchedule,
        })
    }

    // ====================== Functions ====================== //

    handleCancel = () => {
        this.setState({
            loading: false,
        }, () => {
            this.props.handleClose()
        })
    }

    handleChange = (e) => {
        const { inputLength } = this.state;
        const id = [e.target.id];
        const value = e.target.value;
        const condition = inputLength && inputLength[id];
        if (!condition || (value.length < condition)) {
            this.setState({
                [id]: value
            });
        }
    }

    handleDateChange = (date, id) => {
        this.setState({
            [id]: date,
        })
    }

    handleChangeQuantity = (e, index) => {
        const { orderList, quantityRegex } = this.state;
        const value = e.target.value;
        const condition = quantityRegex.test(value);
        if (condition) {
            const newList = orderList;
            newList[index].quantity = value;
            this.setState({ 
                orderList: newList,
            });
        }
    }
    
    handleBlurQuantity = (e, index) => {
        const { orderList, quantityRegex } = this.state;
        const value = e.target.value;
        const condition = quantityRegex.test(value);
        if (condition && value === '') {
            const newList = orderList;
            newList[index].quantity = 1;
            this.setState({ 
                orderList: newList,
            });
        }
    }

    handleChangeAmount = (e, index) => {
        const { orderList, amountRegex } = this.state;
        const value = e.target.value;
        const condition = amountRegex.test(value);
        if (condition) {
            const newList = orderList;
            newList[index].unitAmount = value;
            this.setState({ 
                orderList: newList,
            });
        }
    }
    
    handleBlurAmount = (e, index) => {
        const { orderList, amountRegex } = this.state;
        const value = e.target.value;
        const condition = amountRegex.test(value);
        if (condition && value === '') {
            const newList = orderList;
            newList[index].unitAmount = 0;
            this.setState({ 
                orderList: newList,
            });
        }
    }

    handleChangeNewQuantity = (e) => {
        const { quantityRegex } = this.state;
        const value = e.target.value;
        const condition = quantityRegex.test(value);
        if (condition) {
            this.setState({ 
                quantity: value,
            });
        }
    }
    
    handleBlurNewQuantity = (e) => {
        const { quantityRegex } = this.state;
        const value = e.target.value;
        const condition = quantityRegex.test(value);
        if (condition && value === '') {
            this.setState({ 
                quantity: 1,
            });
        }
    }

    handleChangeNewAmount = (e) => {
        const { amountRegex } = this.state;
        const value = e.target.value;
        const condition = amountRegex.test(value);
        if (condition) {
            this.setState({ 
                unitAmount: value,
            });
        }
    }
    
    handleBlurNewAmount = (e) => {
        const { amountRegex } = this.state;
        const value = e.target.value;
        const condition = amountRegex.test(value);
        if (condition && value === '') {
            this.setState({ 
                unitAmount: 0,
            });
        }
    }

    selectProduct = (newProduct) => {
        const { templates, data } = this.props;
        const contactId = data && data.contactId;
        const itemCode = newProduct && newProduct.itemCode;
        const salesUnitPrice = ( newProduct && newProduct.salesUnitPrice) || 0;
        const existTemplate = templates && templates.find(t => t.inventoryItemCode === itemCode && t.contactId === contactId);
        const templateUnitAmount = existTemplate && existTemplate.unitAmount;
        const unitAmount = templateUnitAmount ? templateUnitAmount : salesUnitPrice; // Giá theo template
        this.setState({
            newProduct,
            autocomplete: null,
            unitAmount,
        });
    }

    addProductToOrder= () => {
        const { newProduct, quantity, orderList, unitAmount } = this.state;
        const { data } = this.props;
        const owner = data.owner;
        const branch = data.branch;
        const contactName = data.contactName;
        const inventoryItemCode = newProduct.itemCode;
        const description = newProduct.itemName;
        const newItem = {
            owner,
            branch,
            contactName,
            inventoryItemCode,
            description,
            quantity,
            unitAmount,
        };
        const newList = [
            ...orderList,
            newItem
        ];
        this.setState({
            orderList: newList,
            newProduct: null,
            quantity: '1',
            unitAmount: '',
            autocomplete: null,
        });
    }

    removeProductFromOrder = (item) => {
        const { orderList } = this.state;
        const newList = orderList && orderList.filter(o => o.inventoryItemCode !== item.inventoryItemCode);
        this.setState({
            orderList: newList,
            newProduct: null,
            quantity: '1',
            autocomplete: null,
        });
    }

    handleFix = () => {
        const { orderList } = this.state;
        const { templates, data } = this.props;
        const contactId = data && data.contactId;
        const fixedList = orderList && orderList.map(item => {
            const itemCode = item && item.inventoryItemCode;
            const existTemplate = templates && templates.find(t => t.inventoryItemCode === itemCode && t.contactId === contactId);
            const templateUnitAmount = existTemplate && existTemplate.unitAmount;
            const unitAmount = templateUnitAmount ? templateUnitAmount : item.unitAmount; // Giá theo template
            return {
                ...item,
                unitAmount,
            }
        })
        
        this.setState({
            orderList: fixedList,
        });

    }

    // ====================== Firebase Functions ====================== //

    updateOrder = () => {
        const { orderList, note, reference } = this.state;
        const { data } = this.props;
        const priceArr = orderList && orderList.map(item => {
            const unitPrice = item.unitAmount ? parseFloat(item.unitAmount) : 0;
            const quantity = item.quantity ? parseFloat(item.quantity) : 0;
            const price = unitPrice * quantity;
            return price;
        });
        const sumPrice = sum(priceArr);
        const subTotal = parseFloat(sumPrice.toFixed(2));
        const total = parseFloat(((sumPrice * 115) / 100).toFixed(2));
        const tax = parseFloat((total - subTotal).toFixed(2));
        const updateData = {
            ...data,
            products: orderList,
            note,
            reference,
            subTotal,
            total,
            tax,
        };
        this.setState({
            loading: true,
        }, () => {
            this.props.firestoreUpdateOrder({ data: updateData }, () => {
                this.setState({
                    loading: false,
                }, () => {
                    this.props.handleSave(updateData)
                });
            });
        });
    }

    // ====================== Render Component ====================== //

    renderTableHead = () => {
        const { header } = this.state;
        return (
            <TableHead>
              <TableRow>
                <TableCell align='center'> # </TableCell>
                { header && header.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding='default'
                    >
                        {headCell.label}
                    </TableCell>
                )) }
              </TableRow>
            </TableHead>
        );
    }

    renderScheduleAndOrderId = () => {
        const { scheduledFor } = this.state;
        return (
            <Grid 
                container 
                spacing={1} 
                direction="row"
                justify="flex-start"
            >
                <Grid item xs={8}>
                    { this.renderOrderId() }
                </Grid>
                <Grid item xs={4}>
                    <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} >
                        <DatePicker
                            autoOk
                            className='date-picker'
                            id='scheduledFor'
                            variant="inline"
                            inputVariant="outlined"
                            fullWidth
                            value={scheduledFor}
                            label="Scheduled for"
                            format="DD/MM/YYYY"
                            onChange={date => this.handleDateChange(date, 'scheduledFor')}
                            margin='dense'
                            disabled
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            </Grid>
        );
    }

    renderOrderId = () => {
        const { orderId, loadingId, orderDate } = this.state;
        if (loadingId) {
            return (
                <Grid 
                    container 
                    spacing={1} 
                    direction="row"
                    justify="flex-start"
                >
                    <Grid item xs={12}>Order Date: {orderDate}</Grid>
                    <Grid item>Order ID:</Grid>
                    <Grid item>
                        <CircularProgress size={15} />
                    </Grid>
                </Grid>
            );
        }
        return (
            <Grid 
                container 
                spacing={1} 
                direction="row"
                justify="flex-start"
            >
                <Grid item xs={12}>Order Date: {orderDate}</Grid>
                <Grid item>Order ID:</Grid>
                <Grid item>
                    {orderId}
                </Grid>
            </Grid>
        );
    }

    renderReference = () => {
        const { reference } = this.state;
        const length = 20 - reference.length;
        return (
            <Grid 
                container 
                spacing={1} 
                direction="row"
                justify="flex-start"
                style={{ marginTop: 10 }}
            >
                <Grid item xs={12} className="count-string">
                    <TextField
                        variant="outlined"
                        margin='dense'
                        fullWidth
                        id="reference"
                        label="Reference #"
                        name="reference"
                        type="text"
                        value={reference}
                        onChange={this.handleChange}
                        InputProps={{
                            endAdornment: 
                                <InputAdornment position="end">
                                    {length}
                                </InputAdornment>,
                        }}
                    />
                </Grid>
            </Grid>
        );
    }

    renderNote = () => {
        const { note } = this.state;
        const length = 300 - note.length;
        return (
            <Grid 
                container 
                spacing={1} 
                direction="row"
                justify="flex-start"
                style={{ marginTop: 10 }}
            >
                <Grid item xs={12} className="count-string">
                    <TextField
                        variant="outlined"
                        margin='dense'
                        fullWidth
                        id="note"
                        label="Notes"
                        name="note"
                        type="text"
                        value={note}
                        onChange={this.handleChange}
                        InputProps={{
                            endAdornment: 
                                <InputAdornment position="end">
                                    {length}
                                </InputAdornment>,
                        }}
                        multiline
                        rows={2}
                    />
                </Grid>
            </Grid>
        );
    }

    renderOrder = () => {
        const { orderList } = this.state;
        return orderList && orderList.map((item, index) => this.renderOrderItem({ item, index }));
    }
 
    renderOrderItem = ({ item, index }) => {
        const description = item && item.description;
        const inventoryItemCode = item && item.inventoryItemCode;
        const itemFullName = `${inventoryItemCode} - ${description}`;
        const quantity = item.quantity;
        const unitAmount = item.unitAmount;
        return (
            <Grid 
                container 
                spacing={1} 
                direction="row"
                justify="flex-start"
                style={{ marginTop: 10 }}
                key={index}
            >
                <Grid item sm={7} xs={8}>
                    <TextField
                        variant="outlined"
                        margin='dense'
                        fullWidth
                        label="Name"
                        name="name"
                        type="text"
                        value={itemFullName}
                        disabled
                    />
                </Grid>
                <Grid item sm={2} xs={2}>
                    <TextField
                        className='quantity-input'
                        variant="outlined"
                        margin='dense'
                        fullWidth
                        label="Quantity"
                        name="quantity"
                        type="text"
                        value={quantity}
                        onChange={e => this.handleChangeQuantity(e, index)}
                        maxLength="3"
                        onBlur={e => this.handleBlurQuantity(e, index)}
                    />
                </Grid>
                <Grid item sm={2} xs={2}>
                    <TextField
                        className='quantity-input'
                        variant="outlined"
                        margin='dense'
                        fullWidth
                        label="Unit Amount"
                        name="unitAmount"
                        type="text"
                        value={unitAmount}
                        onChange={e => this.handleChangeAmount(e, index)}
                        onBlur={e => this.handleBlurAmount(e, index)}
                    />
                </Grid>
                <Grid item sm={1} xs={2} >
                    <IconButton 
                        color="primary"
                        aria-label="delete-item"
                        component="span"
                        onClick={() => this.removeProductFromOrder(item)}
                    >
                        <RemoveCircleOutline style={styles.orderDelete} />
                    </IconButton>
                </Grid>
            </Grid>
        );
    }

    

    renderAddProduct = () => {
        return (
            <Grid 
                container 
                spacing={1} 
                direction="row"
                justify="flex-start"
                style={{ marginTop: 10 }}
            >
                <Grid item sm={7} xs={8}>
                    { this.renderAutoComplete() }
                </Grid>
                <Grid item sm={2} xs={2}>
                    { this.renderNewProductQuantity() }
                </Grid>
                <Grid item sm={2} xs={2}>
                    { this.renderNewProductUnitAmount() }
                </Grid>
                <Grid item sm={1} xs={2}>
                    { this.renderCheckButton() }
                </Grid>
            </Grid>
        );
    }

    renderAutoComplete = () => {
        const { products } = this.props;
        const { orderList, newProduct } = this.state;
        const disabled = !products;
        return (
            <Autocomplete
                onChange={(e, value) => this.selectProduct(value)}
                value={ newProduct }
                getOptionSelected={(option) => option}
                getOptionDisabled={(option) => {
                    const itemCode = option.itemCode;
                    const exist = orderList && orderList.find(t => t.inventoryItemCode === itemCode);
                    return exist ? true : false;
                }}
                getOptionLabel={(option) => {
                    const itemCode = option.itemCode;
                    const itemName = option.itemName;
                    return `${itemCode} - ${itemName}`;
                }}
                options={ products || [] }
                disabled={ disabled }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        margin='dense' 
                        label="Search item by ID / Name"
                        variant="outlined"
                    />
                )}
            />
        );
    }

    renderNewProductQuantity = () => {
        const { quantity } = this.state;
        return (
            <TextField
                className='quantity-input'
                variant="outlined"
                margin='dense'
                fullWidth
                label="Quantity"
                name="quantity"
                type="text"
                value={quantity}
                onChange={this.handleChangeNewQuantity}
                maxLength="3"
                onBlur={this.handleBlurNewQuantity}
            />
        );
    }

    renderNewProductUnitAmount = () => {
        const { unitAmount } = this.state;
        return (
            <TextField
                className='quantity-input'
                variant="outlined"
                margin='dense'
                fullWidth
                label="Unit Amount"
                name="unitAmount"
                type="text"
                value={unitAmount}
                onChange={this.handleChangeNewAmount}
                onBlur={this.handleBlurNewAmount}
            />
        );
    }

    renderCheckButton = () => {
        const { newProduct, quantity } = this.state;
        const disabled = !(newProduct && quantity);
        return (
            <IconButton 
                color="primary"
                aria-label="add-item"
                component="span"
                disabled={disabled}
                onClick={() => this.addProductToOrder()}
            >
                <AddCircleOutline style={styles.addNewOrder} />
            </IconButton>
        );
    }

    renderSaveButton = () => {
        const { loading } = this.state;
        if (loading){
            return (
                <Button 
                    variant="contained" 
                    color='default'
                    disabled
                    style={{ float: 'right' }}
                    disableElevation
                    startIcon={<CircularProgress size={15}/>}
                >
                    Save
                </Button>
            );
        }
        return (
            <Button 
                variant="contained" 
                color='primary'
                style={{ backgroundColor: 'green', float: 'right' }}
                disableElevation
                onClick={() => this.updateOrder()}
            >
                Save
             </Button>
        );
    }

    // ====================== Render Main ====================== //

    render() {
      const { error, loading } = this.state;
      const { dialogOpen, handleClose, data } = this.props;
      const orderId = data && data.orderId;
      const contactName = data && data.contactName;
      const date = data && data.date;
        return (
            <Dialog
                open={dialogOpen}
                onClose={handleClose}
                onEntering={this.resetData}
                scroll='paper'
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                maxWidth='md'
                fullWidth
            >
            <DialogTitle id="scroll-dialog-title">{contactName} - Order ID: {orderId} - Date: {date}</DialogTitle>
            <DialogContent dividers className='container-csv-upload one-file'>
                { this.renderScheduleAndOrderId() }
                { this.renderReference() }
                { this.renderNote() }
                { this.renderOrder() }
                { this.renderAddProduct() }
            </DialogContent>
            <div className="red-text center">
                { error ? <p>{error}</p> : null }
            </div>
            <DialogActions>
                <Button 
                    onClick={() => this.handleFix()}
                    disabled={loading}
                    variant='outlined'
                    style={{ alignSelf: 'flex-start' }}
                >
                    Fix Unit Amount By Template
                </Button>
                <Button 
                    onClick={() => this.handleCancel()}
                    disabled={loading}
                    variant='outlined'
                >
                    Cancel
                </Button>
                { this.renderSaveButton() }
            </DialogActions>
        </Dialog>
        );
    }
};

const mapStateToProps = (state) => ({
    products: state.firebaseWeb.products,
    templates: state.firebaseWeb.templates,
});
    
const mapDispatchToProps = {
    firestoreUpdateOrder
}
  
export default compose(connect(mapStateToProps,mapDispatchToProps))(ReportOrderDialogEdit)