import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import moment from "moment";
import { Loading } from "../../../component/loading";

class ReportDriverLogTable extends Component {
  // ====================== Inits ====================== //

  constructor() {
    super();
    this.state = {
      edited: [],
      editError: [],
      header: [
        { id: "createAt", label: "Date", align: "left", sortable: true },
        { id: "driver", label: "Driver", align: "left", sortable: false },
        { id: "event", label: "Event", align: "left", sortable: false },
        { id: "location", label: "Location", align: "left", sortable: false },
        { id: "time", label: "Time", align: "center", sortable: false },
      ],
      dialogSelected: false,
      dialogEdit: false,
      selectedOrder: null,
    };
  }

  // ====================== Functions ====================== //

  isSelected = (data) => {
    const { selected } = this.props;
    const exist = selected && selected.find((item) => item.itemCode === data.itemCode);
    return exist ? true : false;
  };

  isCheckedAll = () => {
    const { selected, reportData } = this.props;
    return selected && reportData && selected.length === reportData.length ? true : false;
  };

  // ====================== Data Functions ====================== //

  // ====================== Firebase Functions ====================== //

  // ====================== Render Component ====================== //

  renderTableHead = () => {
    const { reportData, orderBy, order } = this.props;
    const { header } = this.state;
    const disabled = !(reportData && reportData.length > 0);
    return (
      <TableHead>
        <TableRow>
          {header?.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.align}
              padding="default"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {this.renderTableSortLabel(headCell, disabled)}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  renderTableSortLabel = (headCell, disabled) => {
    const { orderBy, order } = this.props;
    if (headCell.sortable) {
      return (
        <TableSortLabel
          disabled={disabled}
          active={orderBy === headCell.id}
          direction={orderBy === headCell.id ? order : "asc"}
          onClick={() => this.props.handleSort(headCell.id)}
        >
          {headCell.label}
        </TableSortLabel>
      );
    }
    return headCell.label;
  };

  renderTableBody = () => {
    const { reportData, loading } = this.props;
    if (!loading) {
      return <TableBody>{reportData?.map((row, index) => this.renderTableRow({ row, index }))}</TableBody>;
    }
    return <TableBody />;
  };

  renderTableRow = ({ row, index }) => {
    const createAt = row?.createAt;
    const date = createAt?.seconds && createAt.toDate();
    const dateString = moment(date).format("ddd DD/MM/YYYY");
    const timeString = moment(date).format("hh:mm A");
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={index} style={{ cursor: "pointer" }}>
        <TableCell align="left">{dateString}</TableCell>
        <TableCell align="left">{row.fullName}</TableCell>
        <TableCell align="left">{row.event}</TableCell>
        <TableCell align="left">{row.address}</TableCell>
        <TableCell align="center">{timeString}</TableCell>
      </TableRow>
    );
  };

  // ====================== Render Modal ====================== //

  // ====================== Render Main ====================== //

  render() {
    const { loading } = this.props;
    return (
      <Paper style={{ width: "100%", marginTop: 10, marginBottom: 30 }}>
        <TableContainer className="report-table p5">
          <Table stickyHeader aria-label="sticky table">
            {this.renderTableHead()}
            {this.renderTableBody()}
          </Table>
          <Loading loading={loading} />
        </TableContainer>
      </Paper>
    );
  }
}

export default compose(connect(null, null))(ReportDriverLogTable);
