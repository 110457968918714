import React, { Component} from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { 
    CssBaseline,
    Container, 
    Grid,
    TextField,
    InputAdornment,
    Tooltip,
    IconButton,
    MenuItem,
    FormControl,
    InputLabel,
    Select
} from '@material-ui/core';
import {
    CloudDownloadOutlined,
    CloudUploadOutlined,
    SearchOutlined,
} from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import collection from 'lodash/collection';
import { 
    firestoreGetProductListByBranch,
    firestoreGetBranchList
} from  '../../store/actions/firebase.action';
import Navbar from '../00_Nav/nav_bar';
import RightNav from '../00_Nav/right_nav';
import { exportProductsToCSV } from './functions/export_products_csv';
import ProductDialogCsvUpload from './dialog/product_dialog_csv_upload';
import ProductsTable from './product_table';

class ProductsServiceScene extends Component {

// ====================== Inits ====================== //

    constructor() {
        super();
        this.state = {
            loading: false,
            error: '',
            data: null,
            selectedProduct: null,
            selected: [],
            csvUpload: false,
            orderBy: 'itemCode',
            order: 'asc',
            branch: null,
        };
    }

    componentDidMount = () => {
        this.props.firestoreGetBranchList(); // get Branch
    }

    updateData = () => {
        const { branch } = this.state;
        this.setState({
            loading: true,
        }, () => {
            this.props.firestoreGetProductListByBranch({ branch }, () => {
                this.setState({
                    loading: false,
                }, () => this.refineData());
            })
        });
    }
    
    // ====================== Functions ====================== //

    handleSelectBranch = (event) => {
        const branch = event.target.value;
        this.setState({
            branch,
        }, () => this.updateData());
    }

    selectProduct = (selectedProduct) => {
        this.setState({
            selectedProduct,
        }, () => this.refineData());
    }

    refineData = () => {
        const { selectedProduct, order, orderBy } = this.state;
        const { products } = this.props;
        let data = products;
        if (products && selectedProduct) {
            data = products && products.filter(item => item.itemCode === selectedProduct.itemCode);
        }
        if (order && orderBy) {
            data = collection.orderBy(data, [orderBy], [order]);
        }
        this.setState({
            data,
        });
    }

    handleSort = (id) => {
        const { orderBy, order } = this.state;
        const isAsc = orderBy === id && order === 'asc';
        this.setState({
            order: isAsc ? 'desc' : 'asc',
            orderBy: id,
        }, () => {
            this.refineData();
        });
    };


    // CSV upload
    openCSVUpload = () => {
        this.setState({
            csvUpload: true,
        })
    }
    
    closeCSVUpload = () => {
        this.setState({
            csvUpload: false,
        });
    }
    
    successCSVUpload = () => {
        this.setState({
            csvUpload: false,
        }, () => this.updateData());
    }
    

    // Select
    toggleSelectRecord = (data) => {
        const { selected } = this.state;
        let newCheck = [];
        const exist = selected && selected.filter(c => c.itemCode === data.itemCode).length > 0;
        if (exist){
            newCheck = selected && selected.filter(c => !(c.itemCode === data.itemCode));
        } else {
            newCheck = [...selected, data];
        }
        this.setState({
            selected: newCheck
        })
    }

    toggleCheckAll = () => {
        const { selected, data } = this.state;
        let isSelectedAll = false;
        if (selected && data && data.length === selected.length){
            isSelectedAll = true;
        }
        this.setState({
            selected: isSelectedAll ? [] : data,
        });
    }


    // ====================== Firebase Functions ====================== //
    
    // ====================== Render Component ====================== //

    renderBranchDropDown = () => {
        const { branchs } = this.props;
        const { branch } = this.state;
        const menuItem = branchs && branchs.map((branch, index) => {
            return (
                <MenuItem key={index} value={branch.id}>{branch.tradingName}</MenuItem>
            );
        });
        return (
            <FormControl 
                variant="outlined"
                margin='dense'
                fullWidth
                className='table-select'
            >
                <InputLabel id="branchLabel">Branch</InputLabel>
                <Select
                    labelId="branchLabel"
                    id="branch"
                    value={branch || ''}
                    onChange={(event) => this.handleSelectBranch(event)}
                    label="Branch"
                >
                    { menuItem }
                </Select>
            </FormControl>
        );
    }


    renderTopBar = () => {
        return (
            <Grid 
                container 
                spacing={3} 
                direction="row"
                justify="flex-start"
                alignItems="center"
            >
                <Grid item lg={2} md={12} sm={12} xs={12}>
                    { this.renderBranchDropDown() }
                </Grid> 
                <Grid item lg={4} md={6} sm={6} xs={12}>
                    { this.renderSearchAutocomplete() }
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                    { this.renderRightButtonGroup() }
                </Grid>
            </Grid>
        );
    }

    renderSearchAutocomplete = () => {
        const { selectedProduct } = this.state;
        const { products } = this.props;
        const disabled = !products;
        return (
            <Autocomplete
                id="product-search"
                onChange={(e, value) => this.selectProduct(value)}
                value={ selectedProduct }
                getOptionSelected={(option) => option}
                getOptionLabel={(option) => {
                    const itemCode = option.itemCode;
                    const itemName = option.itemName;
                    const fullName = `${itemCode} - ${itemName}`;
                    return fullName;
                }}
                options={ products || [] }
                disabled={ disabled }
                renderInput={(params) => (
                    <TextField
                    {...params}
                    margin='dense' 
                    label="Search By ID / Name"
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchOutlined />
                            </InputAdornment>
                        ),
                    }}
                    />
                )}
            />
        );
    }

    renderRightButtonGroup = () => {
        const { data } = this.state;
        const length = (data && data.length) || 0;
        return (
            <Grid container spacing={1} justify="flex-end" alignItems="flex-end">
                { this.renderExportImportUser() }
                <Grid item>
                    <div className='total-user'>Total Items: {length}</div>
                </Grid>
            </Grid>
        );
    }

    renderExportImportUser = () => {
        const { branchs } = this.props;
        const { data, branch } = this.state;
        const disabled = !branch;
        return (
          <Grid item>
            <Tooltip title="Export products to csv">
                <span>
                    <IconButton 
                        aria-label="download"
                        onClick={() => exportProductsToCSV({ products: data, branch, branchs })}
                        disabled={disabled}
                    >
                        <CloudDownloadOutlined style={{ color: disabled ? '#c8c8c8' : '#333' }}/>
                    </IconButton>
                </span>
            </Tooltip>
            <Tooltip title="Import products from csv">
                <span>
                    <IconButton 
                        aria-label="upload"
                        onClick={() => this.openCSVUpload()}
                        disabled={disabled}
                    >
                        <CloudUploadOutlined style={{ color: disabled ? '#c8c8c8' : '#333' }}/>
                    </IconButton>
                </span>
            </Tooltip>
          </Grid>
        );
      }

    // ====================== Render Main ====================== //

    render() {
        const { auth } = this.props;
        if (!auth.uid) return <Redirect to={'/signin'}/>
        const { csvUpload, data, selected, order, orderBy, loading, branch} = this.state;
        return (
          <div className='root-container'>
            <CssBaseline />
            <Navbar title="Products & Services" right={<RightNav />}/>
            <Container maxWidth='xl' className='products-services-container'>
                { this.renderTopBar() }
                <ProductsTable 
                    reportData={data}
                    selected={selected}
                    order={order}
                    orderBy={orderBy}
                    loading={loading}
                    handleSort={this.handleSort}
                    handleCheckAll={this.toggleCheckAll}
                    handleCheck={this.toggleSelectRecord}
                    handleUpdate={this.refineData}
                />
                <ProductDialogCsvUpload 
                    dialogOpen={csvUpload}
                    handleClose={this.closeCSVUpload}
                    handleConfirm={this.successCSVUpload}
                    branch={branch}
                />
            </Container>
          </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.firebase.auth,
    products: state.firebaseWeb.products,
    branchs: state.firebaseWeb.branchs,
});

const mapDispatchToProps = {
    firestoreGetProductListByBranch,
    firestoreGetBranchList,
}

export default compose(connect(mapStateToProps,mapDispatchToProps))(ProductsServiceScene)
