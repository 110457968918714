import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { CssBaseline, Container, Grid, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { firestoreGetBranchList, firestoreGetUserList } from "../../store/actions/firebase.action";
import Navbar from "../00_Nav/nav_bar";
import RightNav from "../00_Nav/right_nav";
import ReportDriverLog from "./component/report_driver_log_management";
import { DELIVERY_DRIVER_LOG, DELIVERY_ANOTHER_LOG } from "../../config/stringConfig";

const LOGS = [DELIVERY_DRIVER_LOG, DELIVERY_ANOTHER_LOG];

class ReportDelivery extends Component {
  // ====================== Inits ====================== //

  constructor() {
    super();
    this.state = {
      branch: "",
      type: DELIVERY_DRIVER_LOG,
    };
  }

  componentDidMount = () => {
    this.props.firestoreGetBranchList(); // get Branch
    this.props.firestoreGetUserList(); // get users
  };

  // ====================== Functions ====================== //

  handleSelect = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // ====================== Firebase Functions ====================== //

  // ====================== Render Component ====================== //

  renderRow01 = () => {
    return (
      <Grid container spacing={3} direction="row">
        <Grid item md={2} xs={12}>
          <h3 className="regular-title" style={{ marginTop: 10, fontSize: 20, marginBottom: 0 }}>
            Delivery Report
          </h3>
        </Grid>
        <Grid item md={2} xs={12}>
          {this.renderBranchDropDown()}
        </Grid>
        <Grid item md={2} xs={12}>
          {this.renderTypeDropDown()}
        </Grid>
      </Grid>
    );
  };

  renderBranchDropDown = () => {
    const { branchs } = this.props;
    const { branch } = this.state;
    const menuItem = branchs?.map((branch, index) => {
      return (
        <MenuItem key={index} value={branch.id}>
          {branch.tradingName}
        </MenuItem>
      );
    });
    return (
      <FormControl variant="outlined" margin="dense" fullWidth className="table-select">
        <InputLabel id="branchLabel">Branch</InputLabel>
        <Select
          labelId="branchLabel"
          id="branch"
          name="branch"
          value={branch || ""}
          onChange={this.handleSelect}
          label="Branch"
        >
          {menuItem}
        </Select>
      </FormControl>
    );
  };

  renderTypeDropDown = () => {
    const { type } = this.state;
    const menuItem = LOGS.map((item, index) => {
      return (
        <MenuItem key={index} value={item}>
          {item}
        </MenuItem>
      );
    });
    return (
      <FormControl variant="outlined" margin="dense" fullWidth className="table-select">
        <InputLabel id="typeLabel">Report Type</InputLabel>
        <Select labelId="typeLabel" id="type" name="type" value={type || ""} onChange={this.handleSelect} label="Type">
          {menuItem}
        </Select>
      </FormControl>
    );
  };

  renderReports = () => {
    const { branch, type } = this.state;
    if (type === DELIVERY_DRIVER_LOG) {
      return <ReportDriverLog branch={branch} />;
    }
  };

  // ====================== Render Main ====================== //

  render() {
    const { auth } = this.props;
    if (!auth.uid) return <Redirect to={"/signin"} />;
    return (
      <div className="root-container">
        <CssBaseline />
        <Navbar title="Report Delivery" right={<RightNav />} />
        <Container maxWidth="xl" className="report">
          {this.renderRow01()}
          {this.renderReports()}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  branchs: state.firebaseWeb.branchs,
});

const mapDispatchToProps = {
  firestoreGetBranchList,
  firestoreGetUserList,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ReportDelivery);
