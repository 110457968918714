import FileSaver from 'file-saver';
import moment from 'moment';
import { jsonToCSV } from 'react-papaparse';

const columns = [
    'contactName',
    'emailAddress',
    'firstName',
    'lastName',
    'poAttentionTo',
    'poAddressLine1',
    'poAddressLine2',
    'poAddressLine3',
    'poAddressLine4',
    'poCity',
    'poRegion',
    'poPostalCode',
    'poCountry',
    'saAttentionTo',
    'saAddressLine1',
    'saAddressLine2',
    'saAddressLine3',
    'saAddressLine4',
    'saCity',
    'saRegion',
    'saPostalCode',
    'saCountry',
    'phoneNumber',
    'mobileNumber',
    'ddiNumber',
    'website',
    'legalName',
    'dueDateBillDay',
    'dueDateBillTerm',
    'dueDateSalesDay',
    'dueDateSalesTerm',
    'password',
]

export const exportContactsToCSV = ({ contacts, branchs, branch }) => {
    // =========================  Provider =========================
    // // =========================  Row Data =========================
    const rowTableHeader = '*ContactName,EmailAddress,FirstName,LastName,POAttentionTo,POAddressLine1,POAddressLine2,POAddressLine3,POAddressLine4,POCity,PORegion,POPostalCode,POCountry,SAAttentionTo,SAAddressLine1,SAAddressLine2,SAAddressLine3,SAAddressLine4,SACity,SARegion,SAPostalCode,SACountry,PhoneNumber,MobileNumber,DDINumber,Website,LegalName,DueDateBillDay,DueDateBillTerm,DueDateSalesDay,DueDateSalesTerm,Password\n';
    const rowTableBody = jsonToCSV(contacts, { columns: columns, header: false, quotes: true });
    // // =========================  CSV =========================
    const csvBuffer = `${rowTableHeader}${rowTableBody}`;
    const currentTime = moment().format('DD_MM_YYYY-HH_mm_ss');
    const branchObj = branchs && branchs.find(item => item.id === branch);
    const branchName = branchObj.tradingName;
    const fileName = `${branchName} - All Contacts - ${currentTime}`;
    const fileType = 'text/csv;charset=utf-8;';
    const fileExtension = '.csv';
    const data = new Blob([csvBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
}