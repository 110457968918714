import FileSaver from 'file-saver';
import moment from 'moment';
import { jsonToCSV } from 'react-papaparse';

const columns = ["contactName", "inventoryItemCode", "description", "quantity","unitAmount"]

export const exportTemplatesToCSV = ({ templates, branchs, branch }) => {
    // =========================  Provider =========================
    // // =========================  Row Data =========================
    const rowTableHeader = 'ContactName,InventoryItemCode,Description,Quantity,UnitAmount\n';
    const rowTableBody = jsonToCSV(templates, { columns: columns, header: false, quotes: true });
    // // =========================  CSV =========================
    const csvBuffer = `${rowTableHeader}${rowTableBody}`;
    const currentTime = moment().format('DD_MM_YYYY-HH_mm_ss');
    const branchObj = branchs && branchs.find(item => item.id === branch);
    const branchName = branchObj.tradingName;
    const fileName = `${branchName} - All Templates - ${currentTime}`;
    const fileType = 'text/csv;charset=utf-8;';
    const fileExtension = '.csv';
    const data = new Blob([csvBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
}