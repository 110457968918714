import { GET_TOPUP_SUCCESS } from "../ref";

const initState = {
  topups: null,
};

const topupReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_TOPUP_SUCCESS:
      return {
        ...state,
        topups: action.payload,
      };
    default:
      return state;
  }
};

export default topupReducer;
