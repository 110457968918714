export const styles = {
    container: {
        flex: 1,
    },
    content: {
        padding: 8,
    },
    input: {
        fontSize: 13,
        backgroundColor: '#fff',
        borderRadius: 5,
        marginBottom: 10,
    },
    noteContainer: {
        position: 'relative',
        marginVertical: 2,
    },
    noteCount: {
        position: 'absolute',
        top: -2,
        right: 5,
        fontSize: 12,
        backgroundColor: '#fff',
    },
    orderRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    orderName: {
        fontSize: 13,
        backgroundColor: '#fff',
        flex: 1
    },
    orderQuantity: {
        marginLeft: 5,
        minWidth: 35,
        backgroundColor: '#fff',
    },
    orderDelete: {
        color: 'red'
    },
    addNewOrder: {
        color: 'green'
    },
    autoCompleteContainer: {
        flex: 1,
        left: 0,
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 1,
        flexDirection: 'row'
    },
    autoCompleteItem: { 
        height: 40, 
        width: '100%',
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#eee',
    },
    spinner: { 
        marginRight: 15,
        width: 30
    },
    orderIdContainer: {
        flexDirection: 'row',
    }
};
