import FileSaver from 'file-saver';
import moment from 'moment';
import { jsonToCSV } from 'react-papaparse';

const columns = [
    'itemCode',
    'itemName',
    'purchasesDescription',
    'purchasesUnitPrice',
    'purchasesTaxRate',
    'salesDescription',
    'salesUnitPrice',
    'salesAccount',
    'salesTaxRate',
    'packaging',
    'group',
]

export const exportProductsToCSV = ({ products, branch, branchs }) => {
    // =========================  Provider =========================
    // // =========================  Row Data =========================
    const rowTableHeader = '*ItemCode,ItemName,PurchasesDescription,PurchasesUnitPrice,PurchasesTaxRate,SalesDescription,SalesUnitPrice,SalesAccount,SalesTaxRate,Packaging,Group\n';
    const rowTableBody = jsonToCSV(products, { columns: columns, header: false, quotes: true });
    // // =========================  CSV =========================
    const csvBuffer = `${rowTableHeader}${rowTableBody}`;
    const currentTime = moment().format('DD_MM_YYYY-HH_mm_ss');
    const branchObj = branchs && branchs.find(item => item.id === branch);
    const branchName = branchObj.tradingName;
    const fileName = `${branchName} - All Products - ${currentTime}`;
    const fileType = 'text/csv;charset=utf-8;';
    const fileExtension = '.csv';
    const data = new Blob([csvBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
}