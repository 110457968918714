import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  CssBaseline,
  Container,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
  InputAdornment,
} from "@material-ui/core";
import { VisibilityOutlined, CloudDownloadOutlined, SearchOutlined } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { sumBy, groupBy } from "lodash";
import dateArray from "moment-array-dates";
import {
  firestoreGetBranchList,
  firestoreGetContactsListByBranch,
  firestoreGetOrderListByClientByMonth,
} from "../../store/actions/firebase.action";
import Navbar from "../00_Nav/nav_bar";
import RightNav from "../00_Nav/right_nav";
import { exportMonthlySaleToCSV } from "./functions/export_monthly_sale_csv";
import ReportMonthlyTable from "./report_monthly_table";

class ReportMonthlySale extends Component {
  // ====================== Inits ====================== //

  constructor() {
    super();
    this.state = {
      branch: "",
      date: moment(),
      loading: false,
      search: "",
      searchLoading: false,
      orderBy: "status",
      order: "asc",
      selectedContact: null,
    };
  }

  resetData = () => {
    this.setState({
      reportData: null,
      totalSale: null,
      totalReject: null,
      totalRevenue: null,
    });
  };

  componentDidMount = () => {
    this.props.firestoreGetBranchList(); // get Branch
    this.getDatesInMonth(); // lấy danh sách ngày hiện tại
  };

  updateData = () => {
    const { orders } = this.props;
    // Chuyển data theo date
    const dataByDate = [];
    orders.forEach((item) => {
      const scheduledFor = item?.scheduledFor;
      const scheduleDate = scheduledFor?.seconds && scheduledFor.toDate();
      const date = scheduleDate && moment(scheduleDate).format("DD");
      const products = item?.products;
      const newList = products?.map((p) => {
        return {
          ...p,
          date,
        };
      });
      dataByDate.push(...newList);
    });
    // Chuyển data sang Code
    const dataByCode = groupBy(dataByDate, "inventoryItemCode");
    const arrByCode = Object.values(dataByCode);
    // Chuyển data sang theo code + giá
    const dataByPrice = [];
    arrByCode.forEach((items) => {
      const codeByPrice = groupBy(items, "unitAmount");
      const arrCodeByPrice = Object.values(codeByPrice);
      const result = arrCodeByPrice?.map((item, index) => {
        // Kiểm tra xem có bị dạng dữ liệu dub ko
        const totalQuantity = sumBy(item, (i) => parseFloat(i.quantity));
        const unitAmount = item[0] && item[0].unitAmount;
        const inventoryItemCode = item[0] && item[0].inventoryItemCode;
        const description = item[0] && item[0].description;
        const totalPrice = unitAmount && parseFloat(unitAmount) * totalQuantity;
        return {
          data: item,
          isSecond: index > 0,
          totalQuantity,
          totalPrice,
          unitAmount,
          inventoryItemCode,
          description,
        };
      });
      dataByPrice.push(...result);
    });
    const totalSale = sumBy(dataByPrice, "totalPrice");
    const totalReject = 0;
    const totalRevenue = totalSale - totalReject;
    this.setState({
      reportData: dataByPrice,
      totalSale,
      totalReject,
      totalRevenue,
    });
  };

  // ====================== Functions ====================== //

  handleDateChange = (date, id) => {
    this.setState(
      {
        [id]: date,
      },
      () => this.getDatesInMonth()
    );
  };

  getDatesInMonth = () => {
    const { date } = this.state;
    const start = moment(date).startOf("month");
    const end = moment(date).endOf("month");
    const dates = dateArray.range(start, end, "DD", true);
    this.setState(
      {
        dates,
      },
      () => this.resetData()
    );
  };

  handleSearchChange = (e) => {
    this.setState({
      search: e.target.value,
    });
  };

  handleSelect = (e, id) => {
    this.setState(
      {
        [id]: e.target.value,
      },
      () => this.getBranchContactData()
    );
  };

  handleSort = (id) => {
    const { orderBy, order } = this.state;
    const isAsc = orderBy === id && order === "asc";
    this.setState(
      {
        order: isAsc ? "desc" : "asc",
        orderBy: id,
      },
      () => {
        this.updateData();
      }
    );
  };

  // Select
  toggleSelectRecord = (data) => {
    const { selected } = this.state;
    let newCheck = [];
    const exist = selected && selected.filter((c) => c.itemCode === data.itemCode).length > 0;
    if (exist) {
      newCheck = selected && selected.filter((c) => !(c.itemCode === data.itemCode));
    } else {
      newCheck = [...selected, data];
    }
    this.setState({
      selected: newCheck,
    });
  };

  toggleCheckAll = () => {
    const { selected, data } = this.state;
    let isSelectedAll = false;
    if (selected && data && data.length === selected.length) {
      isSelectedAll = true;
    }
    this.setState({
      selected: isSelectedAll ? [] : data,
    });
  };

  selectContact = (selectedContact) => {
    this.setState(
      {
        selectedContact,
      },
      () => this.resetData()
    );
  };

  // ====================== Firebase Functions ====================== //

  getBranchContactData = () => {
    const { branch } = this.state;
    this.props.firestoreGetContactsListByBranch({ branch }, () => {
      this.resetData();
    });
  };

  viewMonthlyReport = () => {
    const { date, branch, selectedContact } = this.state;
    if (date && branch && selectedContact) {
      this.setState(
        {
          loading: true,
        },
        () => {
          this.props.firestoreGetOrderListByClientByMonth(
            { branch, date, contact: selectedContact },
            () => {
              this.setState(
                {
                  loading: false,
                },
                () => this.updateData()
              );
            }
          );
        }
      );
    }
  };

  // ====================== Render Component ====================== //

  renderBranchDropDown = () => {
    const { branchs } = this.props;
    const { branch } = this.state;
    const menuItem =
      branchs &&
      branchs.map((branch, index) => {
        return (
          <MenuItem key={index} value={branch.id}>
            {branch.tradingName}
          </MenuItem>
        );
      });
    return (
      <FormControl variant="outlined" margin="dense" fullWidth className="table-select">
        <InputLabel id="branchLabel">Branch</InputLabel>
        <Select
          labelId="branchLabel"
          id="branch"
          value={branch || ""}
          onChange={(event) => this.handleSelect(event, "branch")}
          label="Branch"
        >
          {menuItem}
        </Select>
      </FormControl>
    );
  };

  renderRow01 = () => {
    return (
      <Grid container spacing={3} direction="row" justify="flex-start">
        <Grid item md={2} xs={12}>
          <h3 className="regular-title" style={{ marginTop: 10, fontSize: 20, marginBottom: 0 }}>
            Monthly Sales
          </h3>
        </Grid>
        <Grid item md={2} xs={12}>
          {this.renderBranchDropDown()}
        </Grid>
      </Grid>
    );
  };

  renderRow02 = () => {
    const { date } = this.state;
    return (
      <Grid container spacing={3} direction="row" justify="flex-start" alignItems="center">
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {this.renderSearchAutocomplete()}
        </Grid>
        <Grid item md={2} xs={3}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              views={["month"]}
              autoOk
              className="date-picker"
              variant="inline"
              inputVariant="outlined"
              fullWidth
              value={date}
              format="MMM"
              label="Month"
              InputAdornmentProps={{ position: "end" }}
              onChange={(date) => this.handleDateChange(date, "date")}
              margin="dense"
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item md={2} xs={3}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              views={["year"]}
              autoOk
              className="date-picker"
              variant="inline"
              inputVariant="outlined"
              fullWidth
              value={date}
              format="YYYY"
              label="Year"
              InputAdornmentProps={{ position: "end" }}
              onChange={(date) => this.handleDateChange(date, "date")}
              margin="dense"
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item>{this.renderViewButton()}</Grid>
        <Grid item>{this.renderDownloadButton()}</Grid>
      </Grid>
    );
  };

  renderViewButton = () => {
    const { date, branch, loading, selectedContact } = this.state;
    const disabled = !(date && branch && selectedContact && !loading);
    if (loading) {
      return (
        <Button
          variant="contained"
          color="primary"
          disabled
          style={{ marginTop: 3 }}
          startIcon={<CircularProgress size={15} />}
        >
          View
        </Button>
      );
    }
    return (
      <Button
        variant="contained"
        color="primary"
        disabled={disabled}
        style={{ marginTop: 3 }}
        startIcon={<VisibilityOutlined />}
        onClick={() => this.viewMonthlyReport()}
      >
        View
      </Button>
    );
  };

  renderDownloadButton = () => {
    const { branchs, contacts } = this.props;
    const { loading, reportData, branch, selectedContact, date } = this.state;
    const disabled = !(reportData && contacts) || loading;
    return (
      <Button
        variant="contained"
        color="primary"
        disabled={disabled}
        style={{ marginTop: 3 }}
        startIcon={<CloudDownloadOutlined />}
        onClick={() =>
          exportMonthlySaleToCSV({
            reportData,
            selectedContact,
            branch,
            branchs,
            contacts,
            date,
          })
        }
      >
        Download
      </Button>
    );
  };

  renderRow03 = () => {
    const { totalSale, totalReject, totalRevenue } = this.state;
    const totalSaleString = totalSale && totalSale.toFixed(2);
    const totalRejectString = totalReject && totalReject.toFixed(2);
    const totalRevenueString = totalRevenue && totalRevenue.toFixed(2);
    return (
      <Grid container spacing={3} direction="row" justify="space-between" alignItems="center">
        <Grid item lg={12} md={12} sm={12} xs={12} style={{ textAlign: "right", fontSize: 15 }}>
          Total sale: <span style={{ color: "#1a76d2" }}>{totalSaleString}</span> | Total rejected:{" "}
          <span style={{ color: "red" }}>{totalRejectString}</span> | Total revenue:{" "}
          <span style={{ color: "green" }}>{totalRevenueString}</span>
        </Grid>
      </Grid>
    );
  };

  renderSearchAutocomplete = () => {
    const { selectedContact, branch } = this.state;
    const { contacts } = this.props;
    const disabled = !(contacts && branch);
    return (
      <Autocomplete
        id="product-search"
        onChange={(e, value) => this.selectContact(value)}
        value={selectedContact}
        getOptionSelected={(option) => option}
        getOptionLabel={(option) => {
          const contactName = option.contactName;
          const firstName = option.firstName;
          const lastName = option.lastName;
          const fullName = `${contactName} - ${firstName} ${lastName}`;
          return fullName;
        }}
        options={contacts || []}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="dense"
            label="Filter By Client"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    );
  };

  // ====================== Render Main ====================== //

  render() {
    const { auth } = this.props;
    if (!auth.uid) return <Redirect to={"/signin"} />;
    const { reportData, dates, order, orderBy, loading } = this.state;
    return (
      <div className="root-container">
        <CssBaseline />
        <Navbar title="Report Monthly Sales" right={<RightNav />} />
        <Container maxWidth="xl" className="report report-time-tracking">
          {this.renderRow01()}
          {this.renderRow02()}
          {this.renderRow03()}
          <ReportMonthlyTable
            reportData={reportData}
            order={order}
            orderBy={orderBy}
            loading={loading}
            dates={dates}
          />
        </Container>
              
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  branchs: state.firebaseWeb.branchs,
  orders: state.firebaseWeb.contactOrders,
  contacts: state.firebaseWeb.contacts,
});

const mapDispatchToProps = {
  firestoreGetBranchList,
  firestoreGetContactsListByBranch,
  firestoreGetOrderListByClientByMonth,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ReportMonthlySale);
