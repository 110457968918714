import React, { Component } from 'react';
import { IconButton, Badge } from '@material-ui/core';
import { Notifications } from '@material-ui/icons';
// import firebase from 'firebase';

export default class RightNav extends Component {

    // getAllProductAndTemplate = () => {
    //     let templates = [];
    //     let contacts = [];
    //     // Templates
    //     const templateRef = firebase.firestore().collection('templates');
    //     const templatePromise = templateRef
    //     .get().then(snapshot => {
    //         if (snapshot) {
    //             templates = snapshot.docs.map(doc => doc.data());
    //         }
    //     });
    //     // Contacts
    //     const contactRef = firebase.firestore().collection('contacts');
    //     const contactPromise = contactRef
    //     .get().then(snapshot => {
    //         if (snapshot) {
    //             contacts = snapshot.docs.map(doc => doc.data());
    //         }
    //     });
    //     const allPromise = [templatePromise, contactPromise];
    //     Promise.all(allPromise).then(() => {
    //         console.log('=====================');
    //         console.log(templates);
    //         console.log(contacts);
    //         const newTemplate = templates.map(item => {
    //             const exist = contacts && contacts.find(c => c.contactName === item.contactName);
    //             const contactId = (exist && exist.id) || null;
    //             return {
    //                 ...item,
    //                 contactId,
    //             }
    //         });
    //         console.log(newTemplate);
    //         const updateAll = newTemplate.map(item => templateRef.doc(item.id).update(item));
    //         Promise.all(updateAll).then(() => {
    //             console.log('done');
    //         });
    //     });
    // }

    render() {
        return (
            <IconButton 
                aria-label="cart"
            >
                <Badge badgeContent={4} color="secondary">
                    <Notifications style={{ color: '#fff' }}/>
                </Badge>
            </IconButton>
        );
    }
}
