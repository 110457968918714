import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { 
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    TableSortLabel,
    IconButton,
    TablePagination
} from '@material-ui/core';
import { 
    LockOutlined,
    DeleteOutline
} from '@material-ui/icons';
import ContactDialogPassword from './dialog/contacts_dialog_password';
import ContactDialogDelete from './dialog/contacts_dialog_delete';
import { Loading } from '../../component/loading';

class ContactsTable extends Component {

// ====================== Inits ====================== //

    constructor() {
        super();
        this.state = {
            edited: [],
            editError: [],
            header: [
                { id: 'contactName', label: 'Business Name', align: 'left', sortable: true },
                { id: 'website', label: 'Website', align: 'left', sortable: true },
                { id: 'contactPerson', label: 'Contact Person', align: 'left', sortable: false },
                { id: 'emailAddress', label: 'Email', align: 'left', sortable: true },
                { id: 'mobileNumber', label: 'Mobile Number', align: 'left', sortable: true },
                { id: 'youOweThem', label: 'You Owe Them', align: 'right', sortable: true },
                { id: 'theyOweYou', label: 'They Owe You', align: 'right', sortable: true },
                { id: 'password', label: 'Password', align: 'center', sortable: false },
                { id: 'delete', label: 'Delete', align: 'center', sortable: false },
            ],
            selectedContact: null,
            dialogPassword: false,
            dialogDelete: false,
            rowsPerPage: 20,
            page: 0,
        };
    }

    // ====================== Functions ====================== //

    handleChangePage = (event, page) => {
        this.setState({
            page
        });
    };

    handleChangeRowsPerPage = (event) => {
        const rowsPerPage = parseInt(event.target.value, 10);
        this.setState({
            rowsPerPage,
            page: 0,
        });
    };
    
    isSelected = (data) => {
        const { selected } = this.props;
        const exist = selected && selected.find(item => item.contactName === data.contactName);
        return exist ? true : false;
    }

    isCheckedAll = () => {
        const { selected, reportData } = this.props;
        return selected && reportData && selected.length === reportData.length ? true : false;
    }

    // Dialog Password
    openDialogPassword = (selectedContact) => {
        this.setState({
            selectedContact,
            dialogPassword: true,
        });
    }

    closeDialogPassword = () => {
        this.setState({
            selectedContact: null,
            dialogPassword: false,
        });
    }

    successDialogPassword = () => {
        this.setState({
            selectedContact: null,
            dialogPassword: false,
        }, () => this.props.handleUpdate());
    }
    // Dialog Delete
    openDialogDelete = (selectedContact) => {
        this.setState({
            selectedContact,
            dialogDelete: true,
        });
    }

    closeDialogDelete = () => {
        this.setState({
            selectedContact: null,
            dialogDelete: false,
        });
    }

    successDialogDelete = () => {
        this.setState({
            selectedContact: null,
            dialogDelete: false,
        }, () => this.props.handleUpdate());
    }

    // ====================== Data Functions ====================== //
     
    // ====================== Firebase Functions ====================== //

    // ====================== Render Component ====================== //

    renderTableHead = () => {
        const { reportData, orderBy, order } = this.props;
        const { header } = this.state;
        const checkAll = this.isCheckedAll();
        const disabled = !(reportData && reportData.length > 0);
        return (
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={checkAll}
                    disabled={disabled}
                    onChange={() => this.props.handleCheckAll()}
                    inputProps={{ 'aria-label': 'select all desserts' }}
                    color='primary'
                  />
                </TableCell>
                { header && header.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding='default'
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        { this.renderTableSortLabel(headCell, disabled)}
                    </TableCell>
                )) }
              </TableRow>
            </TableHead>
        );
    }

    renderTableSortLabel = (headCell, disabled) => {
        const { orderBy, order } = this.props;
        if (headCell.sortable) {
            return (
                <TableSortLabel
                    disabled={disabled}
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={() => this.props.handleSort(headCell.id)}
                >
                    {headCell.label}
                </TableSortLabel>
            );
        } 
        return headCell.label
    }
    
    renderTableBody = () => {
        const { reportData, loading } = this.props;
        const { rowsPerPage, page } = this.state;
        const length = reportData && reportData.length;
        let data = reportData;
        if (length > 1) {
            data = reportData && reportData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        }
        if (!loading) {
            return (
                <TableBody>
                    { data && data.map((row, index) => this.renderTableRow({ row, index })) }
                </TableBody>
            );
        }
        return <TableBody />;
    }

    // ====================== Render Table Default ====================== //

    renderTableRow = ({ row, index }) => {
        const isItemSelected = this.isSelected(row);
        const labelId = `table-checkbox-${index}`;
        const contactPerson = `${row.firstName} ${row.lastName}`;
        return (
            <TableRow
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={index}
                selected={isItemSelected}
            >
                <TableCell align='left' padding='checkbox'>
                    <Checkbox
                        checked={isItemSelected}
                        color='primary'
                        inputProps={{ 'aria-labelledby': labelId }}
                        onChange={() => this.props.handleCheck(row)}
                    />
                </TableCell>
                <TableCell align='left'>{row.contactName}</TableCell>
                <TableCell align='left'>{row.website}</TableCell>
                <TableCell align='left'>{contactPerson}</TableCell>
                <TableCell align='left'>{row.emailAddress}</TableCell>
                <TableCell align='left'>{row.mobileNumber}</TableCell>
                <TableCell align='right'>{row.youOweThem}</TableCell>
                <TableCell align='right'>{row.theyOweYou}</TableCell>
                { this.renderPassword({ item: row }) }
                { this.renderDelete({ item: row }) }
            </TableRow>
        );
    }

    renderPassword = ({ item }) => {
        const password = item.password;
        return (
            <TableCell align='center'>
                <IconButton 
                    aria-label="password"
                    onClick={() => this.openDialogPassword(item)}
                >
                    <LockOutlined style={{ color: password ? 'green' : '#c8c8c8' }}/>
                 </IconButton>
            </TableCell>
        );
    }

    renderDelete = ({ item }) => {
        return (
            <TableCell align='center'>
                <IconButton 
                    aria-label="delete"
                    onClick={() => this.openDialogDelete(item)}
                >
                    <DeleteOutline style={{ color: 'red' }}/>
                 </IconButton>
            </TableCell>
        );
    }

    renderTableFooter = () => {
        const { reportData } = this.props;
        const { rowsPerPage, page } = this.state;
        const length = reportData && reportData.length;
        if (length > 1) {
            return (
                <TablePagination
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    component="div"
                    count={length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
            );
        }
        return null;
    }

    // ====================== Render Modal ====================== //

    // ====================== Render Main ====================== //

    render() {
        const { dialogPassword, dialogDelete, selectedContact } = this.state;
        const { loading } = this.props;
        return (
            <Paper style={{ width: '100%', marginTop: 10, marginBottom: 30 }}>
                <TableContainer className='report-table'>
                    <Table stickyHeader aria-label="sticky table">
                        { this.renderTableHead() }
                        { this.renderTableBody() }
                    </Table>
                </TableContainer>
                { this.renderTableFooter() }
                <Loading loading={loading} />
                <ContactDialogPassword 
                    contact={selectedContact}
                    dialogOpen={dialogPassword}
                    handleClose={this.closeDialogPassword}
                    handleSuccess={this.successDialogPassword}
                />
                <ContactDialogDelete 
                    dialogOpen={dialogDelete}
                    handleClose={this.closeDialogDelete}
                    handleSuccess={this.successDialogDelete}
                    contact={selectedContact}
                />
            </Paper>
        ); 
    }
}


export default compose(connect(null,null))(ContactsTable)