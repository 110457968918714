/* eslint-disable no-unused-vars */
import firebase from "firebase";
import moment from "moment";

// -------------------------- Delivery --------------------------
// -------------------------- Driver --------------------------
export const firestoreGetDriverLogByBranch = ({ branch, from, to }, callback) => (dispatch) => {
  const currentUser = firebase.auth().currentUser;
  const owner = currentUser && currentUser.uid;
  const logsRef = firebase.firestore().collection("driverLog");
  const fromDate = moment(from).startOf("day")._d;
  const toDate = moment(to).endOf("day")._d;
  logsRef
    .where("owner", "==", owner)
    .where("branch", "==", branch)
    .where("createAt", ">=", fromDate)
    .where("createAt", "<=", toDate)
    .get()
    .then((snapshot) => {
      if (snapshot) {
        const data = snapshot.docs.map((doc) => doc.data());
        getReportDriverLogSuccess(dispatch, data);
      }
      if (callback) {
        callback();
      }
    });
};

const getReportDriverLogSuccess = (dispatch, data) => {
  dispatch({
    type: "get_report_driver_log_success",
    payload: data,
  });
};
