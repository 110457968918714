import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  CssBaseline,
  Container,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
  InputAdornment,
} from "@material-ui/core";
import { VisibilityOutlined, SearchOutlined, DeleteOutline } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { orderBy, uniqWith } from "lodash";
import { firestoreGetBranchList, firestoreGetUserList } from "../../store/actions/firebase.action";
import { firestoreGetRosterBranch, unSubRoster, firestoreDeleteRosterList } from "../../store/actions/roster.action";
import Navbar from "../00_Nav/nav_bar";
import RightNav from "../00_Nav/right_nav";
import RosterTable from "./roster_table";

class RostersScene extends Component {
  // ====================== Inits ====================== //

  constructor() {
    super();
    this.state = {
      branch: "",
      from: moment().startOf("isoWeek"),
      to: moment().endOf("isoWeek"),
      loading: false,
      search: "",
      searchLoading: false,
      by: "date",
      order: "asc",
      staff: null,
    };
  }

  componentDidMount = () => {
    this.props.firestoreGetBranchList(); // get Branch
    this.props.firestoreGetUserList(); // get users
  };

  refineData = () => {
    const { staff, order, by } = this.state;
    const { rosters, users } = this.props;
    let reportData = rosters?.map((roster) => {
      const staff = users?.find((item) => item?.id === roster?.staffId);
      const at = moment(roster?.createAt?.toDate()).format("DD MM YYYY hh:mm:ss A");
      const start = roster?.off ? "Off" : moment(roster?.workAt?.toDate()).format("hh:mm A");
      const end = roster?.off ? "" : moment(roster?.endAt?.toDate()).format("hh:mm A");
      const fullName = `${staff?.firstName} ${staff?.lastName}`;
      return {
        ...roster,
        at,
        start,
        end,
        fullName,
      };
    });
    if (staff) {
      reportData = reportData.filter((item) => item.staffId === staff.id);
    }
    const ordered = orderBy(reportData, by, order);
    this.setState({
      loading: false,
      reportData: ordered,
    });
  };

  // ====================== Functions ====================== //

  handleDateChange = (date, id) => {
    this.setState({
      [id]: date,
    });
  };

  handleSearchChange = (e) => {
    this.setState({
      search: e.target.value,
    });
  };

  handleSelect = (e, id) => {
    this.setState({
      [id]: e.target.value,
    });
  };

  handleSelectStatus = (e) => {
    this.setState(
      {
        selectedStatus: e.target.value,
      },
      () => this.refineData()
    );
  };

  handleSort = (id) => {
    const { by, order } = this.state;
    const isAsc = by === id && order === "asc";
    this.setState(
      {
        order: isAsc ? "desc" : "asc",
        by: id,
      },
      () => {
        this.refineData();
      }
    );
  };

  // Select
  toggleSelectRecord = (data) => {
    const { selected } = this.state;
    let newCheck = [];
    const exist = selected && selected.filter((c) => c.itemCode === data.itemCode).length > 0;
    if (exist) {
      newCheck = selected && selected.filter((c) => !(c.itemCode === data.itemCode));
    } else {
      newCheck = [...selected, data];
    }
    this.setState({
      selected: newCheck,
    });
  };

  toggleCheckAll = () => {
    const { selected, data } = this.state;
    let isSelectedAll = false;
    if (selected && data && data.length === selected.length) {
      isSelectedAll = true;
    }
    this.setState({
      selected: isSelectedAll ? [] : data,
    });
  };

  selectContact = (staff) => {
    this.setState(
      {
        staff,
      },
      () => this.refineData()
    );
  };

  // ====================== Firebase Functions ====================== //

  viewRostersReport = () => {
    const { from, to, branch } = this.state;
    if (from && to && branch) {
      this.props.unSubRoster();
      this.setState(
        {
          loading: true,
        },
        () => {
          this.props.firestoreGetRosterBranch({ branch, from, to }, () => {
            this.setState(
              {
                loading: false,
              },
              () => this.refineData()
            );
          });
        }
      );
    }
  };

  deleteDublicate = () => {
    const { rosters, users } = this.props;
    const filter = rosters?.map((row) => {
      const start = row?.off ? "Off" : moment(row?.workAt?.toDate()).format("hh:mm A");
      const end = row?.off ? "" : moment(row?.endAt?.toDate()).format("hh:mm A");
      const staff = users?.find((item) => item?.id === row?.staffId);
      const fullName = `${staff?.username} ${staff?.firstName} ${staff?.lastName}`;
      return {
        ...row,
        start,
        end,
        fullName,
      };
    });
    const unique = uniqWith(
      filter,
      (a, b) =>
        a.date === b.date && a.off === b.off && a.start === b.start && a.end === b.end && a.staffId === b.staffId
    );
    const ids = unique.map((item) => item.id);
    const deleteList = rosters.filter((item) => !ids?.includes(item.id));
    this.setState(
      {
        loading: true,
      },
      () => {
        this.props.firestoreDeleteRosterList({ list: deleteList }, () => {
          this.setState(
            {
              loading: false,
            },
            () => this.refineData()
          );
        });
      }
    );
  };

  // ====================== Render Component ====================== //

  renderBranchDropDown = () => {
    const { branchs } = this.props;
    const { branch } = this.state;
    const menuItem = branchs?.map((branch, index) => {
      return (
        <MenuItem key={index} value={branch.id}>
          {branch.tradingName}
        </MenuItem>
      );
    });
    return (
      <FormControl variant="outlined" margin="dense" fullWidth className="table-select">
        <InputLabel id="branchLabel">Branch</InputLabel>
        <Select
          labelId="branchLabel"
          id="branch"
          value={branch || ""}
          onChange={(event) => this.handleSelect(event, "branch")}
          label="Branch"
        >
          {menuItem}
        </Select>
      </FormControl>
    );
  };

  renderRow01 = () => {
    return (
      <Grid container spacing={3} direction="row">
        <Grid item md={2} xs={12}>
          <h3 className="regular-title" style={{ marginTop: 10, fontSize: 20, marginBottom: 0 }}>
            Roster Management
          </h3>
        </Grid>
        <Grid item md={2} xs={12}>
          {this.renderBranchDropDown()}
        </Grid>
      </Grid>
    );
  };

  renderRow02 = () => {
    const { from, to, branch } = this.state;
    const disabled = !branch;
    return (
      <Grid container spacing={3} direction="row" alignItems="center">
        <Grid item md={2} xs={12}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              autoOk
              className="date-picker"
              variant="inline"
              inputVariant="outlined"
              fullWidth
              value={from}
              maxDate={to}
              label="From"
              format="DD/MM/YYYY"
              InputAdornmentProps={{ position: "end" }}
              onChange={(date) => this.handleDateChange(date, "from")}
              margin="dense"
              disabled={disabled}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item md={2} xs={12}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              autoOk
              className="date-picker"
              variant="inline"
              inputVariant="outlined"
              fullWidth
              minDate={from}
              value={to}
              label="To"
              format="DD/MM/YYYY"
              InputAdornmentProps={{ position: "end" }}
              onChange={(date) => this.handleDateChange(date, "to")}
              margin="dense"
              disabled={disabled}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item>{this.renderViewButton()}</Grid>
        <Grid item>{this.renderDeleteButton()}</Grid>
      </Grid>
    );
  };

  renderViewButton = () => {
    const { from, to, branch, loading } = this.state;
    const disabled = !(from && to && branch && !loading);
    if (loading) {
      return (
        <Button
          variant="contained"
          color="primary"
          disabled
          style={{ marginTop: 3 }}
          startIcon={<CircularProgress size={15} />}
        >
          View
        </Button>
      );
    }
    return (
      <Button
        variant="contained"
        color="primary"
        disabled={disabled}
        style={{ marginTop: 3 }}
        startIcon={<VisibilityOutlined />}
        onClick={() => this.viewRostersReport()}
      >
        View
      </Button>
    );
  };

  renderDeleteButton = () => {
    const { loading } = this.state;
    const { rosters } = this.props;
    const disabled = !rosters || loading;
    if (loading) {
      return (
        <Button
          variant="contained"
          color="secondary"
          disabled
          style={{ marginTop: 3 }}
          startIcon={<CircularProgress size={15} />}
        >
          Delete Dubplicate
        </Button>
      );
    }
    return (
      <Button
        variant="contained"
        color="secondary"
        disabled={disabled}
        style={{ marginTop: 3 }}
        startIcon={<DeleteOutline />}
        onClick={() => this.deleteDublicate()}
      >
        Delete Dubplicate
      </Button>
    );
  };

  renderRow03 = () => {
    return (
      <Grid container spacing={3} direction="row" alignItems="center">
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {this.renderSearchAutocomplete()}
        </Grid>
      </Grid>
    );
  };

  renderSearchAutocomplete = () => {
    const { branch } = this.state;
    const { users } = this.props;
    const disabled = !users;
    const options = users?.filter((item) => item?.branch === branch);
    return (
      <Autocomplete
        id="product-search"
        onChange={(e, value) => this.selectContact(value)}
        // value={staff}
        getOptionSelected={(option) => option}
        getOptionLabel={(option) => {
          const username = option?.username;
          const firstName = option?.firstName;
          const lastName = option?.lastName;
          const fullName = `${username} - ${firstName} ${lastName}`;
          return fullName;
        }}
        options={options || []}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="dense"
            label="Filter By Name"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    );
  };

  // ====================== Render Main ====================== //

  render() {
    const { auth } = this.props;
    if (!auth.uid) return <Redirect to={"/signin"} />;
    const { reportData, selected, order, by, loading } = this.state;
    return (
      <div className="root-container">
        <CssBaseline />
        <Navbar title="Roster Management" right={<RightNav />} />
        <Container maxWidth="xl" className="report report-time-tracking">
          {this.renderRow01()}
          {this.renderRow02()}
          {this.renderRow03()}
          <RosterTable
            reportData={reportData}
            selected={selected}
            order={order}
            orderBy={by}
            loading={loading}
            handleSort={this.handleSort}
            handleCheckAll={this.toggleCheckAll}
            handleCheck={this.toggleSelectRecord}
          />
        </Container>
              
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  branchs: state.firebaseWeb.branchs,
  rosters: state.rosterReducer.rosters,
  users: state.firebaseWeb.users,
});

const mapDispatchToProps = {
  firestoreGetBranchList,
  firestoreGetUserList,
  firestoreGetRosterBranch,
  unSubRoster,
  firestoreDeleteRosterList,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(RostersScene);
