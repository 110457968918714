import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Loading } from "../../component/loading";

class ReportMonthlyTopupTable extends Component {
  // ====================== Inits ====================== //

  constructor() {
    super();
    this.state = {
      edited: [],
      editError: [],
      headerStart: [
        { id: "inventoryItemCode", label: "Inventory", align: "left", sortable: false },
        { id: "description", label: "Description", align: "left", sortable: false },
        { id: "unitAmount", label: "Unit Price", align: "left", sortable: false },
      ],
      headerEnd: [
        { id: "totalQ", label: "Total Qty", align: "right", sortable: false },
        { id: "total", label: "Total $", align: "right", sortable: false },
      ],
    };
  }

  // ====================== Functions ====================== //

  isSelected = (data) => {
    const { selected } = this.props;
    const exist = selected && selected.find((item) => item.itemCode === data.itemCode);
    return exist ? true : false;
  };

  isCheckedAll = () => {
    const { selected, reportData } = this.props;
    return selected && reportData && selected.length === reportData.length ? true : false;
  };

  // ====================== Data Functions ====================== //

  // ====================== Firebase Functions ====================== //

  // ====================== Render Component ====================== //

  renderTableHead = () => {
    const { orderBy, order, dates } = this.props;
    const { headerStart, headerEnd } = this.state;
    const headerDates =
      dates &&
      dates.map((item) => {
        return { id: item, label: item, align: "center", sortable: false };
      });
    const newHeader = dates && [...headerStart, ...headerDates, ...headerEnd];
    return (
      <TableHead>
        <TableRow>
          {newHeader &&
            newHeader.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.align}
                padding="default"
                sortDirection={orderBy === headCell.id ? order : false}
              >
                {headCell.label}
              </TableCell>
            ))}
        </TableRow>
      </TableHead>
    );
  };

  renderTableBody = () => {
    const { reportData, loading } = this.props;
    if (!loading) {
      return (
        <TableBody>
          {reportData && reportData.map((row, index) => this.renderTableRow({ row, index }))}
        </TableBody>
      );
    }
    return <TableBody />;
  };

  renderTableRow = ({ row, index }) => {
    const { dates } = this.props;
    const data = row && row.data;
    const isSecond = row && row.isSecond;
    const totalQuantity = row && row.totalQuantity;
    const totalPrice = row && row.totalPrice;
    const totalPriceFix = totalPrice && totalPrice.toFixed(2);
    const inventoryItemCode = row && row.inventoryItemCode;
    const description = row && row.description;
    const unitAmount = row && row.unitAmount;
    return (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={index}
        className={isSecond ? "table-second" : ""}
      >
        <TableCell align="left">{inventoryItemCode}</TableCell>
        <TableCell align="left">{description}</TableCell>
        <TableCell align="left">{unitAmount}</TableCell>
        {dates && dates.map((item, index) => this.renderTableCell({ item, index, data }))}
        <TableCell align="right">{totalQuantity}</TableCell>
        <TableCell align="right">{totalPriceFix}</TableCell>
      </TableRow>
    );
  };

  renderTableCell = ({ item, index, data }) => {
    const selected = data && data.find((r) => r.date === item);
    const quantity = selected ? selected.quantity : "";
    return (
      <TableCell align="center" key={index}>
        {quantity}
      </TableCell>
    );
  };

  // ====================== Render Modal ====================== //

  // ====================== Render Main ====================== //

  render() {
    const { loading } = this.props;
    return (
      <Paper style={{ width: "100%", marginTop: 10, marginBottom: 30 }}>
        <TableContainer className="report-table">
          <Table stickyHeader aria-label="sticky table">
            {this.renderTableHead()}
            {this.renderTableBody()}
          </Table>
          <Loading loading={loading} />
        </TableContainer>
      </Paper>
    );
  }
}

export default compose(connect(null, null))(ReportMonthlyTopupTable);
