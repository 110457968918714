import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  CssBaseline,
  Container,
  Grid,
  TextField,
  InputAdornment,
  Tooltip,
  IconButton,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import { CloudDownloadOutlined, CloudUploadOutlined, SearchOutlined } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import collection from "lodash/collection";
import { maxBy } from "lodash";
import moment from "moment";
import {
  firestoreGetTemplateAndContactListByBranch,
  firestoreGetTemplateListByContact,
  firestoreGetBranchList,
} from "../../store/actions/firebase.action";
import Navbar from "../00_Nav/nav_bar";
import RightNav from "../00_Nav/right_nav";
import { exportTemplatesToCSV } from "./functions/export_template_csv";
import TemplateDialogCsvUpload from "./dialog/template_dialog_csv_upload";
import TemplateTable from "./template_table";
import TemplateDetail from "./template_detail";

class TemplateScene extends Component {
  // ====================== Inits ====================== //

  constructor() {
    super();
    this.state = {
      loading: false,
      error: "",
      data: null,
      selectedContact: null,
      selectedEditContact: null,
      selected: [],
      csvUpload: false,
      orderBy: "contactName",
      order: "asc",
      templateLoading: false,
      branch: null,
    };
  }

  componentDidMount = () => {
    this.props.firestoreGetBranchList();
  };

  updateData = () => {
    const { branch } = this.state;
    this.setState(
      {
        loading: true,
      },
      () => {
        this.props.firestoreGetTemplateAndContactListByBranch({ branch }, () => {
          this.setState(
            {
              loading: false,
            },
            () => this.refineData()
          );
        });
      }
    );
  };

  // ====================== Functions ====================== //

  handleSelectBranch = (event) => {
    const branch = event.target.value;
    this.setState(
      {
        branch,
      },
      () => this.updateData()
    );
  };

  selectContact = (selectedContact) => {
    this.setState(
      {
        selectedContact,
      },
      () => this.refineData()
    );
  };

  selectedRow = (contact) => {
    const { branch } = this.state;
    this.setState(
      {
        selectedEditContact: contact,
        templateLoading: true,
      },
      () =>
        this.props.firestoreGetTemplateListByContact({ contact, branch }, () => {
          this.setState({
            templateLoading: false,
          });
        })
    );
  };

  getModifiedOn = (contactTemplate) => {
    const updateArr = contactTemplate && contactTemplate.filter((item) => item.updateAt);
    console.log(updateArr);
    const max = updateArr && maxBy(updateArr, "updateAt");
    const maxUpdate = max && max.updateAt && max.updateAt.toDate();
    const maxDate = maxUpdate && moment(maxUpdate).format("DD/MM/YYYY");
    // if (hasUpdateAt.length > 0) {
    //     const arr = hasUpdateAt && hasUpdateAt.map(item => {
    //         return item.updateAt.toDate();
    //     });
    //     return moment(max(arr)).format('DD/MM/YYYY');
    // }
    return maxDate || "";
  };

  refineData = () => {
    const { selectedContact, order, orderBy } = this.state;
    const { contacts, templates } = this.props;
    let data = contacts;
    if (contacts && selectedContact) {
      data =
        contacts && contacts.filter((item) => item.contactName === selectedContact.contactName);
    }
    // Lọc templates
    data = data.map((item) => {
      const contactTemplate =
        templates && templates.filter((t) => t.contactName === item.contactName);
      const length = contactTemplate ? contactTemplate.length : 0;
      const createAt = length > 0 && contactTemplate[0].createAt;
      const createdOn = createAt && moment(createAt.toDate()).format("DD/MM/YYYY");
      // Tìm max thời điểm update cuối
      const modifiedOn = this.getModifiedOn(contactTemplate);
      return {
        ...item,
        templates: contactTemplate,
        totalItems: length,
        createdOn,
        modifiedOn,
      };
    });
    if (order && orderBy) {
      data = collection.orderBy(data, [orderBy], [order]);
    }
    this.setState({
      data,
    });
  };

  handleSort = (id) => {
    const { orderBy, order } = this.state;
    const isAsc = orderBy === id && order === "asc";
    this.setState(
      {
        order: isAsc ? "desc" : "asc",
        orderBy: id,
      },
      () => {
        this.refineData();
      }
    );
  };

  // CSV upload
  openCSVUpload = () => {
    this.setState({
      csvUpload: true,
    });
  };

  closeCSVUpload = () => {
    this.setState({
      csvUpload: false,
    });
  };

  successCSVUpload = () => {
    this.setState(
      {
        csvUpload: false,
      },
      () => this.updateData()
    );
  };

  // Select
  toggleSelectRecord = (data) => {
    const { selected } = this.state;
    let newCheck = [];
    const exist = selected && selected.filter((c) => c.contactName === data.contactName).length > 0;
    if (exist) {
      newCheck = selected && selected.filter((c) => !(c.contactName === data.contactName));
    } else {
      newCheck = [...selected, data];
    }
    this.setState({
      selected: newCheck,
    });
  };

  toggleCheckAll = () => {
    const { selected, data } = this.state;
    let isSelectedAll = false;
    if (selected && data && data.length === selected.length) {
      isSelectedAll = true;
    }
    this.setState({
      selected: isSelectedAll ? [] : data,
    });
  };

  // ====================== Firebase Functions ====================== //

  // ====================== Render Component ====================== //

  renderBranchDropDown = () => {
    const { branchs } = this.props;
    const { branch } = this.state;
    const menuItem =
      branchs &&
      branchs.map((branch, index) => {
        return (
          <MenuItem key={index} value={branch.id}>
            {branch.tradingName}
          </MenuItem>
        );
      });
    return (
      <FormControl variant="outlined" margin="dense" fullWidth className="table-select">
        <InputLabel id="branchLabel">Branch</InputLabel>
        <Select
          labelId="branchLabel"
          id="branch"
          value={branch || ""}
          onChange={(event) => this.handleSelectBranch(event)}
          label="Branch"
        >
          {menuItem}
        </Select>
      </FormControl>
    );
  };

  renderTopBar = () => {
    return (
      <Grid container spacing={1} direction="row" justify="flex-start" alignItems="center">
        <Grid item lg={2} md={12} sm={12} xs={12}>
          {this.renderBranchDropDown()}
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12}>
          {this.renderSearchAutocomplete()}
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          {this.renderRightButtonGroup()}
        </Grid>
      </Grid>
    );
  };

  renderSearchAutocomplete = () => {
    const { selectedContact } = this.state;
    const { contacts } = this.props;
    const disabled = !contacts;
    return (
      <Autocomplete
        id="product-search"
        onChange={(e, value) => this.selectContact(value)}
        value={selectedContact}
        getOptionSelected={(option) => option}
        getOptionLabel={(option) => {
          const contactName = option.contactName;
          const firstName = option.firstName;
          const lastName = option.lastName;
          const fullName = `${contactName} - ${firstName} ${lastName}`;
          return fullName;
        }}
        options={contacts || []}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="dense"
            label="Search By Bussiness Name / Contact Person"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    );
  };

  renderRightButtonGroup = () => {
    const { data } = this.state;
    const length = (data && data.length) || 0;
    return (
      <Grid container spacing={1} justify="flex-end" alignItems="flex-end">
        {this.renderExportImportUser()}
        <Grid item>
          <div className="total-user" style={{ fontSize: 14 }}>
            Total contacts: {length}
          </div>
        </Grid>
      </Grid>
    );
  };

  renderExportImportUser = () => {
    const { branch } = this.state;
    const { templates, branchs } = this.props;
    const disabled = !branch;
    return (
      <Grid item>
        <Tooltip title="Export template to csv">
          <span>
            <IconButton
              aria-label="download"
              onClick={() => exportTemplatesToCSV({ templates, branchs, branch })}
              disabled={disabled}
            >
              <CloudDownloadOutlined style={{ color: disabled ? "#c8c8c8" : "#333" }} />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Import template from csv">
          <span>
            <IconButton
              aria-label="upload"
              onClick={() => this.openCSVUpload()}
              disabled={disabled}
            >
              <CloudUploadOutlined style={{ color: disabled ? "#c8c8c8" : "#333" }} />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
    );
  };

  // ====================== Render Main ====================== //

  render() {
    const { auth } = this.props;
    if (!auth.uid) return <Redirect to={"/signin"} />;
    const {
      csvUpload,
      data,
      selected,
      order,
      orderBy,
      loading,
      selectedEditContact,
      templateLoading,
      branch,
    } = this.state;
    return (
      <div className="root-container">
        <CssBaseline />
        <Navbar title="Order Templates" right={<RightNav />} />
        <Container maxWidth="xl" className="contacts-services-container">
          {this.renderTopBar()}
          <Grid container spacing={1}>
            <Grid item lg={6} md={5} sm={12} xs={12}>
              <TemplateTable
                reportData={data}
                loading={loading}
                selected={selected}
                order={order}
                orderBy={orderBy}
                handleSort={this.handleSort}
                handleCheckAll={this.toggleCheckAll}
                handleCheck={this.toggleSelectRecord}
                handleUpdate={this.updateData}
                selectedRow={this.selectedRow}
                selectedEditContact={selectedEditContact}
              />
            </Grid>
            <Grid item lg={6} md={7} sm={12} xs={12}>
              <TemplateDetail
                selectedEditContact={selectedEditContact}
                loading={templateLoading}
                branch={branch}
              />
            </Grid>
          </Grid>
          <TemplateDialogCsvUpload
            dialogOpen={csvUpload}
            handleClose={this.closeCSVUpload}
            handleConfirm={this.successCSVUpload}
            branch={branch}
          />
        </Container>
              
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  contacts: state.firebaseWeb.contacts,
  templates: state.firebaseWeb.templates,
  branchs: state.firebaseWeb.branchs,
});

const mapDispatchToProps = {
  firestoreGetTemplateAndContactListByBranch,
  firestoreGetTemplateListByContact,
  firestoreGetBranchList,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(TemplateScene);
