/* eslint-disable no-unused-vars */
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import moment from "moment";
import { allProgress } from "../../component/functions";
import { COMPLETED } from "../../config/stringConfig";

// -------------------------- LOGIN --------------------------
export const firebaseLogin =
  ({ email, password }) =>
  (dispatch) => {
    firebaseLoading(dispatch);
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        firebaseLoginSuccess(dispatch, user, password);
      })
      .catch((error) => firebaseLoginFail(dispatch, error.message)); // Lỗi
  };

const firebaseLoading = (dispatch) => {
  dispatch({
    type: "firebase_loading",
  });
};

const firebaseLoginSuccess = (dispatch, user) => {
  dispatch({
    type: "firebase_login_success",
    payload: user,
  });
};

const firebaseLoginFail = (dispatch, error) => {
  dispatch({
    type: "firebase_login_fail",
    payload: error,
  });
};

// Logout
export const firebaseLogout = () => (dispatch) => {
  // const firebase = getFirebase();
  firebase
    .auth()
    .signOut()
    .then(() => firebaseLogoutSuccess(dispatch)); // Thành công
};

const firebaseLogoutSuccess = (dispatch) => {
  dispatch({
    type: "firebase_logout_success",
  });
};

// -------------------------- User --------------------------

export const firestoreObserveOwner = () => (dispatch) => {
  const ownerRef = firebase.firestore().collection("users").where("role", "==", "0");
  ownerRef.onSnapshot((snapshot) => {
    if (snapshot) {
      const data = snapshot.docs.map((doc) => doc.data());
      getOwnerSuccess(dispatch, data);
    }
  });
};

const getOwnerSuccess = (dispatch, data) => {
  dispatch({
    type: "get_owner_success",
    payload: data,
  });
};

// -------------------------- Group --------------------------

export const firestoreGetUserGroup = () => (dispatch) => {
  const currentUser = firebase.auth().currentUser;
  const owner = currentUser && currentUser.uid;
  const userGroup = firebase.firestore().collection("userGroup");
  userGroup
    .where("owner", "==", owner)
    .orderBy("createAt")
    .get()
    .then((snapshot) => {
      if (snapshot) {
        const data = snapshot.docs.map((doc) => doc.data());
        getUserGroupSuccess(dispatch, data);
      }
    });
};

const getUserGroupSuccess = (dispatch, data) => {
  dispatch({
    type: "get_user_group_success",
    payload: data,
  });
};

export const firestoreAddUserGroup = (name, callback) => (dispatch) => {
  const currentUser = firebase.auth().currentUser;
  const owner = currentUser && currentUser.uid;
  const userGroup = firebase.firestore().collection("userGroup");
  const id = userGroup.doc().id;
  const data = {
    // tạo group data
    id,
    name,
    createAt: firebase.firestore.FieldValue.serverTimestamp(),
    owner,
  };
  userGroup
    .doc(id)
    .set(data, { merge: true })
    .then(() => {
      if (callback) {
        callback();
      }
    });
};

export const firestoreUpdateUserGroupName = (data, name, callback) => (dispatch) => {
  const userGroup = firebase.firestore().collection("userGroup");
  const id = data.id;
  const newData = {
    // tạo group data
    ...data,
    name,
  };
  userGroup
    .doc(id)
    .set(newData, { merge: true })
    .then(() => {
      if (callback) {
        callback();
      }
    });
};

export const firestoreDeleteUserGroup = (data, callback) => (dispatch) => {
  const userGroup = firebase.firestore().collection("userGroup");
  const id = data.id;
  userGroup
    .doc(id)
    .delete()
    .then(() => {
      if (callback) {
        callback();
      }
    });
};

// -------------------------- Branch --------------------------

export const firestoreGetBranchList = (callback) => (dispatch) => {
  const currentUser = firebase.auth().currentUser;
  const owner = currentUser && currentUser.uid;
  const branchRef = firebase.firestore().collection("branch");
  branchRef
    .where("owner", "==", owner)
    .orderBy("createAt")
    .get()
    .then((snapshot) => {
      if (snapshot) {
        const data = snapshot.docs.map((doc) => doc.data());
        getGetBranchSuccess(dispatch, data);
      }
      if (callback) {
        callback();
      }
    });
};

const getGetBranchSuccess = (dispatch, data) => {
  dispatch({
    type: "get_branch_list_success",
    payload: data,
  });
};

export const firestoreAddBranch = (branch, callback) => (dispatch) => {
  const currentUser = firebase.auth().currentUser;
  const owner = currentUser && currentUser.uid;
  const branchRef = firebase.firestore().collection("branch");
  const id = branchRef.doc().id;
  const data = {
    // tạo branch data
    ...branch,
    id,
    createAt: firebase.firestore.FieldValue.serverTimestamp(),
    owner,
  };
  branchRef
    .doc(id)
    .set(data, { merge: true })
    .then(() => {
      if (callback) {
        callback(data);
      }
    });
};

export const firestoreUpdateBranch = (branch, callback) => (dispatch) => {
  const branchRef = firebase.firestore().collection("branch");
  branchRef
    .doc(branch.id)
    .set(branch, { merge: true })
    .then(() => {
      if (callback) {
        callback(branch);
      }
    });
};

export const firestoreDeleteBranch = (branch, callback) => (dispatch) => {
  const branchRef = firebase.firestore().collection("branch");
  branchRef
    .doc(branch.id)
    .delete()
    .then(() => {
      if (callback) {
        callback();
      }
    });
};

export const firestoreUploadBranchLogo = (branch, callback) => (dispatch) => {
  const currentUser = firebase.auth().currentUser;
  const owner = currentUser && currentUser.uid;
  const logoUrl = branch && branch.logoUrl;
  const regex = /^(http|https):\/\/[^ "]+$/; // url
  const isUri = logoUrl && !regex.test(logoUrl); // kiểm tra có phải uri không
  if (isUri) {
    const storageRef = firebase.storage().ref();
    const logoRef = storageRef.child(`images/${owner}/branchs/${branch.tradingName}.jpg`);
    if (logoUrl) {
      const uploadTask = logoRef.putString(logoUrl, "data_url");
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // this.setState({
          //     completed: progress
          // });
        },
        (error) => {
          // Handle unsuccessful uploads
          // console.log(error);
        },
        () => {
          // Handle complete
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            callback(downloadURL);
          });
        }
      );
    }
  } else {
    if (callback) {
      callback(logoUrl);
    }
  }
};

// -------------------------- User --------------------------

export const firestoreGetUserList = (callback) => (dispatch) => {
  const currentUser = firebase.auth().currentUser;
  const owner = currentUser && currentUser.uid;
  const userRef = firebase.firestore().collection("user");
  userRef
    .where("owner", "==", owner)
    .orderBy("createAt")
    .get()
    .then((snapshot) => {
      if (snapshot) {
        const data = snapshot.docs.map((doc) => doc.data());
        getGetUserListSuccess(dispatch, data);
      }
      if (callback) {
        callback();
      }
    });
};

const getGetUserListSuccess = (dispatch, data) => {
  dispatch({
    type: "get_user_list_success",
    payload: data,
  });
};

export const firestoreAddUser = (user, callback) => (dispatch) => {
  const currentUser = firebase.auth().currentUser;
  const owner = currentUser && currentUser.uid;
  const userRef = firebase.firestore().collection("user");
  const id = userRef.doc().id;
  const data = {
    // tạo branch data
    ...user,
    id,
    createAt: firebase.firestore.FieldValue.serverTimestamp(),
    owner,
  };
  userRef
    .doc(id)
    .set(data, { merge: true })
    .then(() => {
      if (callback) {
        callback(data);
      }
    });
};

export const firestoreDeleteAllUser =
  ({ json }, callback) =>
  (dispatch) => {
    const currentUser = firebase.auth().currentUser;
    const owner = currentUser && currentUser.uid;
    const userRef = firebase.firestore().collection("user");
    const userRefDelete = firebase.firestore().collection("user").where("owner", "==", owner);
    userRefDelete.get().then((snapshot) => {
      if (snapshot) {
        // nếu có dữ liệu thì xóa sạch
        const data = snapshot.docs.map((doc) => doc.data());
        let deleteData = [];
        data &&
          data.forEach((item) => {
            const exist = json.find((j) => j.username === item.username);
            if (exist) {
              deleteData = [...deleteData, item];
            }
          });
        const allPromise =
          deleteData &&
          deleteData.map((item) => {
            return userRef.doc(item.id).delete();
          });
        Promise.all(allPromise).then(() => {
          if (callback) {
            callback();
          }
        });
      } else {
        // nếu không có thì thôi
        if (callback) {
          callback();
        }
      }
    });
  };

// Nhập csv user (mới)
export const firestoreImportUserByJson =
  ({ json }, callback) =>
  (dispatch) => {
    const currentUser = firebase.auth().currentUser;
    const owner = currentUser && currentUser.uid;
    const userRef = firebase.firestore().collection("user");
    userRef
      .where("owner", "==", owner)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          const allPromise =
            json &&
            json.map((item) => {
              const exist = data.find((d) => d.username === item.username);
              if (exist) {
                // tồn tại, lấy id
                const id = exist.id;
                const newData = {
                  ...exist,
                  ...item, // ghi đè
                  updateAt: firebase.firestore.FieldValue.serverTimestamp(),
                };
                return userRef.doc(id).update(newData);
              } else {
                // tạo product data
                const id = userRef.doc().id;
                const newData = {
                  ...item,
                  id,
                  owner,
                  createAt: firebase.firestore.FieldValue.serverTimestamp(),
                };
                return userRef.doc(id).set(newData, { merge: true });
              }
            });
          allProgress(allPromise, (p) => {
            const percent = `${p.toFixed(0)}%`;
            if (p === 100 && callback) {
              callback("completed");
            } else {
              callback(percent);
            }
          });
        }
      });
  };

export const firestoreUpdateUser =
  ({ user }, callback) =>
  (dispatch) => {
    const userRef = firebase.firestore().collection("user");
    const id = user.id;
    userRef
      .doc(id)
      .update(user)
      .then(() => {
        if (callback) {
          callback();
        }
      });
  };

export const firestoreUploadAvatar = (user, callback) => (dispatch) => {
  const currentUser = firebase.auth().currentUser;
  const owner = currentUser && currentUser.uid;
  const avatarUrl = user && user.avatar;
  const regex = /^(http|https):\/\/[^ "]+$/; // url
  const isUri = avatarUrl && !regex.test(avatarUrl); // kiểm tra có phải uri không
  if (isUri) {
    const storageRef = firebase.storage().ref();
    const avatarRef = storageRef.child(`images/${owner}/avatar/${user.username}.jpg`);
    if (avatarUrl) {
      const uploadTask = avatarRef.putString(avatarUrl, "data_url");
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          // Handle unsuccessful uploads
          // console.log(error);
        },
        () => {
          // Handle complete
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            callback(downloadURL);
          });
        }
      );
    }
  } else {
    const returnUrl = avatarUrl || "";
    if (callback) {
      callback(returnUrl);
    }
  }
};

// -------------------------- Time Tracking --------------------------

export const firestoreGetTimeTrackingListByBranch =
  ({ branch, from, to }, callback) =>
  (dispatch) => {
    const currentUser = firebase.auth().currentUser;
    const owner = currentUser && currentUser.uid;
    const timeTrackingRef = firebase.firestore().collection("checkInOut");
    const fromDate = moment(from).startOf("day")._d;
    const toDate = moment(to).endOf("day")._d;
    timeTrackingRef
      .where("owner", "==", owner)
      .where("branch", "==", branch)
      .where("checkedAt", ">=", fromDate)
      .where("checkedAt", "<=", toDate)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          getTimeTrackingSuccess(dispatch, data);
        }
        if (callback) {
          callback();
        }
      });
  };

const getTimeTrackingSuccess = (dispatch, data) => {
  dispatch({
    type: "get_time_tracking_list_success",
    payload: data,
  });
};

export const firestoreGetTimeTrackingByUser =
  ({ selectedUser, date }, callback) =>
  (dispatch) => {
    const owner = selectedUser.owner;
    const branch = selectedUser.branch;
    const user = selectedUser.id;
    const fromDate = moment(date).startOf("day")._d;
    const toDate = moment(date).endOf("day")._d;
    const timeTrackingRef = firebase.firestore().collection("checkInOut");
    timeTrackingRef
      .where("owner", "==", owner)
      .where("branch", "==", branch)
      .where("user", "==", user)
      .where("checkedAt", ">=", fromDate)
      .where("checkedAt", "<=", toDate)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          if (callback) {
            callback(data);
          }
        } else {
          if (callback) {
            callback([]);
          }
        }
      });
  };

export const firestoreTimeTracking =
  ({ data }, callback) =>
  (dispatch) => {
    const checkRef = firebase.firestore().collection("checkInOut");
    const id = checkRef.doc().id;
    const newData = {
      ...data,
      id,
      createAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
    checkRef.doc(id).set(newData, { merge: true });
    if (callback) {
      callback();
    }
  };

export const firestoreTimeTrackingHandleTasks =
  ({ tasks }, callback) =>
  (dispatch) => {
    const addTasks = tasks.filter((task) => task.type === "add");
    const updateTask = tasks.filter((task) => task.type === "update");
    const deleteTask = tasks.filter((task) => task.type === "delete");
    const addPromise =
      addTasks &&
      addTasks.map((task) => {
        const addRef = firebase.firestore().collection("checkInOut");
        const checkedAt = task.checkedAt;
        const branch = task.user && task.user.branch;
        const user = task.user && task.user.id;
        const owner = task.user && task.user.owner;
        const id = addRef.doc().id;
        const data = {
          checkedAt,
          user,
          owner,
          branch,
          screenshot: "",
          subUrl: "admin",
          longitude: "",
          latitude: "",
          deviceName: "admin",
          id,
          createAt: firebase.firestore.FieldValue.serverTimestamp(),
        };
        return addRef.doc(id).set(data, { merge: true });
      });
    const updatePromise =
      updateTask &&
      updateTask.map((task) => {
        const updateRef = firebase.firestore().collection("checkInOut");
        const checkedAt = task.checkedAt;
        const id = task.recordId;
        return updateRef.doc(id).update({ checkedAt });
      });
    const deletePromise =
      deleteTask &&
      deleteTask.map((task) => {
        const deleteRef = firebase.firestore().collection("checkInOut");
        const id = task.recordId;
        return deleteRef.doc(id).delete();
      });
    const allPromise = [...addPromise, ...updatePromise, ...deletePromise];
    Promise.all(allPromise).then(() => {
      if (callback) {
        callback();
      }
    });
  };

export const firestoreImportTimeTrackingtByJson =
  ({ json, branch }, callback) =>
  (dispatch) => {
    const currentUser = firebase.auth().currentUser;
    const owner = currentUser && currentUser.uid;
    const checkRef = firebase.firestore().collection("checkInOut");
    checkRef
      .where("owner", "==", owner)
      .where("branch", "==", branch)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          const allPromise =
            json &&
            json.map((item) => {
              const exist = data.find((d) => d.id === item.id);
              if (exist) {
                // tồn tại, lấy id
                const id = exist.id;
                const newData = {
                  ...exist,
                  ...item, // ghi đè
                  updateAt: firebase.firestore.FieldValue.serverTimestamp(),
                };
                return checkRef.doc(id).update(newData);
              } else {
                // tạo product data
                const id = checkRef.doc().id;
                const newData = {
                  ...item,
                  id,
                  owner,
                  branch,
                };
                return checkRef.doc(id).set(newData, { merge: true });
              }
            });
          allProgress(allPromise, (p) => {
            const percent = `${p.toFixed(0)}%`;
            if (p === 100 && callback) {
              callback("completed");
            } else {
              callback(percent);
            }
          });
        }
      });
  };

// -------------------------- Products --------------------------

export const firestoreGetProductListByBranch =
  ({ branch }, callback) =>
  (dispatch) => {
    const currentUser = firebase.auth().currentUser;
    const owner = currentUser && currentUser.uid;
    const productRef = firebase.firestore().collection("products");
    productRef
      .where("owner", "==", owner)
      .where("branch", "==", branch)
      .orderBy("createAt", "desc")
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          getGetProductListSuccess(dispatch, data);
        }
        if (callback) {
          callback();
        }
      });
  };

export const firestoreImportProductByJson =
  ({ json, branch }, callback) =>
  (dispatch) => {
    const currentUser = firebase.auth().currentUser;
    const owner = currentUser && currentUser.uid;
    const productRef = firebase.firestore().collection("products");
    productRef
      .where("owner", "==", owner)
      .where("branch", "==", branch)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          const allPromise =
            json &&
            json.map((item) => {
              const exist = data.find((d) => d.itemCode === item.itemCode);
              if (exist) {
                // tồn tại, lấy id
                const id = exist.id;
                const newData = {
                  ...exist,
                  ...item, // ghi đè
                  updateAt: firebase.firestore.FieldValue.serverTimestamp(),
                };
                return productRef.doc(id).update(newData);
              } else {
                // tạo product data
                const id = productRef.doc().id;
                const newData = {
                  ...item,
                  id,
                  owner,
                  branch,
                  createAt: firebase.firestore.FieldValue.serverTimestamp(),
                };
                return productRef.doc(id).set(newData, { merge: true });
              }
            });
          allProgress(allPromise, (p) => {
            const percent = `${p.toFixed(0)}%`;
            if (p === 100 && callback) {
              callback("completed");
            } else {
              callback(percent);
            }
          });
        }
      });
  };

const getGetProductListSuccess = (dispatch, data) => {
  dispatch({
    type: "get_product_list_success",
    payload: data,
  });
};

export const firestoreDeleteProduct =
  ({ product, products }, callback) =>
  (dispatch) => {
    const id = product && product.id;
    const productRef = firebase.firestore().collection("products");
    productRef
      .doc(id)
      .delete()
      .then(() => {
        const newProducts = products && products.filter((item) => item.id !== id);
        getGetProductListSuccess(dispatch, newProducts);
        if (callback) {
          callback();
        }
      });
  };

// -------------------------- Contacts --------------------------

export const firestoreGetContactsListByBranch =
  ({ branch }, callback) =>
  (dispatch) => {
    const currentUser = firebase.auth().currentUser;
    const owner = currentUser && currentUser.uid;
    const contactRef = firebase.firestore().collection("contacts");
    contactRef
      .where("owner", "==", owner)
      .where("branch", "==", branch)
      .orderBy("createAt", "desc")
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          getGetContactListSuccess(dispatch, data);
        }
        if (callback) {
          callback();
        }
      });
  };

const getGetContactListSuccess = (dispatch, data) => {
  dispatch({
    type: "get_contact_list_success",
    payload: data,
  });
};

export const firestoreImportContactByJson =
  ({ json, branch }, callback) =>
  (dispatch) => {
    const currentUser = firebase.auth().currentUser;
    const owner = currentUser && currentUser.uid;
    const contactRef = firebase.firestore().collection("contacts");
    contactRef
      .where("owner", "==", owner)
      .where("branch", "==", branch)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          const allPromise =
            json &&
            json.map((item) => {
              const exist = data.find((d) => d.contactName === item.contactName);
              if (exist) {
                // tồn tại, lấy id
                const id = exist.id;
                const newData = {
                  ...exist,
                  ...item, // ghi đè
                  updateAt: firebase.firestore.FieldValue.serverTimestamp(),
                };
                return contactRef.doc(id).update(newData);
              } else {
                // tạo mới product data
                const id = contactRef.doc().id;
                const newData = {
                  ...item,
                  id,
                  createAt: firebase.firestore.FieldValue.serverTimestamp(),
                  owner,
                  branch,
                  theyOweYou: "",
                  youOweThem: "",
                };
                return contactRef.doc(id).set(newData, { merge: true });
              }
            });
          allProgress(allPromise, (p) => {
            const percent = `${p.toFixed(0)}%`;
            if (p === 100 && callback) {
              callback("completed");
            } else {
              callback(percent);
            }
          });
        }
      });
  };

// Tạo thông tin đăng nhập của contact
export const firestoreAddContactsPassword =
  ({ contact, newPassword, contacts }, callback) =>
  (dispatch) => {
    // Thành công;
    const contactRef = firebase.firestore().collection("contacts");
    const id = contact.id;
    const data = {
      ...contact,
      password: newPassword,
    };
    contactRef
      .doc(id)
      .update(data)
      .then(() => {
        const newContacts =
          contacts &&
          contacts.map((item) => {
            if (item.id === id) {
              return data;
            }
            return item;
          });
        getGetContactListSuccess(dispatch, newContacts);
        if (callback) {
          callback();
        }
      });
  };

export const firestoreDeleteContact =
  ({ contact, contacts }, callback) =>
  (dispatch) => {
    const id = contact && contact.id;
    const contactRef = firebase.firestore().collection("contacts");
    contactRef
      .doc(id)
      .delete()
      .then(() => {
        const newContacts = contacts && contacts.filter((item) => item.id !== id);
        getGetContactListSuccess(dispatch, newContacts);
        if (callback) {
          callback();
        }
      });
  };

// -------------------------- Templates --------------------------

export const firestoreGetTemplateListByContact =
  ({ contact, branch }, callback) =>
  (dispatch) => {
    const currentUser = firebase.auth().currentUser;
    const owner = currentUser && currentUser.uid;
    const templateRef = firebase.firestore().collection("templates");
    const contactName = contact.contactName;
    templateRef
      .orderBy("createAt", "asc")
      .where("owner", "==", owner)
      .where("branch", "==", branch)
      .where("contactName", "==", contactName)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          getGetContactTemplateListSuccess(dispatch, data);
        }
        if (callback) {
          callback();
        }
      });
  };

const getGetContactTemplateListSuccess = (dispatch, data) => {
  dispatch({
    type: "get_contact_template_list_success",
    payload: data,
  });
};

export const firestoreGetTemplateAndContactListByBranch =
  ({ branch }, callback) =>
  (dispatch) => {
    const currentUser = firebase.auth().currentUser;
    const owner = currentUser && currentUser.uid;
    // Templates
    const templateRef = firebase.firestore().collection("templates");
    const templatePromise = templateRef
      .where("owner", "==", owner)
      .where("branch", "==", branch)
      .orderBy("createAt", "desc")
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          getGetTemplateListSuccess(dispatch, data);
        }
      });
    // Contacts
    const contactRef = firebase.firestore().collection("contacts");
    const contactPromise = contactRef
      .where("owner", "==", owner)
      .where("branch", "==", branch)
      .orderBy("createAt", "desc")
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          getGetContactListSuccess(dispatch, data);
        }
      });
    // Products
    const productRef = firebase.firestore().collection("products");
    const productPromise = productRef
      .where("owner", "==", owner)
      .where("branch", "==", branch)
      .orderBy("createAt", "desc")
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          getGetProductListSuccess(dispatch, data);
        }
      });
    const allPromise = [templatePromise, contactPromise, productPromise];
    Promise.all(allPromise).then(() => {
      if (callback) {
        callback();
      }
    });
  };

const getGetTemplateListSuccess = (dispatch, data) => {
  dispatch({
    type: "get_template_list_success",
    payload: data,
  });
};

export const firestoreImportTemplateByJson =
  ({ json, branch, contacts }, callback) =>
  (dispatch) => {
    const currentUser = firebase.auth().currentUser;
    const owner = currentUser && currentUser.uid;
    const templateRef = firebase.firestore().collection("templates");
    templateRef
      .where("owner", "==", owner)
      .where("branch", "==", branch)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          const allPromise =
            json &&
            json.map((item) => {
              const exist = data.find(
                (d) =>
                  d.contactName === item.contactName &&
                  d.inventoryItemCode === item.inventoryItemCode
              );
              if (exist) {
                // tồn tại, lấy id
                const id = exist.id;
                const newData = {
                  ...exist,
                  ...item, // ghi đè
                  updateAt: firebase.firestore.FieldValue.serverTimestamp(),
                };
                return templateRef.doc(id).update(newData);
              } else {
                // tạo mới product data
                const contact =
                  contacts && contacts.find((c) => c.contactName === item.contactName);
                const contactId = (contact && contact.id) || null;
                const id = templateRef.doc().id;
                const newData = {
                  ...item,
                  id,
                  owner,
                  branch,
                  contactId,
                  createAt: firebase.firestore.FieldValue.serverTimestamp(),
                };
                return templateRef.doc(id).set(newData, { merge: true });
              }
            });
          allProgress(allPromise, (p) => {
            const percent = `${p.toFixed(0)}%`;
            if (p === 100 && callback) {
              callback("completed");
            } else {
              callback(percent);
            }
          });
        }
      });
  };

export const firestoreAddTemplateItemToContact =
  ({ contact, product, quantity, unitAmount, branch }, callback) =>
  (dispatch) => {
    const currentUser = firebase.auth().currentUser;
    const owner = currentUser && currentUser.uid;
    const templateRef = firebase.firestore().collection("templates");
    const contactName = contact.contactName;
    const contactId = contact.id;
    const inventoryItemCode = product.itemCode;
    const description = product.itemName;
    const id = templateRef.doc().id;
    const data = {
      id,
      createAt: firebase.firestore.FieldValue.serverTimestamp(),
      owner,
      branch,
      description,
      contactName,
      contactId,
      quantity,
      unitAmount,
      inventoryItemCode,
    };
    templateRef
      .doc(id)
      .set(data, { merge: true })
      .then(() => {
        if (callback) {
          callback();
        }
      });
  };

export const firestoreUpdateTemplateItemToContact =
  ({ editTemplate, product, quantity, unitAmount, branch }, callback) =>
  (dispatch) => {
    const templateRef = firebase.firestore().collection("templates");
    const inventoryItemCode = product?.itemCode;
    const description = product?.itemName;
    const id = editTemplate.id;
    const data = {
      ...editTemplate,
      quantity,
      unitAmount,
      description,
      inventoryItemCode,
      updateAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
    templateRef
      .doc(id)
      .update(data)
      .then(() => {
        if (callback) {
          callback();
        }
      });
  };

export const firestoreDeleteTemplateItem =
  ({ templateDelete }, callback) =>
  (dispatch) => {
    const templateRef = firebase.firestore().collection("templates");
    const id = templateDelete.id;
    templateRef
      .doc(id)
      .delete()
      .then(() => {
        if (callback) {
          callback();
        }
      });
  };

// -------------------------- Orders --------------------------

export const firestoreGetOrderListByBranch =
  ({ branch, from, to }, callback) =>
  (dispatch) => {
    const currentUser = firebase.auth().currentUser;
    const owner = currentUser && currentUser.uid;
    const ordersRef = firebase.firestore().collection("orders");
    const fromDate = moment(from).startOf("day")._d;
    const toDate = moment(to).endOf("day")._d;
    ordersRef
      .where("owner", "==", owner)
      .where("branch", "==", branch)
      .where("scheduledFor", ">=", fromDate)
      .where("scheduledFor", "<=", toDate)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          getReportOrdersSuccess(dispatch, data);
        }
        if (callback) {
          callback();
        }
      });
  };

export const firestoreGetOrderListById =
  ({ branch, orderId }, callback) =>
  (dispatch) => {
    const currentUser = firebase.auth().currentUser;
    const owner = currentUser && currentUser.uid;
    const ordersRef = firebase.firestore().collection("orders");
    ordersRef
      .where("owner", "==", owner)
      .where("branch", "==", branch)
      .where("orderId", "==", orderId)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          getReportOrdersSuccess(dispatch, data);
        }
        if (callback) {
          callback();
        }
      });
  };

const getReportOrdersSuccess = (dispatch, data) => {
  dispatch({
    type: "get_report_orders_success",
    payload: data,
  });
};

export const firestoreGetOrderListByClientByMonth =
  ({ branch, date, contact }, callback) =>
  (dispatch) => {
    const currentUser = firebase.auth().currentUser;
    const owner = currentUser?.uid;
    const ordersRef = firebase.firestore().collection("orders");
    const contactId = contact?.id;
    const fromDate = moment(date).startOf("month")._d;
    const toDate = moment(date).endOf("month")._d;
    ordersRef
      .where("owner", "==", owner)
      .where("branch", "==", branch)
      .where("contactId", "==", contactId)
      .where("scheduledFor", ">=", fromDate)
      .where("scheduledFor", "<=", toDate)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          const completed = data?.filter((item) => item.status === COMPLETED);
          getContactOrdersSuccess(dispatch, completed);
        }
        if (callback) {
          callback();
        }
      });
  };

const getContactOrdersSuccess = (dispatch, data) => {
  dispatch({
    type: "get_contact_orders_success",
    payload: data,
  });
};

export const firestoreUpdateOrder =
  ({ data }, callback) =>
  (dispatch) => {
    const ordersRef = firebase.firestore().collection("orders");
    const id = data.id;
    ordersRef
      .doc(id)
      .update(data)
      .then(() => {
        updateOrderSuccess(dispatch, data);
        if (callback) {
          callback();
        }
      });
  };

const updateOrderSuccess = (dispatch, data) => {
  dispatch({
    type: "update_order_success",
    payload: data,
  });
};

// -------------------------- Reports --------------------------

export const firestoreGetReport30Day =
  ({ branch }, callback) =>
  (dispatch) => {
    const currentUser = firebase.auth().currentUser;
    const owner = currentUser && currentUser.uid;
    const reportRef = firebase.firestore().collection("report");
    reportRef
      .where("owner", "==", owner)
      .where("branch", "==", branch)
      .where("type", "==", "30day")
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          if (data[0]) {
            getReport30DaySuccess(dispatch, data[0]);
          }
        }
        if (callback) {
          callback();
        }
      });
  };

const getReport30DaySuccess = (dispatch, data) => {
  dispatch({
    type: "get_report_30day_success",
    payload: data,
  });
};

// -------------------------- Formula --------------------------

export const firestoreAddFormula =
  ({ formula }, callback) =>
  (dispatch) => {
    const currentUser = firebase.auth().currentUser;
    const owner = currentUser && currentUser.uid;
    const formulaRef = firebase.firestore().collection("formula");
    const id = formulaRef.doc().id;
    const data = {
      // tạo group data
      ...formula,
      id,
      createAt: firebase.firestore.FieldValue.serverTimestamp(),
      owner,
    };
    formulaRef
      .doc(id)
      .set(data, { merge: true })
      .then(() => {
        if (callback) {
          callback(id);
        }
      });
  };

export const firestoreUpdateFormula =
  ({ formula }, callback) =>
  (dispatch) => {
    const formulaRef = firebase.firestore().collection("formula");
    const id = formula.id;
    formulaRef
      .doc(id)
      .update(formula)
      .then(() => {
        if (callback) {
          callback();
        }
      });
  };

export const firestoreGetFormula =
  ({ branch }, callback) =>
  (dispatch) => {
    const currentUser = firebase.auth().currentUser;
    const owner = currentUser && currentUser.uid;
    const formulaRef = firebase.firestore().collection("formula");
    formulaRef
      .where("owner", "==", owner)
      .where("branch", "==", branch)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          getFormulaSuccess(dispatch, data);
        }
        if (callback) {
          callback();
        }
      });
  };

const getFormulaSuccess = (dispatch, data) => {
  dispatch({
    type: "get_formula_success",
    payload: data,
  });
};

// -------------------------- Working Time --------------------------

export const firestoreGetCompletedOrderFomular =
  ({ branch }, callback) =>
  (dispatch) => {
    const currentUser = firebase.auth().currentUser;
    const owner = currentUser && currentUser.uid;
    const start_completed = moment().subtract(7, "days").startOf("day")._d;
    const end_completed = moment().subtract(1, "day").endOf("day")._d;
    firebase
      .firestore()
      .collection("orders")
      .where("owner", "==", owner)
      .where("branch", "==", branch)
      .where("scheduledFor", ">=", start_completed)
      .where("scheduledFor", "<=", end_completed)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          const data = snapshot.docs.map((doc) => doc.data());
          const filter = data && data.filter((item) => item.status === COMPLETED);
          getOrderCompleteListFomularSuccess(dispatch, filter);
          if (callback) {
            callback();
          }
        }
      });
  };

const getOrderCompleteListFomularSuccess = (dispatch, data) => {
  dispatch({
    type: "get_order_complete_list_fomular_success",
    payload: data,
  });
};
