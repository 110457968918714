import React, { Component} from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { 
    Grid,
    TextField,
    Paper,
    CardContent,
    Typography,
    colors,
    Button,
    InputAdornment,
} from '@material-ui/core';
import { LockOutlined } from '@material-ui/icons'
import { 
    firestoreAddFormula,
    firestoreUpdateFormula,
} from '../../../store/actions/firebase.action';
import { WAGECONTROL } from '../../../config/stringConfig';

class FormulaWageControl extends Component {

// ====================== Inits ====================== //

    constructor() {
        super();
        this.state = {
            loading: false,
            error: '',
            wage: 0,
        };
    }

    // Chuyển state từ props
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.branch !== prevState.branch) {
            const { formulas, branch } = nextProps;
            const fomular = formulas && formulas.find(item => item.type === WAGECONTROL);
            if (fomular) {
                return { 
                    wage: fomular.wage,
                    lock: true,
                    branch,
                    id: fomular.id,
                };
            }
        }
        return null;
    }
    
    // ====================== Functions ====================== //

    unlock = () => {
        this.setState({
            lock: false,
        })
    }

    handleChange = (e) => {
        const value = parseFloat(e.target.value);
        this.setState({
            [e.target.id]: value,
        })
    }


    // ====================== Firebase Functions ====================== //

    handleUpdateWage = () => {
        const { wage, id } = this.state;
        const { branch } = this.props;
        const formula = {
            id,
            type: WAGECONTROL,
            wage,
            branch,
        };
        this.setState({
            lock: true,
        }, () => {
            if (id) {
                this.props.firestoreUpdateFormula({ formula });
            } else {
                this.props.firestoreAddFormula({ formula }, (newId) => {
                    this.setState({
                        id: newId,
                    });
                });
            }
        })
    }
    
    // ====================== Render Component ====================== //



    renderFomularInput = () => {
        return (
            <Grid 
                container 
                spacing={3} 
                direction="row"
                justify="flex-start"
                alignItems="center"
            >
                <Grid item lg={2} md={3} sm={3} xs={12}>
                    {this.renderWage()}
                </Grid> 
            </Grid>
        )
    }


    renderWage = () => {
        const { wage, lock } = this.state;
        return (
            <TextField 
                id="wage"
                fullWidth
                margin='dense'
                variant="outlined"
                label='Wage'
                type="number"
                value={wage}
                onChange={this.handleChange}
                disabled={lock}
                InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
            />
        )
    }

    renderUpdateButton = () => {
        const { lock } = this.state;
        if (lock) {
            return (
                <Button 
                    variant="contained" 
                    color="default"
                    startIcon={<LockOutlined />}
                    onClick={() => this.unlock()}
                >
                    Edit
                </Button>
            )
        }
        return (
            <Button 
                variant="contained"
                style={{ backgroundColor: colors.green[500], color: '#fff' }}
                onClick={() => this.handleUpdateWage()}
            >
                Update
            </Button>
        )
    }


    // ====================== Render Main ====================== //

    render() {
        const { id } = this.state;
        const { branch } = this.props;
        if (branch) {
            return (
                <Paper elevation={3} >
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            Wage control { id ? '' : '- inactive'}
                        </Typography>
                        { this.renderFomularInput() }
                        <p>Formula: Wages per day = ((Hr-rate Staffs * pay rate) + (Fixed-rate Staffs * pay rate)) * 1.08</p>
                        <p>Notes: Fixed-rate staffs will work 8hrs per day from Monday to Friday</p>
                        { this.renderUpdateButton() }
                    </CardContent>
                </Paper>
            )
        }
        return null;
    }
}

const mapStateToProps = (state) => ({
    auth: state.firebase.auth,
    users: state.firebaseWeb.users,
    formulas: state.firebaseWeb.formulas,
});

const mapDispatchToProps = {
    firestoreAddFormula,
    firestoreUpdateFormula,   
}

export default compose(connect(mapStateToProps,mapDispatchToProps))(FormulaWageControl)
