import React, { Component} from 'react';
import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle,
    CircularProgress
} from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { CSVReader } from 'react-papaparse';
import string from 'lodash/string';
import { 
  firestoreImportContactByJson
} from '../../../store/actions/firebase.action';

class ContactDialogCsvUpload extends Component {

  // ====================== Inits ====================== //
    
    constructor() {
        super();
        // state
        this.state = {
            error: '',
            json: null,
            percent: '',
        };
    }

    resetData = () => {
      this.setState({
        error: '',
        json: null,
        percent: '',
      })
    }

    // ====================== Functions ====================== //

    transformHeader = (header) => {
      return string.camelCase(header);
    }

    handleOnDrop = (response) => {
      const json = response && response.map((item, index) => {
        return {
          ...item.data,
        };
      });
      this.setState({
          json
      });
    }

    handleOnError = (err, file, inputElem, reason) => {
      this.setState({
        error: err,
      })
    }

    handleOnRemoveFile = () => {
      this.setState({
        json: null
      })
    }

    handleConfirm = () => {
      const { json } = this.state;
      const { branch } = this.props;
      if (json) {
        this.setState({
          loading: true,
        }, () => {
          this.props.firestoreImportContactByJson({ json, branch }, (response) => {
            if (response === 'completed') {
              this.setState({
                json: null,
                loading: false,
                percent: '',
              },() => {
                this.props.handleConfirm();
              });
            } else {
              this.setState({
                percent: response,
              });
            }
          });
        })
      }
    }

    handleCancel = () => {
      this.setState({
        json: null,
        loading: false,
      }, () => {
        this.props.handleClose()
      })
    }

    // ====================== Firebase Functions ====================== //

    // ====================== Render Component ====================== //

    renderAddButton = () => {
      const { json, loading, percent } = this.state;
      const disabled = !json || (json && json.length < 1);
      if (loading) {
        return (
          <Button 
            type="submit"
            variant="contained"
            className='add-btn'
            startIcon={<CircularProgress size={15}/>}
            disabled
          >
            Importing { percent } ...
          </Button>
        );
      }
      return (
        <Button 
          type="submit"
          variant="contained"
          className='add-btn'
          style={{ backgroundColor: disabled ? '#999' : 'green', color: '#fff' }}
          disabled={disabled}
          onClick={() => this.handleConfirm()}
        >
          Import
        </Button>
      );
    }

    // ====================== Render Main ====================== //

    render() {
      const { error, loading } = this.state;
      const { dialogOpen, handleClose } = this.props;
      return (
        <Dialog
          open={dialogOpen}
          onClose={handleClose}
          onEntering={this.resetData}
          scroll='paper'
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          maxWidth='sm'
          fullWidth
        >
        <DialogTitle id="scroll-dialog-title">Select csv to upload contacts</DialogTitle>
        <DialogContent dividers className='container-csv-upload one-file'>
          <CSVReader
            onDrop={this.handleOnDrop}
            onError={this.handleOnError}
            onRemoveFile={this.handleOnRemoveFile}
            addRemoveButton
            removeButtonColor='#659cef'
            config={{
              skipEmptyLines: true,
              header: true,
              transformHeader: ((header) => this.transformHeader(header)),
            }}
          >
            <span>Drop CSV file here or click to upload.</span>
          </CSVReader>
        </DialogContent>
        <div className="red-text center">
            { error ? <p>{error}</p> : null }
        </div>
        <DialogActions>
          <Button 
            onClick={() => this.handleCancel()}
            disabled={loading}
          >
              Cancel
          </Button>
          {this.renderAddButton()}
        </DialogActions>
      </Dialog>
    );
  }
}

const mapDispatchToProps = {
  firestoreImportContactByJson
}
  
export default compose(connect(null,mapDispatchToProps))(ContactDialogCsvUpload)