import React, { Component} from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { 
    CssBaseline,
    Container, 
    Grid,
    MenuItem,
    FormControl,
    InputLabel,
    Select
} from '@material-ui/core';
import { 
    firestoreGetBranchList,
    firestoreGetUserList,
    firestoreGetFormula,
} from  '../../store/actions/firebase.action';
import Navbar from '../00_Nav/nav_bar';
import RightNav from '../00_Nav/right_nav';
import FormulaWorkTime from './fomulars/formula_work_time';
import FormulaWageControl from './fomulars/formula_wage_control';
import { Loading } from '../../component/loading';

class FormulaScene extends Component {

// ====================== Inits ====================== //

    constructor() {
        super();
        this.state = {
            loading: false,
            error: '',
            branch: null,
        };
    }

    componentDidMount = () => {
        this.props.firestoreGetBranchList(); // get Branch
        this.props.firestoreGetUserList(); // users
    }

    updateData = () => {
        const { branch } = this.state;
        this.setState({
            loading: true,
        }, () => {
            this.props.firestoreGetFormula({ branch }, () => {
                this.setState({
                    loading: false,
                });
            })
        });
    }
    
    // ====================== Functions ====================== //

    handleSelectBranch = (event) => {
        const branch = event.target.value;
        this.setState({
            branch,
        }, () => this.updateData());
    }

    // ====================== Firebase Functions ====================== //
    
    // ====================== Render Component ====================== //

    renderBranchDropDown = () => {
        const { branchs } = this.props;
        const { branch } = this.state;
        const menuItem = branchs && branchs.map((branch, index) => {
            return (
                <MenuItem key={index} value={branch.id}>{branch.tradingName}</MenuItem>
            );
        });
        return (
            <FormControl 
                variant="outlined"
                margin='dense'
                fullWidth
                className='table-select'
            >
                <InputLabel id="branchLabel">Branch</InputLabel>
                <Select
                    labelId="branchLabel"
                    id="branch"
                    value={branch || ''}
                    onChange={(event) => this.handleSelectBranch(event)}
                    label="Branch"
                >
                    { menuItem }
                </Select>
            </FormControl>
        );
    }


    renderTopBar = () => {
        return (
            <Grid 
                container 
                spacing={3} 
                direction="row"
                justify="flex-start"
                alignItems="center"
            >
                <Grid item lg={2} md={12} sm={12} xs={12}>
                    { this.renderBranchDropDown() }
                </Grid> 
            </Grid>
        );
    }

    renderFomulars = () => {
        const { branch, loading } = this.state;
        if (loading) {
            return <Loading loading={loading} />
        }
        return (
            <Grid 
                container 
                spacing={3} 
                direction="row"
                justify="flex-start"
                alignItems="center"
            >
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <FormulaWorkTime 
                        branch={branch}
                    />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <FormulaWageControl 
                        branch={branch}
                    />
                </Grid> 
            </Grid>
        )
    }

    // ====================== Render Main ====================== //

    render() {
        const { auth } = this.props;
        if (!auth.uid) return <Redirect to={'/signin'}/>
        return (
          <div className='root-container'>
            <CssBaseline />
            <Navbar title="Formula Management" right={<RightNav />}/>
            <Container maxWidth='xl' className='products-services-container'>
                { this.renderTopBar() }
                { this.renderFomulars() }
            </Container>
          </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.firebase.auth,
    branchs: state.firebaseWeb.branchs,
});

const mapDispatchToProps = {
    firestoreGetBranchList,
    firestoreGetUserList,
    firestoreGetFormula
}

export default compose(connect(mapStateToProps,mapDispatchToProps))(FormulaScene)
