export const ALL = "All";
export const ALL_WITH_EXEPTIONS = "All excepts cancelled orders";
export const SENT = "Sent";
export const PROCESSING = "Processing";
export const DELIVERY = "Delivery";
export const DELIVERY_SCHEDULED = "Delivery Scheduled";
export const IN_TRANSIT = "In Transit";
export const COMPLETED = "Completed";
export const CANCELLED = "Cancelled";
export const WAGECONTROL = "WageControl";
export const WORKTIME = "Worktime";
export const MODE_SELECT = "select";
export const MODE_EDIT = "edit";
export const MODE_ADD = "add";
export const MODE_NONE = "none";
export const CAMERA = "camera";
export const UPLOAD = "upload";
export const MACHINE_WASHER = "Washer";
export const MACHINE_DRYER = "Dryer";
export const MACHINE_FLATWORK_BOILER = "Flatwork & Boiler";
export const DELIVERY_DRIVER_LOG = "Driver Log";
export const DELIVERY_ANOTHER_LOG = "Another Log";
export const EVENT_LOGGED_IN = "Logged in";
export const EVENT_LOGGED_OUT = "Logged out";
export const EVENT_IN_TRANSIT = "In Transit";
export const EVENT_COMPLETED = "Completed";
export const EVENT_WENT_OFFLINE = "Went offline";
export const EVENT_BACK_ONLINE = "Back to online";
export const EVENTS = [
  EVENT_LOGGED_IN,
  EVENT_LOGGED_OUT,
  EVENT_IN_TRANSIT,
  EVENT_COMPLETED,
  EVENT_WENT_OFFLINE,
  EVENT_BACK_ONLINE,
];
