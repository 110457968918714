const initState = {
  loginError: "",
  loading: false,
  users: null,
  userGroups: null,
  branchs: null,
  timeTracking: null,
  orders: null,
  products: null,
  contacts: null,
  templates: null,
  contactTemplates: null,
  report30Day: null,
  formulas: null,
  completeList: null,
  contactOrders: null,
};
const firebaseReducer = (state = initState, action) => {
  switch (action.type) {
    case "firebase_loading":
      return {
        ...state,
        loading: true,
      };
    case "firebase_login_success":
      return {
        ...state,
        loginError: "",
        loading: false,
      };
    case "firebase_login_fail":
      return {
        ...state,
        loginError: action.payload,
        loading: false,
      };
    case "get_user_group_success":
      return {
        ...state,
        userGroups: action.payload,
      };
    case "get_user_list_success":
      return {
        ...state,
        users: action.payload,
      };
    case "get_branch_list_success":
      return {
        ...state,
        branchs: action.payload,
      };
    case "get_time_tracking_list_success":
      return {
        ...state,
        timeTracking: action.payload,
      };
    case "get_report_orders_success":
      return {
        ...state,
        orders: action.payload,
      };
    case "get_product_list_success":
      return {
        ...state,
        products: action.payload,
      };
    case "get_contact_list_success":
      return {
        ...state,
        contacts: action.payload,
      };
    case "get_template_list_success":
      return {
        ...state,
        templates: action.payload,
      };
    case "get_contact_template_list_success":
      return {
        ...state,
        contactTemplates: action.payload,
      };
    case "get_report_30day_success":
      return {
        ...state,
        report30Day: action.payload,
      };
    case "get_formula_success":
      return {
        ...state,
        formulas: action.payload,
      };
    case "get_order_complete_list_fomular_success":
      return {
        ...state,
        completeList: action.payload,
      };
    case "get_contact_orders_success":
      return {
        ...state,
        contactOrders: action.payload,
      };
    case "update_order_success":
      // eslint-disable-next-line no-case-declarations
      const orders = updateOrderFromSnap({ action, state });
      if (orders) {
        return {
          ...state,
          orders,
        };
      }
      return state;
    default:
      return state;
  }
};

export default firebaseReducer;

const updateOrderFromSnap = ({ action, state }) => {
  const orders = state.orders;
  if (orders) {
    const data = action.payload;
    return orders.map((item) => (item.orderId === data.orderId ? data : item));
  }
  return false;
};
