import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { 
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    TableSortLabel,
    TablePagination,
    IconButton
} from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import { Loading } from '../../component/loading';
import ProductDialogDelete from './dialog/product_dialog_delete';

class ProductsTable extends Component {

// ====================== Inits ====================== //

    constructor() {
        super();
        this.state = {
            edited: [],
            editError: [],
            header: [
                { id: 'itemCode', label: 'Item Code', align: 'left', sortable: true },
                { id: 'itemName', label: 'Item Name', align: 'left', sortable: true },
                { id: 'purchasesUnitPrice', label: 'Cost Price', align: 'right', sortable: true },
                { id: 'salesUnitPrice', label: 'Sale Price', align: 'right', sortable: true },
                { id: 'packaging', label: 'Packaging', align: 'right', sortable: true },
                { id: 'group', label: 'Group', align: 'right', sortable: true },
                { id: 'delete', label: 'Delete', align: 'center', sortable: false },
            ],
            selectedProduct: null,
            dialogDelete: false,
            rowsPerPage: 20,
            page: 0,
        };
    }

    // ====================== Functions ====================== //

    handleChangePage = (event, page) => {
        this.setState({
            page
        });
    };

    handleChangeRowsPerPage = (event) => {
        const rowsPerPage = parseInt(event.target.value, 10);
        this.setState({
            rowsPerPage,
            page: 0,
        });
    };
    
    isSelected = (data) => {
        const { selected } = this.props;
        const exist = selected && selected.find(item => item.itemCode === data.itemCode);
        return exist ? true : false;
    }

    isCheckedAll = () => {
        const { selected, reportData } = this.props;
        return selected && reportData && selected.length === reportData.length ? true : false;
    }

    // Dialog Delete
    openDialogDelete = (selectedProduct) => {
        this.setState({
            selectedProduct,
            dialogDelete: true,
        });
    }

    closeDialogDelete = () => {
        this.setState({
            selectedProduct: null,
            dialogDelete: false,
        });
    }

    successDialogDelete = () => {
        this.setState({
            selectedProduct: null,
            dialogDelete: false,
        }, () => this.props.handleUpdate());
    }

    // ====================== Data Functions ====================== //
     
    // ====================== Firebase Functions ====================== //

    // ====================== Render Component ====================== //

    renderTableHead = () => {
        const { reportData, orderBy, order } = this.props;
        const { header } = this.state;
        const checkAll = this.isCheckedAll();
        const disabled = !(reportData && reportData.length > 0);
        return (
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={checkAll}
                    disabled={disabled}
                    onChange={() => this.props.handleCheckAll()}
                    inputProps={{ 'aria-label': 'select all desserts' }}
                    color='primary'
                  />
                </TableCell>
                { header && header.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding='default'
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        { this.renderTableSortLabel(headCell, disabled)}
                    </TableCell>
                )) }
              </TableRow>
            </TableHead>
        );
    }

    renderTableSortLabel = (headCell, disabled) => {
        const { orderBy, order } = this.props;
        if (headCell.sortable) {
            return (
                <TableSortLabel
                    disabled={disabled}
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={() => this.props.handleSort(headCell.id)}
                >
                    {headCell.label}
                </TableSortLabel>
            );
        } 
        return headCell.label
    }

    renderTableBody = () => {
        const { reportData, loading } = this.props;
        const { rowsPerPage, page } = this.state;
        const length = reportData && reportData.length;
        let data = reportData;
        if (length > 1) {
            data = reportData && reportData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        }
        if (!loading) {
            return (
                <TableBody>
                    { data && data.map((row, index) => this.renderTableRow({ row, index })) }
                </TableBody>
            );
        }
        return <TableBody />;
    }

    // ====================== Render Table Default ====================== //

    renderTableRow = ({ row, index }) => {
        const isItemSelected = this.isSelected(row);
        const labelId = `table-checkbox-${index}`;
        return (
            <TableRow
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={index}
                selected={isItemSelected}
            >
                <TableCell align='left' padding='checkbox'>
                    <Checkbox
                        checked={isItemSelected}
                        color='primary'
                        inputProps={{ 'aria-labelledby': labelId }}
                        onChange={() => this.props.handleCheck(row)}
                    />
                </TableCell>
                <TableCell align='left'>{row.itemCode}</TableCell>
                <TableCell align='left'>{row.itemName}</TableCell>
                <TableCell align='right'>{row.purchasesUnitPrice}</TableCell>
                <TableCell align='right'>{row.salesUnitPrice}</TableCell>
                <TableCell align='right'>{row.packaging}</TableCell>
                <TableCell align='right'>{row.group}</TableCell>
                { this.renderDelete({ item: row }) }
            </TableRow>
        );
    }

    renderDelete = ({ item }) => {
        return (
            <TableCell align='center'>
                <IconButton 
                    aria-label="delete"
                    onClick={() => this.openDialogDelete(item)}
                >
                    <DeleteOutline style={{ color: 'red' }}/>
                 </IconButton>
            </TableCell>
        );
    }

    renderTableFooter = () => {
        const { reportData } = this.props;
        const { rowsPerPage, page } = this.state;
        const length = reportData && reportData.length;
        if (length > 1) {
            return (
                <TablePagination
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    component="div"
                    count={length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
            );
        }
        return null;
    }

    // ====================== Render Modal ====================== //

    // ====================== Render Main ====================== //

    render() {
        const { dialogDelete, selectedProduct } = this.state;
        const { loading } = this.props;
        return (
            <Paper style={{ width: '100%', marginTop: 10, marginBottom: 30 }}>
                <TableContainer className='report-table'>
                    <Table stickyHeader aria-label="sticky table">
                        { this.renderTableHead() }
                        { this.renderTableBody() }
                    </Table>
                    <Loading loading={loading} />
                </TableContainer>
                { this.renderTableFooter() }
                <ProductDialogDelete 
                    dialogOpen={dialogDelete}
                    handleClose={this.closeDialogDelete}
                    handleSuccess={this.successDialogDelete}
                    product={selectedProduct}
                />
            </Paper>
        ); 
    }
}


export default compose(connect(null,null))(ProductsTable)