import React, { Component} from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import firebase from 'firebase';
import { 
    Grid,
    IconButton,
    TextField,
    CircularProgress
} from '@material-ui/core';
import { 
    CheckBoxOutlined,
    EditOutlined,
    DeleteOutlined,
    CancelOutlined
} from '@material-ui/icons';
import { 
    firestoreAddUserGroup, 
    firestoreUpdateUserGroupName,
    firestoreGetUserGroup,
} from '../../store/actions/firebase.action';
import UserGroupDialogDelete from './dialog/user_group_dialog_delete'

class UserGroupList extends Component {

// ====================== Inits ====================== //

    constructor(props) {
        super(props);
        this.state = {
            adding: false,
            name: '',
            error: '',
            loading: false,
            indexEditting: null,
            deleteGroupDialog: false, 
            selectedGroup: null,
        };
    }

    resetData = () => {
        this.setState({ // Xử lý thành công
            adding: false,
            error: '',
            name: '',
            loading: false,
            indexEditting: null,
        });
    }

    // ====================== Functions ====================== //

    handleChange = (e) => {
        if (e.target.id === 'name') { 
            this.handleValidateGroupName(e.target.value) 
        }
    }

    handleValidateGroupName = (value) => {
        if (value.length > 0) {
            const regexp = /^[a-zA-Z0-9]{2,32}$/; // Không chọn chữ, gồm 2-32 kí tự
            const valid = regexp.test(value);
            if (valid) { 
                this.setState({ // done
                    error: '',
                    adding: true,
                    name: value,
                });
                
            } else {// lỗi
                this.setState({
                    adding: false,
                    error: 'Must contain 2-32 characters, alphabets only.',
                    name: value,
                });
            }
        } else { // empty
            this.resetData();
        }
    }

    handleEditUserGroup = (item, index) => {
        this.setState({
            indexEditting: index,
            name: item.name
        });
    }

    handleCancelEditGroup = () => {
        this.resetData()
    }

    handleUpdateName = (e) => {
        this.handleValidateGroupName(e.target.value)
    }

    openDeleteUserGroupDialog = (item) => {
        this.setState({
            deleteGroupDialog: true, 
            selectedGroup: item,
        })
    }

    handleCloseUserGroupDeleteDialog = () => {
        this.setState({
            deleteGroupDialog: false, 
            selectedGroup: null,
        })
    }

    // ====================== Firebase Functions ====================== //

    checkDubplicateUserGroup = () => {
        this.setState({
            loading: true,
        }, () => {
            const { name } = this.state;
            const owner = firebase.auth().currentUser.uid;
            const userGroup = firebase.firestore().collection('userGroup');
            userGroup.where('name', '==', name).where('owner', '==', owner)
            .get()
            .then(snapshot => {
                if (snapshot.empty) { // Nếu không trùng
                    this.props.firestoreAddUserGroup(name, () => {
                        this.resetData();
                        this.props.firestoreGetUserGroup(); // update group list
                    });
                } else {
                    this.setState({ // Xử lý lỗi
                        loading: false,
                        error: 'Dubpicate Group Name',
                    });
                }
            });
        });
    }

    updateUserGroup = (data) => {
        this.setState({
            loading: true,
        }, () => {
            const { name } = this.state;
            const owner = firebase.auth().currentUser.uid;
            const userGroup = firebase.firestore().collection('userGroup');
            userGroup.where('name', '==', name).where('owner', '==', owner)
            .get()
            .then(snapshot => {
                if (snapshot.empty) { // Nếu không trùng thì update
                    this.props.firestoreUpdateUserGroupName(data, name, () => {
                        this.resetData();
                        this.props.firestoreGetUserGroup(); // lấy danh sách mới
                    });
                } else {
                    this.setState({ // Xử lý lỗi
                        loading: false,
                        error: 'Dubpicate Group Name',
                    });
                }
            });
        });
    }


    // ====================== Render Component ====================== //


    renderSectionGroups = () => {
        const { userGroups } = this.props;
        if (userGroups) {
            return (
                userGroups && userGroups.map((item, index) => this.renderItem(item, index))
            );
        }
       return null;
    }

    renderItem = (item, index) => {
        const { indexEditting, name, error } = this.state;
        if (item) {
            return (
                <Grid key={index} item xs={12} style={{ flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                    <TextField
                        variant="outlined"
                        margin='dense'
                        required
                        fullWidth
                        id={item.name}
                        placeholder={item.name}
                        name={item.name}
                        type="text"
                        autoFocus
                        value={indexEditting === index ? name : item.name}
                        disabled={indexEditting === index ? false : true}
                        error={indexEditting === index ? !!error : false}
                        helperText={indexEditting === index ? error : ''}
                        onChange={this.handleUpdateName}
                    />
                {this.renderItemButtons(item, index)}
                </Grid>
            );
        }
        return null;
    }

    renderItemButtons = (item, index) => {
        const { indexEditting, loading } = this.state;
        if (indexEditting === index) {
            if (loading) {
                return (
                    <div className='group-button-container'>
                    <IconButton 
                        aria-label="update"
                        size='small'
                    >
                        <CircularProgress size={30}/>
                    </IconButton>
                </div>
                );
            }
            return (
                <div className='group-button-container'>
                    <IconButton 
                        aria-label="update"
                        size='small'
                        onClick={() => this.updateUserGroup(item)}
                    >
                        <CheckBoxOutlined style={{ color: 'green', fontSize: 30 }}/>
                    </IconButton>
                    <IconButton 
                        aria-label="delete"
                        size='small'
                        onClick={() => this.handleCancelEditGroup()}
                    >
                        <CancelOutlined style={{ color: '#999', fontSize: 30 }}/>
                    </IconButton>
                </div>
            );
        }
        return (
            <div className='group-button-container'>
                <IconButton 
                    aria-label="edit"
                    size='small'
                    onClick={() => this.handleEditUserGroup(item, index)}
                >
                    <EditOutlined style={{ color: '#2b78e4', fontSize: 30 }}/>
                </IconButton>
                <IconButton 
                    aria-label="delete"
                    size='small'
                    onClick={() => this.openDeleteUserGroupDialog(item)}
                >
                    <DeleteOutlined style={{ color: 'red', fontSize: 30 }}/>
                </IconButton>
            </div>
        );
    }


    // ============ Section Add ============ 
    renderSectionAdd = () => {
        const { error, name, indexEditting } = this.state;
        return (
            <Grid item xs={12} style={{ flexDirection: 'row', display: 'flex', marginBottom: 10, justifyContent: 'flex-end' }}>
                <TextField
                    variant="outlined"
                    margin='dense'
                    required
                    fullWidth
                    id="name"
                    label="Group name ..."
                    placeholder='Group name...'
                    name="name"
                    type="text"
                    autoFocus
                    disabled={indexEditting !== null}
                    value={indexEditting !== null ? '' : name}
                    error={indexEditting !== null ? false : !!error}
                    helperText={indexEditting !== null ? false : error}
                    onChange={this.handleChange}
                />
                <div className='group-button-container'>
                    {this.renderCheckButton()}
                </div>
            </Grid>
        );
    }

    renderCheckButton = () => {
        const { loading, adding, indexEditting } = this.state;
        if (loading && !indexEditting) {
            return (
                <IconButton 
                    aria-label="loading"
                    disabled={true}
                    size='small'
                >
                    <CircularProgress size={30}/>
                </IconButton>
            );
        }
        return (
            <IconButton 
                aria-label="check"
                disabled={indexEditting ? true : !adding}
                size='small'
                onClick={() => this.checkDubplicateUserGroup()}
            >
                <CheckBoxOutlined style={{ color: adding && !indexEditting ? 'green' : '#c8c8c8', fontSize: 30 }}/>
            </IconButton>
        );
    }

    // ====================== Render Modal ====================== //

    // ====================== Render Main ====================== //


render() {
    const { deleteGroupDialog, selectedGroup } = this.state;
    return (
        <Grid container spacing={2} style={{ alignItems: 'flex-end' }}>
            {this.renderSectionGroups()}
            {this.renderSectionAdd()}
            <UserGroupDialogDelete 
                dialogOpen={deleteGroupDialog}
                handleClose={this.handleCloseUserGroupDeleteDialog}
                groupData={selectedGroup}
            />
        </Grid>
    )
  }
}

const mapStateToProps = (state) => ({
    userGroups: state.firebaseWeb.userGroups,
});

const mapDispatchToProps = {
    firestoreAddUserGroup,
    firestoreUpdateUserGroupName,
    firestoreGetUserGroup
}

export default compose(connect(mapStateToProps,mapDispatchToProps))(UserGroupList)

