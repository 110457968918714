import FileSaver from 'file-saver';
import moment from 'moment';

export const exportToCSV = ({reportData, branch, branchs, from, to}) => {
    // =========================  Provider =========================
    const selectedBranch = branchs.find(b => b.id === branch);
    const branchName = selectedBranch && selectedBranch.tradingName;
    const header = reportData && reportData.header;
    const body = reportData && reportData.body;
    const length = header.length - 1; // Bỏ breaking
    const time = moment().format('DD/MM/YYYY HH:mm:ss');
    const fromString = moment(from).format('DD/MM/YYYY');
    const toString = moment(to).format('DD/MM/YYYY');
    const total = reportData && reportData.workingSec && secondsToHoursFixed({ seconds: reportData.workingSec });
    // =========================  Row Data =========================
    const rowBranch = rowLeftData({ data: branchName, length });
    const rowEmpty = rowEmptyData({ length });
    const rowTitle = rowLeftData({ data: 'Timesheet Details', length });
    const rowCreateOn = rowLeftData({ data: `Created on:${time}`, length });
    const rowCreateBy = rowLeftData({ data: 'Created by:Admin', length });
    const rowPeriod = rowLeftData({ data: `Period: ${fromString} - ${toString}`, length });
    const rowTotal = rowTotalData({ data: total, length });
    const rowTableHeader = rowHeaderData({ data: header});
    const rowTableBody = rowBodyData({ data: body, length});
    // =========================  Section =========================
    const sectionTop = `${rowBranch}${rowEmpty}${rowEmpty}${rowTitle}${rowCreateOn}${rowCreateBy}${rowPeriod}${rowEmpty}${rowTotal}${rowEmpty}`;
    const sectionTable = `${rowTableHeader}${rowTableBody}`;
    // =========================  CSV =========================
    const csvBuffer = `${sectionTop}${sectionTable}`;
    const fromName = moment(from).format('DD');
    const toName = moment(to).format('DDMMYY');
    const currentTime = moment().format('HH:mm');
    const fileName = `${branchName} - Timesheet Details - ${fromName}-${toName}-${currentTime}`;
    const fileType = 'text/csv;charset=utf-8;';
    const fileExtension = '.csv';
    const data = new Blob([csvBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
}

const rowLeftData = ({ data, length }) => {
    let rowString = data;
    for (let i = 0; i < length - 1; i++) {
        rowString = `${rowString},`;
    }
    rowString = `${rowString}\n`;
    return rowString;
}

const rowRightData = ({ data, length }) => {
    let rowString = '';
    for (let i = 0; i < length - 2; i++) {
        rowString = `${rowString},`;
    }
    rowString = `${rowString},${data},\n`;
    return rowString;
}

const rowTotalData = ({ data, length }) => {
    let rowString = '';
    for (let i = 0; i < length - 3; i++) {
        rowString = `${rowString},`;
    }
    rowString = `${rowString},Total,${data},\n`;
    return rowString;
}

const rowEmptyData = ({ length }) => {
    let rowString = '';
    for (let i = 0; i < length - 1; i++) {
        rowString = `${rowString},`;
    }
    rowString = `${rowString}\n`;
    return rowString;
}

const rowHeaderData = ({ data }) => {
    const filter = data && data.filter(d => d.csvLabel !== 'Breaking Time'); // bỏ breacking time
    let rowString = filter && filter.map(d => d.csvLabel);
    rowString = `${rowString},Notes\n`;
    return rowString;
}

const rowBodyData = ({ data, length }) => {
    let bodyString = '';
    data && data.forEach(d => {
        let rowString = '';
        if (d.type === 'row') {
            const username = d.username;
            const fullName = d.fullName;
            const groupName = d.groupName;
            const date = d.stringDate;
            const day = d.stringDay;
            const working = secondsToHoursFixed({ seconds: d.workingSec });
            let inOut = '';
            for (let i = 0; i < d.data.length; i++) {
                const time = (d.data[i] && d.data[i].stringTime) || '';
                inOut = `${inOut},${time}`;
            }
            rowString = `${username},${fullName},${groupName},${date},${day}${inOut},${working},\n`;
        } else if (d.type === 'total'){
            const working = secondsToHoursFixed({ seconds: d.workingSec });
            const rowTotal = rowRightData({ data: working, length });
            const rowEmpty = rowEmptyData({ length });
            rowString = `${rowTotal}${rowEmpty}`;
        };
        bodyString = `${bodyString}${rowString}`;
    });
    return bodyString;
}

// Chuyến giây sang giờ phút
const secondsToHoursFixed = ({ seconds }) => {
    if (seconds && seconds >= 0) {
        const hours = (seconds / 3600).toFixed(2);
        return hours;
    }
    return 0.00;
}