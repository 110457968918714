import React, { Component} from 'react';
import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle,
    CircularProgress
} from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { 
    firestoreDeleteProduct
} from  '../../../store/actions/firebase.action';

class ProductDialogDelete extends Component {

  // ====================== Inits ====================== //
    
    constructor() {
        super();
        // state
        this.state = {
            error: '',
            loading: false,
        };
    }

    // ====================== Functions ====================== //

    // ====================== Firebase Functions ====================== //

    handleDeleteProduct = () => {
        const { product, products } = this.props;
        this.setState({
            loading: true,
        }, () => {
            this.props.firestoreDeleteProduct({ product, products }, () => {
                this.setState({
                    loading: false,
                }, () => {
                    this.props.handleSuccess();
                })
            });
        })
    }

    // ====================== Render Component ====================== //


    renderDeleteButton = () =>{
        const { loading } = this.state;
        if (loading) {
          return (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className='add-btn'
              disabled
              startIcon={<CircularProgress size={15}/>}
            >
              Delete
            </Button>
          );
        }
        return (
          <Button 
            onClick={() => this.handleDeleteProduct()} 
            type="submit"
            variant="contained"
            className='add-btn'
            style={{ backgroundColor: 'red', color: '#fff' }}
          >
            Delete
          </Button>
        );
    }

    
    // ====================== Render Main ====================== //

    render() {
        const { error, loading } = this.state;
        const { dialogOpen, handleClose, product } = this.props;
        const itemName = product && product.itemName;
        const itemCode = product && product.itemCode;
        const fullName = `${itemCode} - ${itemName}`
        return (
            <Dialog
                open={dialogOpen}
                onClose={handleClose}
                scroll='paper'
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                maxWidth='sm'
                fullWidth
            >
            <DialogTitle id="scroll-dialog-title">Remove Product {fullName}</DialogTitle>
            <DialogContent dividers >
              <p>Are you sure to delete "{fullName}". This action can't be undone.</p>
              <div className="red-text center">
                  { error ? <p>{error}</p> : null }
              </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} disabled={loading}>
                    Cancel
                </Button>
                {this.renderDeleteButton()}
            </DialogActions>
        </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
    products: state.firebaseWeb.products,
});
    

const mapDispatchToProps = {
    firestoreDeleteProduct
}
  
export default compose(connect(mapStateToProps,mapDispatchToProps))(ProductDialogDelete)