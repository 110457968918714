import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { CssBaseline, Container, Button } from "@material-ui/core";
import Navbar from "../00_Nav/nav_bar";
import RightNav from "../00_Nav/right_nav";
import orderSVG from "../../asset/svg/order_blue.svg";
import truckSVG from "../../asset/svg/truck_blue.svg";
import settingsSVG from "../../asset/svg/settings_blue.svg";
import timeSVG from "../../asset/svg/time_check_blue.svg";
import repairSVG from "../../asset/svg/repair_blue.svg";
import dashboardSVG from "../../asset/svg/dashboard_blue.svg";
import calendarClientSVG from "../../asset/svg/calendar_client_blue.svg";
import linenClientSVG from "../../asset/svg/linen_blue.svg";
import { URL } from "../../config/config";

class ReportManagement extends Component {
  // ====================== Inits ====================== //

  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {}

  // ====================== Functions ====================== //

  // ====================== Firebase Functions ====================== //

  // ====================== Render Component ====================== //

  // ====================== Render Modal ====================== //

  // ====================== Render Main ====================== //

  render() {
    const { auth } = this.props;
    if (!auth.uid) return <Redirect to={"/signin"} />;
    return (
      <div className="root-container">
        <CssBaseline />
        <Navbar title="Reports" right={<RightNav />} />
        <Container maxWidth="xl">
          <Button
            variant="outlined"
            color="primary"
            size="large"
            className="square-button"
            startIcon={<img alt="Dashboard" src={dashboardSVG} />}
            href={URL.reporDashboard}
          >
            Dashboard
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            className="square-button"
            startIcon={<img alt="Time Tracking" src={timeSVG} />}
            href={URL.reportTimeTracking}
          >
            Time Tracking
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            className="square-button"
            startIcon={<img alt="Operations" src={settingsSVG} />}
          >
            Operations
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            className="square-button"
            startIcon={<img alt="Maintenance" src={repairSVG} />}
          >
            Maintenance
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            className="square-button"
            startIcon={<img alt="Delivery" src={truckSVG} />}
            href={URL.reportDelivery}
          >
            Delivery
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            className="square-button"
            startIcon={<img alt="Orders" src={orderSVG} />}
            href={URL.reportOrders}
          >
            Orders
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            className="square-button"
            startIcon={<img alt="Monthly Sales" src={calendarClientSVG} />}
            href={URL.reportMonthlySale}
          >
            Monthly Sales
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            className="square-button"
            startIcon={<img alt="Topup Linen" src={linenClientSVG} />}
            href={URL.reportMonthlyTopup}
          >
            Topup Linen
          </Button>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ReportManagement);
