import React, { Component} from 'react';
import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle,
    CircularProgress,
} from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { 
    firestoreGetTemplateListByContact,
    firestoreDeleteTemplateItem
} from  '../../../store/actions/firebase.action';

class TemplateDetailDialogDelete extends Component {

  // ====================== Inits ====================== //
    
    constructor(props) {
        super();
        // state
        this.state = {
            error: '',
            loading: false,
        };
    }

    // ====================== Functions ====================== //

    handleClose = () => {
        this.setState({
            error: '',
            loading: false,
        }, () => this.props.handleClose());
    }

    // ====================== Firebase Functions ====================== //

    firebaseDeleteTemplateItem = () => {
      const { templateDelete, selectedEditContact, branch } = this.props;
      if (templateDelete) {
        this.setState({
            loading: true,
        }, () => {
            const contact = selectedEditContact;
            this.props.firestoreDeleteTemplateItem({ templateDelete }, () => {
                this.props.firestoreGetTemplateListByContact({ contact, branch }, () => {
                    this.handleClose();
                })
            })
        });
      }
    }

    // ====================== Render Component ====================== //

    renderDeleteButton = () =>{
        const { loading } = this.state;
        if (loading) {
          return (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className='add-btn'
              disabled
              startIcon={<CircularProgress size={15}/>}
            >
              Delete
            </Button>
          );
        }
        return (
          <Button 
            onClick={() => this.firebaseDeleteTemplateItem()} 
            type="submit"
            variant="contained"
            className='add-btn'
            style={{ backgroundColor: 'red', color: '#fff' }}
          >
            Delete
          </Button>
        );
    }

    
    // ====================== Render Main ====================== //

    render() {
        const { error, loading } = this.state;
        const { dialogOpen, handleClose, templateDelete } = this.props;
        const inventoryItemCode = templateDelete && templateDelete.inventoryItemCode;
        const contactName = templateDelete && templateDelete.contactName;
        return (
            <Dialog
                open={dialogOpen}
                onClose={handleClose}
                scroll='paper'
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                maxWidth='sm'
                fullWidth
            >
            <DialogTitle id="scroll-dialog-title">Delete item {inventoryItemCode } </DialogTitle>
            <DialogContent dividers >
              <p>Are you sure to delete "{ inventoryItemCode }" from "{ contactName }" order template. This action can't be undone.</p>
              <div className="red-text center">
                  { error ? <p>{error}</p> : null }
              </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} disabled={loading}>
                    Cancel
                </Button>
                {this.renderDeleteButton()}
            </DialogActions>
        </Dialog>
    );
  }
}

const mapDispatchToProps = {
    firestoreGetTemplateListByContact,
    firestoreDeleteTemplateItem
}
  
export default compose(connect(null,mapDispatchToProps))(TemplateDetailDialogDelete)
  