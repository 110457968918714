import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Grid, TextField, IconButton, CircularProgress } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { CheckBoxOutlined, CancelOutlined, EditOutlined, DeleteOutlined } from "@material-ui/icons";
import {
  firestoreGetTemplateListByContact,
  firestoreUpdateTemplateItemToContact,
} from "../../../store/actions/firebase.action";

class TemplateDetailUpdate extends Component {
  // ====================== Inits ====================== //

  constructor() {
    super();
    this.state = {
      loading: false,
      quantity: "1",
      unitAmount: "",
      selectedProduct: null,
    };
  }

  // ====================== Functions ====================== //

  selectedProduct = (selectedProduct) => {
    const salesUnitPrice = selectedProduct.salesUnitPrice;
    this.setState({
      selectedProduct,
      unitAmount: salesUnitPrice,
    });
  };

  handleChangeQuantity = (event) => {
    const value = event.target.value;
    const regex = /^[0-9]{0,3}$/;
    const valid = regex.test(value);
    if (!valid) {
      return;
    }
    this.setState({
      quantity: value,
    });
  };

  handleChangeUnitAmount = (event) => {
    const value = event.target.value;
    const regex = /^[0-9.]{0,10}$/;
    const valid = regex.test(value);
    if (!valid) {
      return;
    }
    this.setState({
      unitAmount: value,
    });
  };

  handleEdit = () => {
    const { products, editTemplate } = this.props;
    const inventoryItemCode = editTemplate && editTemplate.inventoryItemCode;
    const selectedProduct =
      products && inventoryItemCode && products.find((p) => p.itemCode === inventoryItemCode);
    const quantity = editTemplate && editTemplate.quantity;
    const unitAmount = editTemplate && editTemplate.unitAmount;
    this.setState(
      {
        selectedProduct,
        quantity,
        unitAmount,
      },
      () => {
        this.props.handleEdit();
      }
    );
  };

  handleDelete = () => {
    this.props.handleDelete();
  };

  handleCancel = () => {
    this.setState(
      {
        loading: false,
        quantity: "1",
        selectedProduct: null,
      },
      () => {
        this.props.handleCancel();
      }
    );
  };

  // ====================== Firebase Functions ====================== //

  handleUpdate = () => {
    const { selectedEditContact, editTemplate, branch } = this.props;
    const { selectedProduct, quantity, unitAmount } = this.state;
    this.setState(
      {
        loading: true,
      },
      () => {
        const contact = selectedEditContact;
        const product = selectedProduct;
        this.props.firestoreUpdateTemplateItemToContact(
          { editTemplate, product, quantity, unitAmount },
          () => {
            this.props.firestoreGetTemplateListByContact({ contact, branch }, () => {
              this.handleCancel();
            });
          }
        );
      }
    );
  };

  // ====================== Render Component ====================== //

  renderEditAutocomplete = () => {
    const { products, contactTemplates, isEditting, editTemplate } = this.props;
    const { selectedProduct } = this.state;
    const disabled = !products;
    if (isEditting) {
      return (
        <Autocomplete
          onChange={(e, value) => this.selectedProduct(value)}
          value={selectedProduct}
          getOptionSelected={(option) => option}
          getOptionDisabled={(option) => {
            const itemCode = option.itemCode;
            const exist =
              contactTemplates && contactTemplates.find((t) => t.inventoryItemCode === itemCode);
            return exist ? true : false;
          }}
          getOptionLabel={(option) => {
            const itemCode = option.itemCode;
            const itemName = option.itemName;
            return `${itemCode} - ${itemName}`;
          }}
          options={products || []}
          disabled={disabled}
          renderInput={(params) => (
            <TextField {...params} margin="dense" label="Code" variant="outlined" />
          )}
        />
      );
    }
    const inventoryItemCode = editTemplate && editTemplate.inventoryItemCode;
    return (
      <TextField
        variant="outlined"
        margin="dense"
        required
        fullWidth
        type="text"
        autoFocus
        value={inventoryItemCode}
        disabled
      />
    );
  };

  renderItemName = () => {
    const { isEditting, editTemplate, products } = this.props;
    const { selectedProduct } = this.state;
    let itemName = "";
    if (isEditting) {
      itemName = selectedProduct && selectedProduct.itemName;
      return (
        <TextField
          variant="outlined"
          margin="dense"
          required
          fullWidth
          type="text"
          autoFocus
          disabled
          value={itemName}
          className="template-item-description"
        />
      );
    }
    const inventoryItemCode = editTemplate && editTemplate.inventoryItemCode;
    const product = products && products.find((p) => p.itemCode === inventoryItemCode);
    itemName = product && product.itemName;
    return (
      <TextField
        variant="outlined"
        margin="dense"
        required
        fullWidth
        type="text"
        autoFocus
        disabled
        value={itemName}
        className="template-item-description"
      />
    );
  };

  renderQuantity = () => {
    const { isEditting, editTemplate } = this.props;
    const { quantity } = this.state;
    if (isEditting) {
      return (
        <TextField
          variant="outlined"
          margin="dense"
          required
          fullWidth
          type="text"
          autoFocus
          value={quantity}
          onChange={this.handleChangeQuantity}
          className="template-item-quantity"
        />
      );
    }
    const itemQuantity = editTemplate && editTemplate.quantity;
    return (
      <TextField
        variant="outlined"
        margin="dense"
        required
        fullWidth
        type="text"
        autoFocus
        disabled
        value={itemQuantity}
        className="template-item-quantity"
      />
    );
  };

  renderPrice = () => {
    const { isEditting, editTemplate } = this.props;
    const { unitAmount } = this.state;
    if (isEditting) {
      return (
        <TextField
          variant="outlined"
          margin="dense"
          required
          fullWidth
          type="text"
          autoFocus
          value={unitAmount}
          onChange={this.handleChangeUnitAmount}
          className="template-item-price"
        />
      );
    }
    const itemUnitAmount = editTemplate && editTemplate.unitAmount;
    return (
      <TextField
        variant="outlined"
        margin="dense"
        required
        fullWidth
        type="text"
        autoFocus
        disabled
        value={itemUnitAmount}
        className="template-item-price"
      />
    );
  };

  renderUpdateButtonBar = () => {
    const { loading, selectedProduct, quantity } = this.state;
    const { isEditting } = this.props;
    const disabled = !selectedProduct || !quantity || quantity === "0" || loading;
    if (isEditting) {
      if (loading) {
        return (
          <div className="group-button-container">
            <IconButton aria-label="update" size="small">
              <CircularProgress size={30} />
            </IconButton>
          </div>
        );
      }
      return (
        <div className="group-button-container">
          <IconButton
            aria-label="update"
            size="small"
            disabled={disabled}
            onClick={() => this.handleUpdate()}
          >
            <CheckBoxOutlined style={{ color: disabled ? "#c8c8c8" : "green", fontSize: 30 }} />
          </IconButton>
          <IconButton aria-label="delete" size="small" onClick={() => this.handleCancel()}>
            <CancelOutlined style={{ color: "#666", fontSize: 30 }} />
          </IconButton>
        </div>
      );
    }
    return null;
  };

  renderEditButtonBar = () => {
    const { isEditting } = this.props;
    if (!isEditting) {
      return (
        <div className="group-button-container">
          <IconButton aria-label="edit" size="small" onClick={() => this.handleEdit()}>
            <EditOutlined style={{ color: "#2b78e4", fontSize: 30 }} />
          </IconButton>
          <IconButton aria-label="delete" size="small" onClick={() => this.handleDelete()}>
            <DeleteOutlined style={{ color: "red", fontSize: 30 }} />
          </IconButton>
        </div>
      );
    }
    return null;
  };

  // ====================== Render Main ====================== //

  render() {
    const { selectedEditContact, contactTemplates, loading } = this.props;
    if (selectedEditContact && contactTemplates && !loading) {
      return (
        <Grid
          container
          spacing={1}
          direction="row"
          justify="flex-start"
          alignItems="center"
          className="template-list"
        >
          <Grid item lg={3} md={12} sm={12} xs={12}>
            {this.renderEditAutocomplete()}
          </Grid>
          <Grid item lg={4} md={12} sm={12} xs={12}>
            {this.renderItemName()}
          </Grid>
          <Grid item lg={1} md={12} sm={12} xs={12}>
            {this.renderQuantity()}
          </Grid>
          <Grid item lg={2} md={12} sm={12} xs={12}>
            {this.renderPrice()}
          </Grid>
          <Grid item lg={2} md={12} sm={12} xs={12}>
            {this.renderEditButtonBar()}
            {this.renderUpdateButtonBar()}
          </Grid>
        </Grid>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => ({
  products: state.firebaseWeb.products,
  contactTemplates: state.firebaseWeb.contactTemplates,
});

const mapDispatchToProps = {
  firestoreGetTemplateListByContact,
  firestoreUpdateTemplateItemToContact,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(TemplateDetailUpdate);
