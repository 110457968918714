import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import {
  firestoreGetTemplateListByContact,
  firestoreAddTemplateItemToContact,
} from "../../store/actions/firebase.action";
import { Loading } from "../../component/loading";
import TemplateDetailAdd from "./component/template_detail_add";
import TemplateDetailUpdate from "./component/template_detail_update";
import TemplateDetailDialogDelete from "./dialog/template_detail_dialog_delete";

class TemplateDetail extends Component {
  // ====================== Inits ====================== //

  constructor() {
    super();
    this.state = {
      edit: false,
      editIndex: 0,
      loading: false,
      quantity: "1",
      selectedProduct: null,
      templateDelete: null,
      dialogDelete: false,
    };
  }

  // ====================== Functions ====================== //

  selectedProduct = (selectedProduct) => {
    this.setState({
      selectedProduct,
    });
  };

  handleChangeQuantity = (event) => {
    const value = event.target.value;
    const regex = /^[0-9]{0,3}$/;
    const valid = regex.test(value);
    if (!valid) {
      return;
    }
    this.setState({
      quantity: value,
    });
  };

  handleEditTemplateItem = ({ index, item }) => {
    const { products } = this.props;
    const selectedProduct = products && products.find((p) => p.itemCode === item.inventoryItemCode);
    const quantity = item.quantity;
    this.setState({
      edit: true,
      editIndex: index,
      selectedProduct,
      quantity,
    });
  };

  handleCancelEditTemplateItem = () => {
    this.setState({
      edit: false,
      templateEdit: null,
    });
  };

  handleEdit = ({ index }) => {
    this.setState({
      edit: true,
      editIndex: index,
    });
  };

  handleCancel = () => {
    this.setState({
      edit: false,
    });
  };

  openTemplateDeleteDialog = ({ templateDelete }) => {
    this.setState({
      dialogDelete: true,
      templateDelete,
    });
  };

  closeTemplateDeleteDialog = () => {
    this.setState({
      dialogDelete: false,
      templateDelete: null,
    });
  };

  // ====================== Firebase Functions ====================== //

  // ====================== Render Component ====================== //

  renderSectionTemplates = () => {
    const { selectedEditContact, contactTemplates, loading } = this.props;
    if (selectedEditContact && contactTemplates && !loading) {
      return (
        contactTemplates && contactTemplates.map((item, index) => this.renderItem({ item, index }))
      );
    }
    return null;
  };

  renderItem = ({ item, index }) => {
    const { edit, editIndex } = this.state;
    const { selectedEditContact, loading, branch } = this.props;
    const isEditting = edit && editIndex === index ? true : false;
    return (
      <TemplateDetailUpdate
        key={index}
        isEditting={isEditting}
        editTemplate={item}
        loading={loading}
        handleEdit={() => this.handleEdit({ index })}
        handleCancel={() => this.handleCancel()}
        handleDelete={() => this.openTemplateDeleteDialog({ templateDelete: item })}
        selectedEditContact={selectedEditContact}
        branch={branch}
      />
    );
  };

  renderTopBarDetail = () => {
    const { selectedEditContact, contactTemplates } = this.props;
    if (selectedEditContact) {
      const contactName = selectedEditContact && selectedEditContact.contactName;
      const length = contactTemplates && contactTemplates.length;
      return (
        <Grid container spacing={1} direction="row" justify="flex-start" alignItems="center">
          <Grid item lg={8} md={12} sm={12} xs={12}>
            <h3 className="regular-title" style={{ marginBottom: 0 }}>
              {contactName}
            </h3>
          </Grid>
          <Grid item lg={4} md={12} sm={12} xs={12}>
            <div className="total-user" style={{ fontSize: 14, textAlign: "right" }}>
              Total Items: {length}
            </div>
          </Grid>
        </Grid>
      );
    }
    return null;
  };

  // ====================== Render Main ====================== //

  render() {
    const { selectedEditContact, loading, branch } = this.props;
    const { edit, dialogDelete, templateDelete } = this.state;
    return (
      <Grid
        container
        spacing={2}
        style={{ alignItems: "flex-end", paddingLeft: 20, marginTop: 10, marginBottom: 30 }}
      >
        {this.renderTopBarDetail()}
        {this.renderSectionTemplates()}
        <TemplateDetailAdd
          edit={edit}
          loading={loading}
          selectedEditContact={selectedEditContact}
          branch={branch}
        />
        <Loading loading={loading} />
        <TemplateDetailDialogDelete
          dialogOpen={dialogDelete}
          templateDelete={templateDelete}
          branch={branch}
          selectedEditContact={selectedEditContact}
          handleClose={this.closeTemplateDeleteDialog}
        />
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  products: state.firebaseWeb.products,
  contactTemplates: state.firebaseWeb.contactTemplates,
});

const mapDispatchToProps = {
  firestoreGetTemplateListByContact,
  firestoreAddTemplateItemToContact,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(TemplateDetail);
