import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  CssBaseline,
  Container,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { VisibilityOutlined, CloudDownloadOutlined, SearchOutlined } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import sumBy from "lodash/sumBy";
import {
  firestoreGetBranchList,
  firestoreGetOrderListByBranch,
  firestoreGetTemplateAndContactListByBranch,
  firestoreGetProductListByBranch,
  firestoreGetOrderListById,
} from "../../store/actions/firebase.action";
import Navbar from "../00_Nav/nav_bar";
import RightNav from "../00_Nav/right_nav";
import ReportOrderTable from "./report_order_table";
import { exportOrdersToCSV } from "./functions/export_order_csv";
import {
  ALL,
  ALL_WITH_EXEPTIONS,
  SENT,
  PROCESSING,
  DELIVERY_SCHEDULED,
  IN_TRANSIT,
  COMPLETED,
  CANCELLED,
} from "../../config/stringConfig";

class ReportOrders extends Component {
  // ====================== Inits ====================== //

  constructor() {
    super();
    this.state = {
      branch: "",
      from: moment().startOf("isoWeek"),
      to: moment().endOf("isoWeek"),
      loading: false,
      search: "",
      searchLoading: false,
      orderBy: "status",
      order: "asc",
      selectedContact: null,
      status: [
        ALL,
        ALL_WITH_EXEPTIONS,
        SENT,
        PROCESSING,
        DELIVERY_SCHEDULED,
        IN_TRANSIT,
        COMPLETED,
        CANCELLED,
      ],
      selectedStatus: ALL,
    };
  }

  componentDidMount = () => {
    this.props.firestoreGetBranchList(); // get Branch
  };

  refineData = () => {
    const { selectedContact, selectedStatus } = this.state;
    const { contacts, orders } = this.props;
    let reportData =
      orders &&
      orders?.map((item) => {
        const scheduledFor = item?.scheduledFor;
        const date = scheduledFor && moment(scheduledFor?.toDate()).format("DD/MM/YYYY hh:mmA");
        return {
          ...item,
          date,
        };
      });
    if (contacts && selectedContact) {
      reportData = reportData?.filter((item) => item?.contactId === selectedContact?.id);
    }
    if (selectedStatus !== ALL) {
      if (selectedStatus === ALL_WITH_EXEPTIONS) {
        reportData = reportData?.filter((item) => item?.status !== CANCELLED);
      } else {
        reportData = reportData?.filter((item) => item?.status === selectedStatus);
      }
    }
    this.setState({
      loading: false,
      reportData,
    });
  };

  // ====================== Functions ====================== //

  handleDateChange = (date, id) => {
    this.setState({
      [id]: date,
    });
  };

  handleSearchChange = (e) => {
    this.setState({
      search: e.target.value,
    });
  };

  handleSelect = (e, id) => {
    this.setState({
      [id]: e.target.value,
    });
    if (id === "branch") {
      const branch = e.target.value;
      this.props.firestoreGetTemplateAndContactListByBranch({ branch });
      this.props.firestoreGetProductListByBranch({ branch });
    }
  };

  handleSelectStatus = (e) => {
    this.setState(
      {
        selectedStatus: e.target.value,
      },
      () => this.refineData()
    );
  };

  handleSort = (id) => {
    const { orderBy, order } = this.state;
    const isAsc = orderBy === id && order === "asc";
    this.setState(
      {
        order: isAsc ? "desc" : "asc",
        orderBy: id,
      },
      () => {
        this.refineData();
      }
    );
  };

  // Select
  toggleSelectRecord = (data) => {
    const { selected } = this.state;
    let newCheck = [];
    const exist = selected && selected.filter((c) => c.itemCode === data.itemCode).length > 0;
    if (exist) {
      newCheck = selected && selected.filter((c) => !(c.itemCode === data.itemCode));
    } else {
      newCheck = [...selected, data];
    }
    this.setState({
      selected: newCheck,
    });
  };

  toggleCheckAll = () => {
    const { selected, data } = this.state;
    let isSelectedAll = false;
    if (selected && data && data.length === selected.length) {
      isSelectedAll = true;
    }
    this.setState({
      selected: isSelectedAll ? [] : data,
    });
  };

  selectContact = (selectedContact) => {
    this.setState(
      {
        selectedContact,
      },
      () => this.refineData()
    );
  };

  // ====================== Firebase Functions ====================== //

  handleSearchByOrderId = () => {
    const { search, branch } = this.state;
    this.setState(
      {
        searchLoading: true,
      },
      () => {
        this.props.firestoreGetOrderListById({ branch, orderId: search }, () => {
          this.setState(
            {
              searchLoading: false,
            },
            () => this.refineData()
          );
        });
      }
    );
  };

  viewOrdersReport = () => {
    const { from, to, branch } = this.state;
    if (from && to && branch) {
      this.setState(
        {
          loading: true,
        },
        () => {
          this.props.firestoreGetOrderListByBranch({ branch, from, to }, () => {
            this.setState(
              {
                loading: false,
              },
              () => this.refineData()
            );
          });
        }
      );
    }
  };

  // ====================== Render Component ====================== //

  renderBranchDropDown = () => {
    const { branchs } = this.props;
    const { branch } = this.state;
    const menuItem =
      branchs &&
      branchs?.map((branch, index) => {
        return (
          <MenuItem key={index} value={branch.id}>
            {branch.tradingName}
          </MenuItem>
        );
      });
    return (
      <FormControl variant="outlined" margin="dense" fullWidth className="table-select">
        <InputLabel id="branchLabel">Branch</InputLabel>
        <Select
          labelId="branchLabel"
          id="branch"
          value={branch || ""}
          onChange={(event) => this.handleSelect(event, "branch")}
          label="Branch"
        >
          {menuItem}
        </Select>
      </FormControl>
    );
  };

  renderRow01 = () => {
    return (
      <Grid container spacing={3} direction="row">
        <Grid item md={2} xs={12}>
          <h3 className="regular-title" style={{ marginTop: 10, fontSize: 20, marginBottom: 0 }}>
            Orders Management
          </h3>
        </Grid>
        <Grid item md={2} xs={12}>
          {this.renderBranchDropDown()}
        </Grid>
      </Grid>
    );
  };

  renderRow02 = () => {
    const { from, to, branch } = this.state;
    const disabled = !branch;
    return (
      <Grid container spacing={3} direction="row" alignItems="center">
        <Grid item md={2} xs={12}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              autoOk
              className="date-picker"
              variant="inline"
              inputVariant="outlined"
              fullWidth
              value={from}
              maxDate={to}
              label="From"
              format="DD/MM/YYYY"
              InputAdornmentProps={{ position: "end" }}
              onChange={(date) => this.handleDateChange(date, "from")}
              margin="dense"
              disabled={disabled}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item md={2} xs={12}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              autoOk
              className="date-picker"
              variant="inline"
              inputVariant="outlined"
              fullWidth
              minDate={from}
              value={to}
              label="To"
              format="DD/MM/YYYY"
              InputAdornmentProps={{ position: "end" }}
              onChange={(date) => this.handleDateChange(date, "to")}
              margin="dense"
              disabled={disabled}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item>{this.renderViewButton()}</Grid>
        <Grid item>{this.renderDownloadButton()}</Grid>
      </Grid>
    );
  };

  renderViewButton = () => {
    const { from, to, branch, loading } = this.state;
    const disabled = !(from && to && branch && !loading);
    if (loading) {
      return (
        <Button
          variant="contained"
          color="primary"
          disabled
          style={{ marginTop: 3 }}
          startIcon={<CircularProgress size={15} />}
        >
          View
        </Button>
      );
    }
    return (
      <Button
        variant="contained"
        color="primary"
        disabled={disabled}
        style={{ marginTop: 3 }}
        startIcon={<VisibilityOutlined />}
        onClick={() => this.viewOrdersReport()}
      >
        View
      </Button>
    );
  };

  renderDownloadButton = () => {
    const { branchs, contacts } = this.props;
    const { loading, reportData, branch, from, to } = this.state;
    const disabled = !(reportData && contacts) || loading;
    return (
      <Button
        variant="contained"
        color="primary"
        disabled={disabled}
        style={{ marginTop: 3 }}
        startIcon={<CloudDownloadOutlined />}
        onClick={() =>
          exportOrdersToCSV({
            orders: reportData,
            from,
            to,
            branch,
            branchs,
            contacts,
          })
        }
      >
        Download
      </Button>
    );
  };

  renderSearchOrderId = () => {
    const { branch, search } = this.state;
    const disabled = !branch;
    return (
      <TextField
        margin="dense"
        label="Search By Order ID"
        variant="outlined"
        fullWidth
        value={search || ""}
        disabled={disabled}
        onChange={(e) => this.handleSearchChange(e)}
        onKeyPress={(ev) => {
          if (ev.key === "Enter") {
            this.handleSearchByOrderId();
            ev.preventDefault();
          }
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">{this.renderSearchButton()}</InputAdornment>,
        }}
      />
    );
  };

  renderSearchButton = () => {
    const { searchLoading } = this.state;
    if (searchLoading) {
      return (
        <IconButton aria-label="search order id" disabled>
          <CircularProgress size={15} />
        </IconButton>
      );
    }
    return (
      <IconButton aria-label="search order id" onClick={() => this.handleSearchByOrderId()}>
        <SearchOutlined />
      </IconButton>
    );
  };

  renderRow03 = () => {
    const { reportData } = this.state;
    const length = (reportData && reportData.length) || "--";
    const revenue = sumBy(reportData, "total");
    const subRevenue = sumBy(reportData, "subTotal");
    const revenueString = revenue.toFixed(2);
    const subRevenueString = subRevenue.toFixed(2);
    return (
      <Grid container spacing={3} direction="row" alignItems="center">
        <Grid item lg={4} md={6} sm={12} xs={12}>
          {this.renderSearchOrderId()}
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {this.renderSearchAutocomplete()}
        </Grid>
        <Grid item lg={2} md={6} sm={12} xs={12}>
          {this.renderStatusDropDown()}
        </Grid>
        <Grid
          item
          lg={3}
          md={6}
          sm={12}
          xs={12}
          style={{ textAlign: "right", fontWeight: "bold", fontSize: 15 }}
        >
          Total Orders: <span style={{ color: "#1a76d2" }}>{length}</span> | Revenue:{" "}
          <span style={{ color: "#1a76d2" }}>
            ${revenueString}{" "}
            <span style={{ color: "green", fontWeight: "normal", fontSize: 13 }}>
              (${subRevenueString})
            </span>
          </span>
        </Grid>
      </Grid>
    );
  };

  renderSearchAutocomplete = () => {
    const { selectedContact } = this.state;
    const { contacts } = this.props;
    const disabled = !contacts;
    return (
      <Autocomplete
        id="product-search"
        onChange={(e, value) => this.selectContact(value)}
        value={selectedContact}
        getOptionSelected={(option) => option}
        getOptionLabel={(option) => {
          const contactName = option.contactName;
          const firstName = option.firstName;
          const lastName = option.lastName;
          const fullName = `${contactName} - ${firstName} ${lastName}`;
          return fullName;
        }}
        options={contacts || []}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="dense"
            label="Filter By Client"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    );
  };

  renderStatusDropDown = () => {
    const { status, selectedStatus } = this.state;
    const menuItem =
      status &&
      status.map((item, index) => {
        return (
          <MenuItem key={index} value={item}>
            {item}
          </MenuItem>
        );
      });
    return (
      <FormControl variant="outlined" margin="dense" fullWidth className="table-select">
        <InputLabel id="statusLabel">Filter By Status</InputLabel>
        <Select
          labelId="statusLabel"
          id="status"
          value={selectedStatus || ""}
          onChange={(event) => this.handleSelectStatus(event)}
          label="Status"
        >
          {menuItem}
        </Select>
      </FormControl>
    );
  };

  // ====================== Render Main ====================== //

  render() {
    const { auth } = this.props;
    if (!auth.uid) return <Redirect to={"/signin"} />;
    const { reportData, selected, order, orderBy, loading } = this.state;
    return (
      <div className="root-container">
        <CssBaseline />
        <Navbar title="Report Orders" right={<RightNav />} />
        <Container maxWidth="xl" className="report report-time-tracking">
          {this.renderRow01()}
          {this.renderRow02()}
          {this.renderRow03()}
          <ReportOrderTable
            reportData={reportData}
            selected={selected}
            order={order}
            orderBy={orderBy}
            loading={loading}
            handleSort={this.handleSort}
            handleCheckAll={this.toggleCheckAll}
            handleCheck={this.toggleSelectRecord}
            handleUpdate={this.refineData}
          />
        </Container>
              
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  branchs: state.firebaseWeb.branchs,
  orders: state.firebaseWeb.orders,
  contacts: state.firebaseWeb.contacts,
  products: state.firebaseWeb.products,
});

const mapDispatchToProps = {
  firestoreGetBranchList,
  firestoreGetOrderListByBranch,
  firestoreGetTemplateAndContactListByBranch,
  firestoreGetProductListByBranch,
  firestoreGetOrderListById,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ReportOrders);
