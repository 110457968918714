// local
export const LOCAL_USER = "user";
// firebase ref
export const REF_ORDERS = "orders";
export const REF_TOPUP = "topups";
// redux ref
export const FIREBASE_AUTH_STATUS = "firebase_auth_status_update";
export const LOGIN_LOADING = "firebase_login_loading";
export const LOGIN_SUCCESS = "firebase_login_success";
export const LOGIN_FAILED = "firebase_login_fail";
export const LOGOUT_SUCCESS = "firebase_logout_success";

export const GET_ORDER_DETAIL_SUCCESS = "get_order_detail_success";
export const GET_TOPUP_SUCCESS = "get_topup_success";
export const GET_ORDER_LIST_SUCCESS = "get_order_list_success";
// notification ref
export const NOTIFICATION_CLIENT = "notification_client";
