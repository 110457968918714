import FileSaver from 'file-saver';
import moment from 'moment';
import { BRAND_NAME } from '../../../config/config';
import { jsonToCSV } from 'react-papaparse';

const columns = [
    'checkedAt',
    'createAt',
    'deviceName',
    'latitude',
    'longitude',
    'screenshot',
    'subUrl',
    'user',
]

export const exportTimeTrackingToCSV = ({ timeTracking, users }) => {
    // =========================  Provider =========================
    // // =========================  Row Data =========================
    const timeData = converData({ timeTracking, users });
    const rowTableHeader = 'CheckedAt,CreateAt,DeviceName,Latitude,Longitude,Screenshot,SubUrl,User\n';
    const rowTableBody = jsonToCSV(timeData, { columns: columns, header: false, quotes: true });
    // // =========================  CSV =========================
    const csvBuffer = `${rowTableHeader}${rowTableBody}`;
    const currentTime = moment().format('DD_MM_YYYY-HH_mm_ss');
    const fileName = `${BRAND_NAME} -  All Time Tracking - ${currentTime}`;
    const fileType = 'text/csv;charset=utf-8;';
    const fileExtension = '.csv';
    const data = new Blob([csvBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
}

const converData = ({ timeTracking, users }) => {
    const result = timeTracking && timeTracking.map(item => {
        const checkedAt = item.checkedAt;
        const createAt = item.createAt;
        const checkAtString = checkedAt && moment(checkedAt.toDate()).format('DD MM YYYY hh:mm:ss');
        const createAtString = createAt && moment(createAt.toDate()).format('DD MM YYYY hh:mm:ss');
        // Users
        const userId = item.user;
        const user = users && userId && users.find(item => item.id === userId);
        const userName = user && user.username;
        return {
            ...item,
            checkedAt: checkAtString,
            createAt: createAtString,
            user: userName,
        }
    });
    return result;
}