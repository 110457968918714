const initState = {
  driverLogs: null,
};
const reportReducer = (state = initState, action) => {
  switch (action.type) {
    case "get_report_driver_log_success":
      return {
        ...state,
        driverLogs: action.payload,
      };
    default:
      return state;
  }
};

export default reportReducer;
