import React, { Component} from 'react';
import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    TableContainer,
    Table,
    Grid
} from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { EditOutlined } from '@material-ui/icons';

class ReportOrderDialogDetail extends Component {

  // ====================== Inits ====================== //
    
    constructor() {
        super();
        // state
        this.state = {
            header: [
                { id: 'inventoryItemCode', label: 'Code', align: 'left', sortable: false },
                { id: 'description', label: 'Name', align: 'left', sortable: true },
                { id: 'quantity', label: 'Quantity', align: 'right', sortable: true },
                { id: 'unitAmount', label: 'Unit Amount', align: 'right', sortable: true },
                { id: 'amount', label: 'Total', align: 'right', sortable: true },
            ]
        };
    }

    // ====================== Functions ====================== //


    handleCancel = () => {
        this.setState({
            loading: false,
        }, () => {
            this.props.handleClose()
        })
    }

    handleEdit = () => {
        this.setState({
            loading: false,
        }, () => {
            this.props.handleEdit()
        })
    }

    // ====================== Firebase Functions ====================== //

    // ====================== Render Component ====================== //

    renderTableHead = () => {
        const { header } = this.state;
        return (
            <TableHead>
              <TableRow>
                <TableCell align='center'> # </TableCell>
                { header && header.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding='default'
                    >
                        {headCell.label}
                    </TableCell>
                )) }
              </TableRow>
            </TableHead>
        );
    }

    renderTableBody = () => {
        const { data } = this.props;
        const products = data && data.products;
        if (products) {
            return (
                <TableBody>
                    { products && products.map((row, index) => this.renderTableRow({ row, index })) }
                </TableBody>
            );
        }
        return <TableBody />;
    }
    
    renderTableRow = ({ row, index }) => {
        const unitAmount = parseFloat(row.unitAmount) || 0;
        const quantity = parseFloat(row.quantity) || 0;
        const total = parseFloat(unitAmount * quantity).toFixed(2);
        return (
            <TableRow
                hover
                tabIndex={-1}
                key={index}
                style={{ cursor: 'pointer' }}
            >
                <TableCell align='center'>{index + 1}</TableCell>
                <TableCell align='left'>{row.inventoryItemCode}</TableCell>
                <TableCell align='left'>{row.description}</TableCell>
                <TableCell align='right'>{row.quantity}</TableCell>
                <TableCell align='right'>${row.unitAmount}</TableCell>
                <TableCell align='right'>${total}</TableCell>
            </TableRow>
        );
    }

    renderTable = () => {
        return (
            <Paper style={{ width: '100%', marginTop: 10, marginBottom: 30 }}>
                <TableContainer className='report-table'>
                    <Table stickyHeader aria-label="sticky table">
                        { this.renderTableHead() }
                        { this.renderTableBody() }
                    </Table>
                </TableContainer>
                { this.renderSubTotal() }
                { this.renderGST() }
                { this.renderTotal() }
            </Paper>
        )
    }

    renderSubTotal = () => {
        const { data } = this.props;
        const subTotal = data && data.subTotal;
        return (
            <Grid 
                container 
                spacing={2} 
                direction="row"
                justify="flex-end"
                style={{ marginTop: 10 }}
            >
                <Grid item md={2} sm={6} xs={12} style={{ textAlign: 'right', alignItems: 'flex-end' }}>
                    Subtotal
                </Grid>
                <Grid item md={2} sm={6} xs={12} style={{ textAlign: 'right', alignItems: 'flex-end' }}>
                    { subTotal }
                </Grid>
            </Grid>
        );
    }

    renderGST = () => {
        const { data } = this.props;
        const tax = data && data.tax;
        return (
            <Grid 
                container 
                spacing={2} 
                direction="row"
                justify="flex-end"
            >
                <Grid item md={2} sm={6} xs={12} style={{ textAlign: 'right', alignItems: 'flex-end' }}>
                    GST 15%
                </Grid>
                <Grid item md={2} sm={6} xs={12} style={{ textAlign: 'right', alignItems: 'flex-end' }}>
                    { tax }
                </Grid>
            </Grid>
        );
    }

    renderTotal = () => {
        const { data } = this.props;
        const total = data && data.total;
        return (
            <Grid 
                container 
                spacing={2} 
                direction="row"
                justify="flex-end"
                style={{ marginBottom: 10 }}
            >
                <Grid item md={2} sm={6} xs={12} style={{ textAlign: 'right', fontWeight: 'bold', alignItems: 'flex-end' }}>
                    Total
                </Grid>
                <Grid item md={2} sm={6} xs={12} style={{ textAlign: 'right', fontWeight: 'bold', alignItems: 'flex-end' }}>
                    { total }
                </Grid>
            </Grid>
        );
    }


    // ====================== Render Main ====================== //

    render() {
      const { error, loading } = this.state;
      const { dialogOpen, handleClose, data } = this.props;
      const orderId = data && data.orderId;
      const contactName = data && data.contactName;
      const date = data && data.date;
        return (
            <Dialog
                open={dialogOpen}
                onClose={handleClose}
                onEntering={this.resetData}
                scroll='paper'
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                maxWidth='md'
                fullWidth
            >
            <DialogTitle id="scroll-dialog-title">{contactName} - Order ID: {orderId} - Date: {date}</DialogTitle>
            <DialogContent dividers className='container-csv-upload one-file'>
                { this.renderTable() }
            </DialogContent>
            <div className="red-text center">
                { error ? <p>{error}</p> : null }
            </div>
            <DialogActions>
                <Button 
                    onClick={() => this.handleCancel()}
                    disabled={loading}
                    variant='outlined'
                >
                    Cancel
                </Button>
                <Button 
                    color='primary'
                    onClick={() => this.handleEdit()}
                    disabled={loading}
                    variant='contained'
                    startIcon={<EditOutlined />}
                    disableElevation
                >
                    Edit
                </Button>
            </DialogActions>
        </Dialog>
        );
    }
};
  
export default compose(connect(null,null))(ReportOrderDialogDetail)