const initState = {
  rosters: null,
};
const rosterReducer = (state = initState, action) => {
  switch (action.type) {
    case "get_report_roster_success":
      return {
        ...state,
        rosters: action.payload,
      };
    default:
      return state;
  }
};

export default rosterReducer;
