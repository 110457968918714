/* eslint-disable no-unused-vars */
import firebase from "firebase";
import moment from "moment";

// -------------------------- Roster --------------------------
const rosterSubs = [];
export const firestoreGetRosterBranch = ({ branch, from, to }, callback) => (dispatch) => {
  const currentUser = firebase.auth().currentUser;
  const owner = currentUser && currentUser.uid;
  const logsRef = firebase.firestore().collection("roster");
  const fromDate = moment(from).startOf("day")._d;
  const toDate = moment(to).endOf("day")._d;
  const unsubRoster = logsRef
    .where("owner", "==", owner)
    .where("branch", "==", branch)
    .where("workAt", ">=", fromDate)
    .where("workAt", "<=", toDate)
    .onSnapshot((snapshot) => {
      if (snapshot) {
        const data = snapshot.docs.map((doc) => doc.data());
        getRosterSuccess(dispatch, data);
      }
      if (callback) {
        callback();
      }
    });
  rosterSubs.push(unsubRoster);
};

const getRosterSuccess = (dispatch, data) => {
  dispatch({
    type: "get_report_roster_success",
    payload: data,
  });
};

export const unSubRoster = () => (dispatch) => {
  rosterSubs.forEach((subscriber) => {
    subscriber();
  });
  rosterSubs.length = 0;
};

// -------------------------- Delete --------------------------
export const firestoreDeleteRoster = ({ id }, callback) => (dispatch) => {
  firebase.firestore().collection("roster").doc(id).delete();
};

export const firestoreDeleteRosterList = ({ list }, callback) => (dispatch) => {
  const rosterRef = firebase.firestore().collection("roster");
  const allPromise = list.map((item) => {
    return rosterRef.doc(item?.id).delete();
  });
  Promise.all(allPromise).then(() => {
    if (callback) {
      callback();
    }
  });
};
