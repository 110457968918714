import { combineReducers } from "redux";
import firebaseWeb from "./firebase.reducer";
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";
import reportReducer from "./report.reducer";
import rosterReducer from "./roster.reducer";
import topupReducer from "./topup.reducer";
// import firebase from './firebase.reducer';

const rootReducer = combineReducers({
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  firebaseWeb,
  reportReducer,
  rosterReducer,
  topupReducer,
});

export default rootReducer;
