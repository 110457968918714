import React from 'react';
import loadingGif from '../asset/img/loading.gif';

export const Loading = ({ loading }) => {
    if (loading) {
        return (
            <div className="loading">
                <img className='full' src= { loadingGif } alt="Loading... " />
            </div>
        );
    }
    return null;
}