import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  CssBaseline,
  Container,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import {
  VisibilityOutlined,
  CloudDownloadOutlined,
  SearchOutlined,
  LibraryAddCheckOutlined,
  AddOutlined,
  EditOutlined,
  DoneAllOutlined,
  CloudUploadOutlined,
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import {
  firestoreGetBranchList,
  firestoreGetUserGroup,
  firestoreGetUserList,
  firestoreGetTimeTrackingListByBranch,
  firestoreTimeTrackingHandleTasks,
} from "../../store/actions/firebase.action";

import Navbar from "../00_Nav/nav_bar";
import RightNav from "../00_Nav/right_nav";
import TimeTrackingTable from "./time_tracking_table";
import TimeTrackingDialogAdd from "./dialog/time_tracking_add";
import TimeTrackingDialogEditSelected from "./dialog/time_tracking_edit_selected";
import { refineData } from "./functions/refine_data";
import { exportToCSV } from "./functions/csv_converter";
import { exportTimeTrackingToCSV } from "./functions/export_time_tracking";
import TimeTrackingDialogCsvUpload from "./dialog/time_tracking_dialog_upload";

class ReportTimeTracking extends Component {
  // ====================== Inits ====================== //

  constructor() {
    super();
    this.state = {
      branch: "",
      from: moment().startOf("isoWeek"),
      to: moment().endOf("isoWeek"),
      group: "all",
      loading: false,
      selectedUser: null,
      showPhoto: false,
      showLocation: false,
      orderBy: "fullname",
      order: "asc",
      isEdit: false,
      edited: [],
      editError: [],
      editLoading: false,
      selected: [],
      reportData: null,
      dialogAdd: false,
      dialogSelected: false,
      matrix: [],
      csvUpload: false,
    };
  }

  componentDidMount = () => {
    this.props.firestoreGetUserGroup(); // get Group
    this.props.firestoreGetBranchList(); // get Branch
    this.props.firestoreGetUserList(); // get users
  };

  updateData = () => {
    const { users, userGroups, timeTracking } = this.props;
    const { group, selectedUser, order, orderBy } = this.state;
    const reportData = refineData({
      users,
      order,
      orderBy,
      userGroups,
      timeTracking,
      groupId: group,
      selectedUser,
    });
    this.setState({
      loading: false,
      reportData,
    });
  };

  // ====================== Functions ====================== //

  selectUser = (selectedUser) => {
    this.setState(
      {
        selectedUser,
        selected: [],
      },
      () => {
        this.updateData();
      }
    );
  };

  handleSelect = (e, id) => {
    this.setState(
      {
        [id]: e.target.value,
        selectedUser: null,
        selected: [],
      },
      () => {
        if (id === "group") {
          this.updateData();
        }
      }
    );
  };

  handleCheckShowPhoto = () => {
    const { showPhoto } = this.state;
    this.setState({
      showPhoto: !showPhoto,
    });
  };

  handleCheckLocation = () => {
    const { showLocation } = this.state;
    this.setState({
      showLocation: !showLocation,
    });
  };

  handleDateChange = (date, id) => {
    this.setState({
      [id]: date,
    });
  };

  handleSort = (id) => {
    const { orderBy, order } = this.state;
    const isAsc = orderBy === id && order === "asc";
    this.setState(
      {
        order: isAsc ? "desc" : "asc",
        orderBy: id,
      },
      () => {
        this.updateData();
      }
    );
  };

  toggleCheckAll = () => {
    const { selected, reportData } = this.state;
    const body = reportData && reportData.body && reportData.body;
    const row = body && body.filter((b) => b.type === "row");
    let isSelectedAll = false;
    if (selected && row && row.length === selected.length) {
      isSelectedAll = true;
    }
    this.setState({
      selected: isSelectedAll ? [] : row,
    });
  };

  toggleSelectRecord = (data) => {
    const { selected } = this.state;
    let newCheck = [];
    const exist =
      selected && selected.filter((c) => c.username === data.username && c.stringDate === data.stringDate).length > 0;
    if (exist) {
      newCheck =
        selected && selected.filter((c) => !(c.username === data.username && c.stringDate === data.stringDate));
    } else {
      newCheck = [...selected, data];
    }
    this.setState({
      selected: newCheck,
    });
  };

  openDialogAdd = () => {
    this.setState({
      dialogAdd: true,
    });
  };

  closeDialogAdd = () => {
    this.setState(
      {
        dialogAdd: false,
      },
      () => {
        this.viewReportTimeTracking();
      }
    );
  };

  openDialogSelected = () => {
    this.setState({
      dialogSelected: true,
    });
  };

  closeDialogSelected = () => {
    this.setState({
      dialogSelected: false,
    });
  };

  confirmUpdateComplete = () => {
    this.setState(
      {
        dialogSelected: false,
        selected: [],
      },
      () => {
        this.viewReportTimeTracking();
      }
    );
  };

  openEditMode = () => {
    this.setState({
      isEdit: true,
      edited: [],
      editError: [],
      selected: [],
    });
  };

  closeEditMode = () => {
    this.setState({
      isEdit: false,
      edited: [],
      editError: [],
      matrix: [],
    });
  };

  successEdited = () => {
    this.setState(
      {
        isEdit: false,
        edited: [],
        editError: [],
        editLoading: false,
      },
      () => {
        this.viewReportTimeTracking();
      }
    );
  };

  isEditValid = ({ edited, editError }) => {
    this.setState({
      edited,
      editError,
    });
  };

  // CSV upload
  openCSVUpload = () => {
    this.setState({
      csvUpload: true,
    });
  };

  closeCSVUpload = () => {
    this.setState({
      csvUpload: false,
    });
  };

  successCSVUpload = () => {
    this.setState(
      {
        csvUpload: false,
      },
      () => this.updateData()
    );
  };

  // ====================== Firebase Functions ====================== //

  viewReportTimeTracking = () => {
    const { from, to, branch } = this.state;
    if (from && to && branch) {
      this.setState(
        {
          loading: true,
        },
        () => {
          this.props.firestoreGetTimeTrackingListByBranch({ branch, from, to }, () => {
            this.updateData();
          });
        }
      );
    }
  };

  updateEditedRecord = () => {
    const { edited } = this.state;
    // Tạo task
    let tasks = [];
    edited &&
      edited.forEach((item) => {
        const cell = item.cell;
        const value = item.value;
        const checkedAt = moment(item.value)._d;
        const user = item && item.row && item.row.user;
        if (cell && value) {
          // update (có giá trị mới và cũ)
          const recordId = cell.recordId;
          const newTask = {
            checkedAt,
            type: "update",
            user,
            recordId,
          };
          tasks = [...tasks, newTask];
        } else if (cell && !value) {
          // xóa (có giá trị cũ nhưng không có mới)
          const recordId = cell.recordId;
          const newTask = {
            type: "delete",
            recordId,
          };
          tasks = [...tasks, newTask];
        } else if (!cell && value) {
          // add (không có giá trị cũ nhưng có mới)
          const newTask = {
            checkedAt,
            type: "add",
            user,
          };
          tasks = [...tasks, newTask];
        }
      });
    // Thực hiện task
    this.setState(
      {
        editLoading: true,
      },
      () => {
        this.props.firestoreTimeTrackingHandleTasks({ tasks }, () => {
          this.successEdited();
        });
      }
    );
  };

  // ====================== Render Component ====================== //

  renderBranchDropDown = () => {
    const { branchs } = this.props;
    const { branch, isEdit } = this.state;
    const disabled = isEdit;
    const menuItem =
      branchs &&
      branchs.map((branch, index) => {
        return (
          <MenuItem key={index} value={branch.id}>
            {branch.tradingName}
          </MenuItem>
        );
      });
    return (
      <FormControl variant="outlined" margin="dense" fullWidth className="table-select">
        <InputLabel id="branchLabel">Branch</InputLabel>
        <Select
          labelId="branchLabel"
          id="branch"
          value={branch}
          onChange={(event) => this.handleSelect(event, "branch")}
          label="Branch"
          disabled={disabled}
        >
          {menuItem}
        </Select>
      </FormControl>
    );
  };

  renderRow01 = () => {
    return (
      <Grid container spacing={3} direction="row" justify="flex-start" alignItems="center">
        <Grid item md={2} xs={12}>
          <h3 className="regular-title" style={{ marginTop: 10, fontSize: 20, marginBottom: 0 }}>
            Timesheet Details
          </h3>
        </Grid>
        <Grid item md={2} xs={12}>
          {this.renderBranchDropDown()}
        </Grid>
      </Grid>
    );
  };

  renderRow02 = () => {
    const { from, to, isEdit } = this.state;
    const disabled = isEdit;
    return (
      <Grid container spacing={3} direction="row" justify="flex-start" alignItems="center">
        <Grid item md={2} xs={12}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              autoOk
              className="date-picker"
              variant="inline"
              inputVariant="outlined"
              fullWidth
              value={from}
              maxDate={to}
              label="From"
              format="DD/MM/YYYY"
              InputAdornmentProps={{ position: "end" }}
              onChange={(date) => this.handleDateChange(date, "from")}
              margin="dense"
              disabled={disabled}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item md={2} xs={12}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              autoOk
              className="date-picker"
              variant="inline"
              inputVariant="outlined"
              fullWidth
              minDate={from}
              value={to}
              label="To"
              format="DD/MM/YYYY"
              InputAdornmentProps={{ position: "end" }}
              onChange={(date) => this.handleDateChange(date, "to")}
              margin="dense"
              disabled={disabled}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item>{this.renderViewButton()}</Grid>
        <Grid item>{this.renderDownloadButton()}</Grid>
        <Grid item>{/* { this.renderExportImportTimeTracking() } */}</Grid>
      </Grid>
    );
  };

  renderExportImportTimeTracking = () => {
    const { timeTracking, branchs, users } = this.props;
    const { branch } = this.state;
    const disabled = !(branchs && branch && timeTracking && timeTracking.length > 0);
    const uploadDisable = !branch;
    return (
      <Grid item>
        <Tooltip title="Export all time tracking to csv">
          <span>
            <IconButton
              aria-label="download"
              onClick={() => exportTimeTrackingToCSV({ timeTracking, branchs, users })}
              disabled={disabled}
            >
              <CloudDownloadOutlined style={{ color: disabled ? "#c8c8c8" : "#333" }} />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Import all time tracking from csv">
          <span>
            <IconButton aria-label="upload" onClick={() => this.openCSVUpload()} disabled={uploadDisable}>
              <CloudUploadOutlined style={{ color: uploadDisable ? "#c8c8c8" : "#333" }} />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
    );
  };

  renderViewButton = () => {
    const { from, to, branch, loading, isEdit } = this.state;
    const disabled = !(from && to && branch && !loading && !isEdit);
    if (loading) {
      return (
        <Button
          variant="contained"
          color="primary"
          disabled
          style={{ marginTop: 3 }}
          startIcon={<CircularProgress size={15} />}
        >
          View
        </Button>
      );
    }
    return (
      <Button
        variant="contained"
        color="primary"
        disabled={disabled}
        style={{ marginTop: 3 }}
        startIcon={<VisibilityOutlined />}
        onClick={() => this.viewReportTimeTracking()}
      >
        View
      </Button>
    );
  };

  renderDownloadButton = () => {
    const { branchs } = this.props;
    const { loading, reportData, isEdit, branch, from, to } = this.state;
    const disabled = !reportData || loading || isEdit;
    // const data = { reportData, branch, branchs, from, to };
    return (
      <Button
        variant="contained"
        color="primary"
        disabled={disabled}
        style={{ marginTop: 3 }}
        startIcon={<CloudDownloadOutlined />}
        onClick={() =>
          exportToCSV({
            reportData,
            branch,
            branchs,
            from,
            to,
          })
        }
      >
        Download
      </Button>
    );
  };

  renderSearchAutocomplete = () => {
    const { reportData, group, branch, selectedUser, isEdit } = this.state;
    const { users } = this.props;
    const disabled = !reportData || isEdit;
    let options = users || [];
    options = options.filter((o) => o.branch === branch);
    if (group !== "all") {
      options = options.filter((o) => o.group === group);
    }
    return (
      <Autocomplete
        id="asynchronous-search"
        onChange={(e, value) => this.selectUser(value)}
        value={selectedUser}
        getOptionSelected={(option) => option}
        getOptionDisabled={(option) => !option.active}
        getOptionLabel={(option) => {
          const firstName = option.firstName;
          const lastName = option.lastName;
          const fullName = firstName && lastName && `${firstName} ${lastName}`;
          return `${fullName} (${option.username})`;
        }}
        options={options}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="dense"
            label="Search By Name"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    );
  };

  renderGroupDropDown = () => {
    const { userGroups } = this.props;
    const { reportData, group, isEdit } = this.state;
    const disabled = !reportData || isEdit;
    const menuItem =
      userGroups &&
      userGroups.map((group, index) => {
        return (
          <MenuItem key={index} value={group.id}>
            {group.name}
          </MenuItem>
        );
      });
    return (
      <FormControl variant="outlined" margin="dense" fullWidth className="table-select">
        <InputLabel id="groupLabel">Group</InputLabel>
        <Select
          labelId="groupLabel"
          id="group"
          value={group}
          onChange={(event) => this.handleSelect(event, "group")}
          label="Group"
          disabled={disabled}
        >
          <MenuItem value="all">All Groups</MenuItem>
          {menuItem}
        </Select>
      </FormControl>
    );
  };

  renderCheckBoxShowPhoto = () => {
    const { showPhoto, reportData, isEdit } = this.state;
    const disabled = !reportData || isEdit;
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={showPhoto}
            onChange={() => this.handleCheckShowPhoto()}
            name="showPhoto"
            color="primary"
            disabled={disabled}
          />
        }
        label="Show photos"
      />
    );
  };

  renderCheckBoxShowLocation = () => {
    const { showLocation, reportData, isEdit } = this.state;
    const disabled = !reportData || isEdit;
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={showLocation}
            onChange={() => this.handleCheckLocation()}
            name="showLocation"
            color="primary"
            disabled={disabled}
          />
        }
        label="Show location"
      />
    );
  };

  renderButtonGroup = () => {
    const { isEdit } = this.state;
    if (isEdit) {
      return (
        <Grid container spacing={3} direction="row" justify="flex-end" alignItems="center">
          {this.renderEditCancelButton()}
          {this.renderEditConfirmButton()}
        </Grid>
      );
    }
    return (
      <Grid container spacing={1} direction="row" justify="flex-end" alignItems="center">
        {this.renderButtonAddRecord()}
        {this.renderButtonEditSelected()}
        {this.renderButtonEdit()}
      </Grid>
    );
  };

  renderButtonAddRecord = () => {
    const { reportData } = this.state;
    const disabled = !reportData;
    return (
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: 3, backgroundColor: disabled ? "#e0e0e0" : "#4caf50" }}
          startIcon={<AddOutlined />}
          disabled={disabled}
          onClick={() => this.openDialogAdd()}
        >
          Add record
        </Button>
      </Grid>
    );
  };

  renderButtonEditSelected = () => {
    const { selected } = this.state;
    const length = selected.length;
    const disabled = length > 0 ? false : true;
    return (
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: 3 }}
          startIcon={<LibraryAddCheckOutlined />}
          disabled={disabled}
          onClick={() => this.openDialogSelected()}
        >
          Edit selected ({length})
        </Button>
      </Grid>
    );
  };

  renderButtonEdit = () => {
    const { reportData } = this.state;
    const disabled = !reportData;
    return (
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: 3 }}
          startIcon={<EditOutlined />}
          disabled={disabled}
          onClick={() => this.openEditMode()}
        >
          Edit
        </Button>
      </Grid>
    );
  };

  renderEditCancelButton = () => {
    const { editLoading } = this.state;
    return (
      <Grid item>
        <Button
          variant="contained"
          color="default"
          style={{ marginTop: 3 }}
          disabled={editLoading}
          onClick={() => this.closeEditMode()}
        >
          Cancel
        </Button>
      </Grid>
    );
  };

  renderEditConfirmButton = () => {
    const { edited, editError, editLoading } = this.state;
    const disabled = editError.length > 0 || edited.length === 0;
    if (editLoading) {
      return (
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: 3 }}
            disabled
            startIcon={<CircularProgress size={15} />}
          >
            Confirm
          </Button>
        </Grid>
      );
    }
    return (
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: 3, backgroundColor: disabled ? "#e0e0e0" : "#4caf50" }}
          disabled={disabled}
          startIcon={<DoneAllOutlined />}
          onClick={() => this.updateEditedRecord()}
        >
          Confirm
        </Button>
      </Grid>
    );
  };

  renderRow03 = () => {
    return (
      <Grid container spacing={3} direction="row" justify="flex-start" alignItems="center">
        <Grid item lg={4} md={3} sm={6} xs={12}>
          {this.renderSearchAutocomplete()}
        </Grid>
        <Grid item lg={2} md={3} sm={6} xs={12}>
          {this.renderGroupDropDown()}
        </Grid>
        <Grid item lg={2} md={6} sm={4} xs={12} className="report-checkbox">
          {this.renderCheckBoxShowPhoto()}
          {this.renderCheckBoxShowLocation()}
        </Grid>
        <Grid item lg={4} md={12} sm={8} xs={12}>
          {this.renderButtonGroup()}
        </Grid>
      </Grid>
    );
  };

  renderRow04 = () => {
    const { reportData } = this.state;
    const finalWorking = (reportData && reportData.finalWorking) || "--:--";
    const finalBreaking = (reportData && reportData.finalBreaking) || "--:--";
    return (
      <Grid container spacing={3} direction="row" justify="flex-end" alignItems="center">
        <Grid item lg={4} md={6} sm={12} xs={12} style={{ textAlign: "right", fontWeight: "bold", fontSize: 15 }}>
          Total: <span style={{ color: "#1a76d2" }}>{finalWorking}</span> |{" "}
          <span style={{ color: "red" }}>{finalBreaking}</span>
        </Grid>
      </Grid>
    );
  };

  // ====================== Render Main ====================== //

  render() {
    const { auth } = this.props;
    if (!auth.uid) return <Redirect to={"/signin"} />;
    const {
      reportData,
      showPhoto,
      showLocation,
      branch,
      order,
      orderBy,
      selected,
      dialogAdd,
      dialogSelected,
      isEdit,
      edited,
      editError,
      csvUpload,
    } = this.state;
    return (
      <div className="root-container">
        <CssBaseline />
        <Navbar title="Report Time Tracking" right={<RightNav />} />
        <Container maxWidth="xl" className="report report-time-tracking">
          {this.renderRow01()}
          {this.renderRow02()}
          {this.renderRow03()}
          {this.renderRow04()}
          <TimeTrackingTable
            reportData={reportData}
            showPhoto={showPhoto}
            showLocation={showLocation}
            order={order}
            orderBy={orderBy}
            handleSort={this.handleSort}
            handleCheckAll={this.toggleCheckAll}
            selected={selected}
            handleCheck={this.toggleSelectRecord}
            isEdit={isEdit}
            edited={edited}
            editError={editError}
            isEditValid={this.isEditValid}
          />
          <TimeTrackingDialogAdd dialogOpen={dialogAdd} handleClose={this.closeDialogAdd} branch={branch} />
          <TimeTrackingDialogEditSelected
            dialogOpen={dialogSelected}
            handleClose={this.closeDialogSelected}
            handleUpdateComplete={this.confirmUpdateComplete}
            selected={selected}
          />
          <TimeTrackingDialogCsvUpload
            dialogOpen={csvUpload}
            handleClose={this.closeCSVUpload}
            handleConfirm={this.successCSVUpload}
            branch={branch}
          />
        </Container>
              
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  branchs: state.firebaseWeb.branchs,
  userGroups: state.firebaseWeb.userGroups,
  users: state.firebaseWeb.users,
  timeTracking: state.firebaseWeb.timeTracking,
});

const mapDispatchToProps = {
  firestoreGetBranchList,
  firestoreGetUserGroup,
  firestoreGetUserList,
  firestoreGetTimeTrackingListByBranch,
  firestoreTimeTrackingHandleTasks,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ReportTimeTracking);
