import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
  InputAdornment,
} from "@material-ui/core";
import { VisibilityOutlined, CloudDownloadOutlined, SearchOutlined } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { orderBy } from "lodash";
import ReportDriverLogTable from "./report_driver_log_table";
import { EVENTS } from "../../../config/stringConfig";
import { firestoreGetDriverLogByBranch } from "../../../store/actions/report.action";

class ReportDriverLog extends Component {
  // ====================== Inits ====================== //

  constructor() {
    super();
    this.state = {
      from: moment().startOf("isoWeek"),
      to: moment().endOf("isoWeek"),
      loading: false,
      by: "createAt",
      order: "desc",
      driver: null,
      event: null,
    };
  }

  refineData = () => {
    const { driver, event, order, by } = this.state;
    const { driverLogs } = this.props;
    let reportData = driverLogs && orderBy(driverLogs, by, order);
    if (driverLogs && driver) {
      reportData = reportData?.filter((item) => item?.driver === driver?.id);
    }
    if (event) {
      reportData = reportData?.filter((item) => item.event === event);
    }
    this.setState({
      loading: false,
      reportData,
    });
  };

  // ====================== Functions ====================== //

  handleDateChange = (date, id) => {
    this.setState({
      [id]: date,
    });
  };

  handleSearchChange = (e) => {
    this.setState({
      search: e.target.value,
    });
  };

  handleSelectEvent = (e) => {
    this.setState(
      {
        event: e.target.value,
      },
      () => this.refineData()
    );
  };

  selectDriver = (driver) => {
    this.setState({ driver }, () => this.refineData());
  };

  handleSort = (id) => {
    const { by, order } = this.state;
    const isAsc = by === id && order === "asc";
    this.setState(
      {
        order: isAsc ? "desc" : "asc",
        by: id,
      },
      () => {
        this.refineData();
      }
    );
  };

  // Select
  toggleSelectRecord = (data) => {
    const { selected } = this.state;
    let newCheck = [];
    const exist = selected && selected.filter((c) => c.itemCode === data.itemCode).length > 0;
    if (exist) {
      newCheck = selected && selected.filter((c) => !(c.itemCode === data.itemCode));
    } else {
      newCheck = [...selected, data];
    }
    this.setState({
      selected: newCheck,
    });
  };

  toggleCheckAll = () => {
    const { selected, data } = this.state;
    let isSelectedAll = false;
    if (selected && data && data.length === selected.length) {
      isSelectedAll = true;
    }
    this.setState({
      selected: isSelectedAll ? [] : data,
    });
  };

  // ====================== Firebase Functions ====================== //

  viewDriverLogReport = () => {
    const { branch } = this.props;
    const { from, to } = this.state;
    if (from && to && branch) {
      this.setState(
        {
          loading: true,
        },
        () => {
          this.props.firestoreGetDriverLogByBranch({ branch, from, to }, () => {
            this.setState(
              {
                loading: false,
              },
              () => this.refineData()
            );
          });
        }
      );
    }
  };

  // ====================== Render Component ====================== //

  renderRow02 = () => {
    const { branch } = this.props;
    const { from, to } = this.state;
    const disabled = !branch;
    return (
      <Grid container spacing={3} direction="row" alignItems="center">
        <Grid item md={2} xs={12}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              autoOk
              className="date-picker"
              variant="inline"
              inputVariant="outlined"
              fullWidth
              value={from}
              maxDate={to}
              label="From"
              format="DD/MM/YYYY"
              InputAdornmentProps={{ position: "end" }}
              onChange={(date) => this.handleDateChange(date, "from")}
              margin="dense"
              disabled={disabled}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item md={2} xs={12}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              autoOk
              className="date-picker"
              variant="inline"
              inputVariant="outlined"
              fullWidth
              minDate={from}
              value={to}
              label="To"
              format="DD/MM/YYYY"
              InputAdornmentProps={{ position: "end" }}
              onChange={(date) => this.handleDateChange(date, "to")}
              margin="dense"
              disabled={disabled}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item>{this.renderViewButton()}</Grid>
        <Grid item>{this.renderDownloadButton()}</Grid>
      </Grid>
    );
  };

  renderViewButton = () => {
    const { branch } = this.props;
    const { from, to, loading } = this.state;
    const disabled = !(from && to && branch && !loading);
    if (loading) {
      return (
        <Button
          variant="contained"
          color="primary"
          disabled
          style={{ marginTop: 3 }}
          startIcon={<CircularProgress size={15} />}
        >
          View
        </Button>
      );
    }
    return (
      <Button
        variant="contained"
        color="primary"
        disabled={disabled}
        style={{ marginTop: 3 }}
        startIcon={<VisibilityOutlined />}
        onClick={() => this.viewDriverLogReport()}
      >
        View
      </Button>
    );
  };

  renderDownloadButton = () => {
    const { contacts } = this.props;
    const { loading, reportData } = this.state;
    const disabled = !(reportData && contacts) || loading;
    return (
      <Button
        variant="contained"
        color="primary"
        disabled={disabled}
        style={{ marginTop: 3 }}
        startIcon={<CloudDownloadOutlined />}
      >
        Download
      </Button>
    );
  };

  renderRow03 = () => {
    return (
      <Grid container spacing={3} direction="row" alignItems="center">
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {this.renderSearchAutocomplete()}
        </Grid>
        <Grid item lg={2} md={6} sm={12} xs={12}>
          {this.renderEventDropDown()}
        </Grid>
      </Grid>
    );
  };

  renderSearchAutocomplete = () => {
    const { users, branch } = this.props;
    const { driver } = this.state;
    const disabled = !(users && branch);
    const options = users?.filter((item) => item.branch === branch) || [];
    return (
      <Autocomplete
        id="asynchronous-search"
        onChange={(e, value) => this.selectDriver(value)}
        value={driver}
        getOptionSelected={(option) => option}
        getOptionDisabled={(option) => !option.active}
        getOptionLabel={(option) => {
          const firstName = option.firstName;
          const lastName = option.lastName;
          const fullName = firstName && lastName && `${firstName} ${lastName}`;
          return `${fullName} (${option.username})`;
        }}
        options={options}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="dense"
            label="Search By Name"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    );
  };

  renderEventDropDown = () => {
    const { event } = this.state;
    const menuItem = EVENTS.map((item, index) => {
      return (
        <MenuItem key={index} value={item}>
          {item}
        </MenuItem>
      );
    });
    return (
      <FormControl variant="outlined" margin="dense" fullWidth className="table-select">
        <InputLabel id="eventLabel">Event</InputLabel>
        <Select
          labelId="eventLabel"
          id="Event"
          name="event"
          value={event || ""}
          onChange={(e) => this.handleSelectEvent(e)}
          label="Event"
        >
          <MenuItem value={null}>{"All Event"}</MenuItem>
          {menuItem}
        </Select>
      </FormControl>
    );
  };

  // ====================== Render Main ====================== //

  render() {
    const { auth } = this.props;
    if (!auth.uid) return <Redirect to={"/signin"} />;
    const { reportData, selected, by, order, loading } = this.state;
    return (
      <React.Fragment>
        {this.renderRow02()}
        {this.renderRow03()}
        <ReportDriverLogTable
          reportData={reportData}
          selected={selected}
          order={order}
          orderBy={by}
          loading={loading}
          handleSort={this.handleSort}
          handleCheckAll={this.toggleCheckAll}
          handleCheck={this.toggleSelectRecord}
          handleUpdate={this.refineData}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  users: state.firebaseWeb.users,
  driverLogs: state.reportReducer.driverLogs,
});

const mapDispatchToProps = {
  firestoreGetDriverLogByBranch,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ReportDriverLog);
