import React, { Component} from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { 
    Grid,
    TextField,
    Paper,
    CardContent,
    Typography,
    colors,
    Button,
    InputAdornment,
    CircularProgress,
} from '@material-ui/core';
import { sumBy } from 'lodash';
import moment from 'moment';
import { LockOutlined } from '@material-ui/icons'
import { 
    firestoreAddFormula,
    firestoreUpdateFormula,
    firestoreGetCompletedOrderFomular
} from '../../../store/actions/firebase.action';
import { WORKTIME } from '../../../config/stringConfig';

class FormulaWorkTime extends Component {

// ====================== Inits ====================== //

    constructor() {
        super();
        this.state = {
            loading: false,
            error: '',
            revenue: 0,
            leave: 0,
            hourRate: 0,
            fixedHourInWeek: 0,
            lock: true,
            testRevenue: null,
            testTarget: null,
        };
    }

    // Chuyển state từ props
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.branch !== prevState.branch) {
            const { formulas, branch } = nextProps;
            const fomular = formulas && formulas.find(item => item.type === WORKTIME );
            if (fomular) {
                return { 
                    revenue: fomular.revenue,
                    leave: fomular.leave,
                    hourRate: fomular.hourRate,
                    fixedHourInWeek: fomular.fixedHourInWeek,
                    lock: true,
                    branch,
                    id: fomular.id,
                };
            }
        }
        return null;
    }
    
    // ====================== Functions ====================== //

    unlock = () => {
        this.setState({
            lock: false,
        })
    }

    handleChange = (e) => {
        const value = parseFloat(e.target.value);
        this.setState({
            [e.target.id]: value,
        })
    }

    calculateRate = (amount, fixedLength) => {
        const { revenue, leave, hourRate, fixedHourInWeek } = this.state;
        const revenueAmount = (revenue * amount) / 100;
        const leaveAmount = (revenueAmount * leave) / 100;
        const target = ((revenueAmount - leaveAmount ) / hourRate) - (fixedHourInWeek * fixedLength);
        return target.toLocaleString('en-US', { maximumFractionDigits: 2 }) || 0;
    }

    handleTest = () => {
        const { users, branch } = this.props;
        const fixedList = users && users.filter(item => item.salary === 'fixed' && item.branch === branch);
        const fixedLength = fixedList && fixedList.length;
        this.setState({
            loading: true,
        }, () => {
            this.props.firestoreGetCompletedOrderFomular({ branch }, () => {
                const { completeList } = this.props;
                const revenue = completeList && sumBy(completeList, 'subTotal');
                const testTarget = this.calculateRate(revenue, fixedLength);
                const testRevenue = revenue.toFixed(2);
                this.setState({
                    loading: false,
                    testRevenue,
                    testTarget,
                })
            });
        });
    }

    // ====================== Firebase Functions ====================== //

    handleUpdateWorkTime = () => {
        const { revenue, leave, hourRate, fixedHourInWeek, id } = this.state;
        const { branch } = this.props;
        const formula = {
            id,
            type: 'Worktime',
            revenue,
            leave,
            hourRate,
            fixedHourInWeek,
            branch,
        };
        this.setState({
            lock: true,
        }, () => {
            if (id) {
                this.props.firestoreUpdateFormula({ formula });
            } else {
                this.props.firestoreAddFormula({ formula }, (newId) => {
                    this.setState({
                        id: newId,
                    });
                });
            }
        })
    }
    
    // ====================== Render Component ====================== //

    renderRevenue = () => {
        const { revenue, lock } = this.state;
        return (
            <TextField 
                id="revenue"
                fullWidth
                margin='dense'
                variant="outlined"
                label='Last week revenue'
                type="number"
                value={revenue}
                onChange={this.handleChange}
                disabled={lock}
                InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
            />
        )
    }

    renderLeaveContribution = () => {
        const { leave, lock } = this.state;
        return (
            <TextField 
                id="leave"
                fullWidth
                margin='dense'
                variant="outlined"
                type="number"
                label='Leave contribution'
                value={leave}
                onChange={this.handleChange}
                disabled={lock}
                InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
            />
        )
    }

    renderHourRate = () => {
        const { hourRate, lock } = this.state;
        return (
            <TextField 
                id="hourRate"
                fullWidth
                margin='dense'
                variant="outlined"
                type="number"
                label='Hour rate ($/hr)'
                value={hourRate}
                onChange={this.handleChange}
                disabled={lock}
                InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
            />
        )
    }

    renderFixedHourInWeek = () => {
        const { fixedHourInWeek, lock } = this.state;
        return (
            <TextField 
                id="fixedHourInWeek"
                fullWidth
                margin='dense'
                variant="outlined"
                type="number"
                label='Fixed rate (hrs/week)'
                value={fixedHourInWeek}
                onChange={this.handleChange}
                disabled={lock}
                InputProps={{
                    endAdornment: <InputAdornment position="end">hrs</InputAdornment>,
                }}
            />
        )
    }

    renderFomularInput = () => {
        return (
            <Grid 
                container 
                spacing={3} 
                direction="row"
                justify="flex-start"
                alignItems="center"
            >
                <Grid item lg={2} md={3} sm={3} xs={12}>
                    {this.renderRevenue()}
                </Grid> 
                <Grid item lg={2} md={3} sm={3} xs={12}>
                    {this.renderLeaveContribution() }
                </Grid>
                <Grid item lg={2} md={3} sm={3} xs={12}>
                    {this.renderHourRate()}
                </Grid>
                <Grid item lg={2} md={3} sm={3} xs={12}>
                    {this.renderFixedHourInWeek()}
                </Grid>
            </Grid>
        )
    }

    renderUpdateButton = () => {
        const { lock } = this.state;
        if (lock) {
            return (
                <Button 
                    variant="contained" 
                    color="default"
                    startIcon={<LockOutlined />}
                    onClick={() => this.unlock()}
                >
                    Edit
                </Button>
            )
        }
        return (
            <Button 
                variant="contained"
                style={{ backgroundColor: colors.green[500], color: '#fff' }}
                onClick={() => this.handleUpdateWorkTime()}
            >
                Update
            </Button>
        )
    }

    renderExampleTest = () => {
        const { branch } = this.props;
        const { testRevenue, testTarget, loading, lock } = this.state;
        if (!lock) {
            return null;
        }
        if (testRevenue && testTarget) {
            const from = moment().subtract(7, 'days').format('ddd DD/MM/YYYY');
            const to = moment().subtract(1, 'day').format('ddd DD/MM/YYYY');
            return (
                <p> Test data: Last 7 days ({from} - {to}) revenue is <span style={{ color: 'green' }}>${testRevenue}</span>, target will be <span style={{ color: 'green' }}>{testTarget}</span> hrs</p>
            )
        }
        if (loading) {
            return (
                <Button
                    variant='outlined'
                    disabled
                    startIcon={<CircularProgress size={15} color='#fff'/>}
                >
                    Calculating
                </Button>
            );
        }
        return (
            <Button 
                variant='outlined'
                onClick={() => this.handleTest()}
                disabled={!branch}
            >
                Test Worktime Formula
            </Button>
        )
    }

    // ====================== Render Main ====================== //

    render() {
        const { revenue, leave, hourRate, fixedHourInWeek, id } = this.state;
        const { users, branch } = this.props;
        const fixedList = users && users.filter(item => item.salary === 'fixed' && item.branch === branch);
        const fixedLength = fixedList && fixedList.length;
        if (branch) {
            return (
                <Paper elevation={3} >
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            Worktime { id ? '' : '- inactive'}
                        </Typography>
                        { this.renderFomularInput() }
                        <p> Formula: Target = ({revenue}% last week revenue - {leave}% leave contribution (based on {revenue}% last week revenue)) / {hourRate} - ({fixedHourInWeek} * {fixedLength})</p>
                        <p>{ this.renderExampleTest() }</p>
                        { this.renderUpdateButton() }
                    </CardContent>
                </Paper>
            )
        }
        return null;
    }
}

const mapStateToProps = (state) => ({
    auth: state.firebase.auth,
    users: state.firebaseWeb.users,
    formulas: state.firebaseWeb.formulas,
    completeList: state.firebaseWeb.completeList,
});

const mapDispatchToProps = {
    firestoreAddFormula,
    firestoreUpdateFormula,
    firestoreGetCompletedOrderFomular
}

export default compose(connect(mapStateToProps,mapDispatchToProps))(FormulaWorkTime)
