import React, { Component} from 'react';
import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle,
    CircularProgress,
    FormControl,
    InputLabel,
    OutlinedInput,
    FormHelperText,
    Grid,
    InputAdornment,
    IconButton
} from '@material-ui/core';
import { 
  Visibility,
  VisibilityOff
} from '@material-ui/icons';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { 
  firestoreAddContactsPassword
} from '../../../store/actions/firebase.action';

class ContactDialogPassword extends Component {

  // ====================== Inits ====================== //
    
    constructor() {
        super();
        // state
        this.state = {
            error: '',
            loading: false,
            newPassword: '',
            show: false,
        };
    }

    resetData = () => {
      const { contact } = this.props;
      const newPassword = (contact && contact.password) || '';
      const emailAddress = contact && contact.emailAddress;
      this.setState({
        error: emailAddress ? '' : 'Email is required for generate account',
        loading: false,
        newPassword,
      })
    }

    // ====================== Functions ====================== //

    handleChange = (e) => {
      const value = e.target.value;
      this.setState({
        newPassword: value,
      });
    }

    handleClickShowPassword = () => {
      const { show } = this.state;
      this.setState({
        show: !show,
      });
    }

    handleConfirm = () => {
      const { newPassword } = this.state;
      const { contact, contacts } = this.props;
      if (newPassword) {
        this.setState({
          loading: true,
          error: '',
        }, () => {
            this.props.firestoreAddContactsPassword({ contact, newPassword, contacts }, () => {
              this.setState({
                loading: false,
                error: '',
              }, () => this.props.handleSuccess());
            });
        })
      }
    }

    handleCancel = () => {
      this.setState({
        loading: false,
        newPassword: '',
      }, () => {
        this.props.handleClose()
      })
    }

    // ====================== Firebase Functions ====================== //

    // ====================== Render Component ====================== //

    renderUpdateButton = () => {
      const { newPassword, loading, error } = this.state;
      const disabled = !newPassword || (newPassword && newPassword.length < 6) || error;
      if (loading) {
        return (
          <Button 
            type="submit"
            variant="contained"
            className='add-btn'
            startIcon={<CircularProgress size={15}/>}
            disabled
          >
            Setting...
          </Button>
        );
      }
      return (
        <Button 
          type="submit"
          variant="contained"
          className='add-btn'
          style={{ backgroundColor: disabled ? '#999' : 'green', color: '#fff' }}
          disabled={disabled}
          onClick={() => this.handleConfirm()}
        >
          Set Password
        </Button>
      );
    }

    renderPasswordInput = () => {
      const { error, newPassword, show } = this.state;
      const { contact } = this.props;
      const emailAddress = contact && contact.emailAddress;
      const type = show ? 'text' : 'password';
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            Email: {emailAddress}
          </Grid>
          <Grid item xs={12}>
            <FormControl 
              fullWidth 
              variant="outlined" 
              margin='dense'
              error={!!error}
            >
                <InputLabel 
                  htmlFor="outlined-adornment-password" 
                  required
                >
                  Password
                </InputLabel>
                <OutlinedInput
                    id="outlined-adornment-password"
                    value={newPassword}
                    onChange={this.handleChange}
                    type={type}
                    disabled={error}
                    autoComplete='new-password'
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword}
                            >
                            {show ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                /> 
                <FormHelperText >{error}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      );
    }

    // ====================== Render Main ====================== //

    render() {
      const { loading } = this.state;
      const { dialogOpen, handleClose, contact } = this.props;
      const contactName = contact && contact.contactName;
      return (
        <Dialog
          open={dialogOpen}
          onClose={handleClose}
          onEnter={this.resetData}
          scroll='paper'
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          maxWidth='sm'
          fullWidth
        >
        <DialogTitle id="scroll-dialog-title">Change {contactName} login password</DialogTitle>
        <DialogContent dividers className='container-csv-upload one-file'>
          { this.renderPasswordInput() }
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => this.handleCancel()}
            disabled={loading}
          >
              Cancel
          </Button>
          { this.renderUpdateButton() }
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  contacts: state.firebaseWeb.contacts,
});
  

const mapDispatchToProps = {
  firestoreAddContactsPassword
}
  
export default compose(connect(mapStateToProps,mapDispatchToProps))(ContactDialogPassword)
  