import React, { Component} from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { 
    CssBaseline,
    Container, 
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    // Button,
    // CircularProgress
} from '@material-ui/core';
import { 
    firestoreGetBranchList,
    firestoreGetReport30Day,
} from  '../../store/actions/firebase.action';
import Navbar from '../00_Nav/nav_bar';
import RightNav from '../00_Nav/right_nav';
import ReportSaleLabor30Day from './component/report_sale_labor_30_day';
// import { generate30day } from '../../util/generate.function';

class ReportDashboard extends Component {

// ====================== Inits ====================== //

    constructor() {
        super();
        this.state = {
            branch: '',
            loading30Day: false,
        };
    }

    componentDidMount = () => {
        this.props.firestoreGetBranchList();// get Branch
    }

    updateData = () => {
        const { branch } = this.state;
        this.setState({
            loading30Day: true,
        }, () => {
            this.props.firestoreGetReport30Day({ branch }, () => {
                this.setState({
                    loading30Day: false,
                });
            });
        })
    }
    
    // ====================== Functions ====================== //

    handleSelect = (e, id) => {
        this.setState({
            [id]: e.target.value,
        }, () => {
            this.updateData();
        });
    }

    // ====================== Firebase Functions ====================== //

    // generate30DayReport = () => {
    //     const { branch } = this.state;
    //     if (branch) {
    //         this.setState({
    //             loading: true,
    //         }, () => {
    //             generate30day({ branch }, () => {
    //                 this.setState({
    //                     loading: false,
    //                 })
    //             });
    //         });
    //     }
    // }

    // ====================== Render Component ====================== //

    renderBranchDropDown = () => {
        const { branchs } = this.props;
        const { branch } = this.state;
        const menuItem = branchs && branchs.map((branch, index) => {
            return (
                <MenuItem key={index} value={branch.id}>{branch.tradingName}</MenuItem>
            );
        });
        return (
            <FormControl 
                variant="outlined"
                margin='dense'
                fullWidth
                className='table-select'
            >
                <InputLabel id="branchLabel">Branch</InputLabel>
                <Select
                    labelId="branchLabel"
                    id="branch"
                    value={branch || ''}
                    onChange={(event) => this.handleSelect(event, 'branch')}
                    label="Branch"
                >
                    { menuItem }
                </Select>
            </FormControl>
        );
    }

    renderRowTop = () => {
        return (
            <Grid 
                container 
                spacing={3} 
                direction="row"
                // justify="flex-start"
            >
                {/* <Grid item>
                    <h3 
                        className="regular-title"
                        style={{ marginTop: 10, fontSize: 20, marginBottom: 0 }}
                    >Dashboard</h3>
                </Grid> */}
                <Grid item md={4} xs={12}>
                    { this.renderBranchDropDown() }
                </Grid>
                {/* <Grid item md={2} xs={4}>
                    { this.render30DayButton() }
                </Grid> */}
            </Grid>
        );
    }

    // render30DayButton = () => {
    //     const { branch, loading } = this.state;
    //     const disabled = !(branch && !loading);
    //     if (loading) {
    //         return (
    //             <Button
    //                 variant="contained"
    //                 color="primary"
    //                 disabled
    //                 style={{ marginTop: 10 }}
    //                 startIcon={<CircularProgress size={15}/>}
    //             >
    //                 30 day
    //             </Button>
    //         );
    //     }
    //     return (
    //         <Button
    //             variant="contained"
    //             color="primary"
    //             disabled={ disabled }
    //             style={{ marginTop: 10 }}
    //             onClick={() => this.generate30DayReport()}
    //         >
    //             30 day
    //         </Button>
    //     );
    // }
    
    renderChartsContainer = () => {
        const { loading30Day } = this.state;
        return (
            <Grid 
                container 
                spacing={3} 
                direction="row"
                // justify="flex-start"
            >
                <Grid item lg={12} md={12} sm={12} xs={12} className='chart-container'>
                    <h3 className='report-title'>Sales & labour hrs last 30 days</h3>
                    <ReportSaleLabor30Day loading={loading30Day} />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} className='chart-container'>
                    <h3 className='report-title'>Sales & labour hrs last 6 months</h3>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} className='chart-container'>
                    <h3 className='report-title'>Sales / labour hrs last 6 months</h3>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} className='chart-container'>
                    <h3 className='report-title'>Top 10 clients</h3>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} className='chart-container'>
                    <h3 className='report-title'>Top 10 items</h3>
                </Grid>
            </Grid>
        );
    }

    // ====================== Render Main ====================== //

    render() {
        const { auth } = this.props;
        if (!auth.uid) return <Redirect to={'/signin'}/>
        return (
          <div className='root-container'>
            <CssBaseline />
            <Navbar title="Dashboard" right={<RightNav />}/>
            <Container maxWidth='xl' className='report report-time-tracking'>
                { this.renderRowTop() }
                { this.renderChartsContainer() }
            </Container>
          </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.firebase.auth,
    branchs: state.firebaseWeb.branchs,
});

const mapDispatchToProps = {
    firestoreGetBranchList,
    firestoreGetReport30Day,
}

export default compose(connect(mapStateToProps,mapDispatchToProps))(ReportDashboard)
