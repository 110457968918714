import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { 
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    TableSortLabel,
} from '@material-ui/core';
import { 
    BallotOutlined,
    Send,
    LocalLaundryServiceOutlined,
    LocalShippingOutlined,
    AssignmentTurnedInOutlined,
    Block
} from '@material-ui/icons';
import { Loading } from '../../component/loading';
import ReportOrderDialogDetail from './dialogs/report_order_dialog_detail';
import ReportOrderDialogEdit from './dialogs/report_order_dialog_edit';
import { 
    SENT,
    PROCESSING,
    IN_TRANSIT,
    COMPLETED,
    DELIVERY_SCHEDULED,
    CANCELLED
} from '../../config/stringConfig';

class ReportOrderTable extends Component {

// ====================== Inits ====================== //

    constructor() {
        super();
        this.state = {
            edited: [],
            editError: [],
            header: [
                { id: 'date', label: 'Date', align: 'left', sortable: false },
                { id: 'orderId', label: 'Order ID', align: 'left', sortable: true },
                { id: 'contactName', label: 'Contact Name', align: 'left', sortable: true },
                { id: 'emailAddress', label: 'Email', align: 'left', sortable: true },
                { id: 'products', label: 'Products', align: 'right', sortable: true },
                { id: 'subTotal', label: 'Sub Total', align: 'right', sortable: true },
                { id: 'tax', label: 'Tax', align: 'right', sortable: true },
                { id: 'total', label: 'Total', align: 'right', sortable: true },
                { id: 'status', label: 'Status', align: 'center', sortable: true },
                { id: 'note', label: 'Note', align: 'left', sortable: false },
            ],
            dialogSelected: false,
            dialogEdit: false,
            selectedOrder: null,
        };
    }

    // ====================== Functions ====================== //

    
    isSelected = (data) => {
        const { selected } = this.props;
        const exist = selected && selected.find(item => item.itemCode === data.itemCode);
        return exist ? true : false;
    }

    isCheckedAll = () => {
        const { selected, reportData } = this.props;
        return selected && reportData && selected.length === reportData.length ? true : false;
    }

    // Dialog Selected
    openDialogSelected = ({ selectedOrder }) => {
        this.setState({
            dialogSelected: true,
            selectedOrder,
        })
    }

    closeDialogSelected = () => {
        this.setState({
            dialogSelected: false,
            selectedOrder: null,
        })
    }

    // Dialog Edit
    openDialogEdit = () => {
        this.setState({
            dialogEdit: true,
        })
    }

    closeDialogEdit= () => {
        this.setState({
            dialogEdit: false,
        })
    }

    successDialogEdit= (selectedOrder) => {
        this.setState({
            dialogEdit: false,
            selectedOrder,
        }, () => {
            this.props.handleUpdate();
        })
    }

    // ====================== Data Functions ====================== //
     
    // ====================== Firebase Functions ====================== //

    // ====================== Render Component ====================== //

    renderTableHead = () => {
        const { reportData, orderBy, order } = this.props;
        const { header } = this.state;
        const checkAll = this.isCheckedAll();
        const disabled = !(reportData && reportData.length > 0);
        return (
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={checkAll}
                    disabled={disabled}
                    onChange={() => this.props.handleCheckAll()}
                    inputProps={{ 'aria-label': 'select all desserts' }}
                    color='primary'
                  />
                </TableCell>
                { header && header.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding='default'
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        { this.renderTableSortLabel(headCell, disabled)}
                    </TableCell>
                )) }
              </TableRow>
            </TableHead>
        );
    }

    renderTableSortLabel = (headCell, disabled) => {
        const { orderBy, order } = this.props;
        if (headCell.sortable) {
            return (
                <TableSortLabel
                    disabled={disabled}
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={() => this.props.handleSort(headCell.id)}
                >
                    {headCell.label}
                </TableSortLabel>
            );
        } 
        return headCell.label
    }

    renderTableBody = () => {
        const { reportData, loading } = this.props;
        if (!loading) {
            return (
                <TableBody>
                    { reportData && reportData.map((row, index) => this.renderTableRow({ row, index })) }
                </TableBody>
            );
        }
        return <TableBody />;
    }

    renderTableRow = ({ row, index }) => {
        const isItemSelected = this.isSelected(row);
        const labelId = `table-checkbox-${index}`;
        const products = row.products;
        const length = products && products.length;
        return (
            <TableRow
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={index}
                selected={isItemSelected}
                onClick={() => this.openDialogSelected({ selectedOrder: row })}
                style={{ cursor: 'pointer' }}
            >
                <TableCell align='left' padding='checkbox'>
                    <Checkbox
                        checked={isItemSelected}
                        color='primary'
                        inputProps={{ 'aria-labelledby': labelId }}
                        onChange={() => this.props.handleCheck(row)}
                    />
                </TableCell>
                <TableCell align='left'>{row.date}</TableCell>
                <TableCell align='left'>{row.orderId}</TableCell>
                <TableCell align='left'>{row.contactName}</TableCell>
                <TableCell align='left'>{row.emailAddress}</TableCell>
                <TableCell align='right'>{length}</TableCell>
                <TableCell align='right'>{row.subTotal}</TableCell>
                <TableCell align='right'>{row.tax}</TableCell>
                <TableCell align='right'>{row.total}</TableCell>
                <TableCell align='center'>{ this.renderCellStatus({ item: row }) }</TableCell>
                <TableCell align='left'>{row.status === CANCELLED ? row.cancelReason : row.note}</TableCell>
            </TableRow>
        );
    }

    renderCellStatus = ({ item }) => {
        const status = item && item.status;
        switch (status) {
            case SENT:
                return <Send style={{ color: 'red' }} />
            case PROCESSING:
                return <LocalLaundryServiceOutlined style={{ color: '#1976d2' }}/>
            case DELIVERY_SCHEDULED:
                return <LocalShippingOutlined style={{ color: 'orangered' }} />
            case IN_TRANSIT:
                return <LocalShippingOutlined style={{ color: 'green' }}/>
            case COMPLETED:
                return <AssignmentTurnedInOutlined style={{ color: 'green' }}/>
            case CANCELLED:
                return <Block style={{ color: 'red' }}/>
            default:
                return<BallotOutlined style={{ color: '#333' }}  />
        }
    }

    // ====================== Render Modal ====================== //

    // ====================== Render Main ====================== //

    render() {
        const { loading } = this.props;
        const { dialogSelected, dialogEdit, selectedOrder } = this.state;
        return (
            <Paper style={{ width: '100%', marginTop: 10, marginBottom: 30 }}>
                <TableContainer className='report-table'>
                    <Table stickyHeader aria-label="sticky table">
                        { this.renderTableHead() }
                        { this.renderTableBody() }
                    </Table>
                    <Loading loading={loading} />
                </TableContainer>
                <ReportOrderDialogDetail 
                    dialogOpen={dialogSelected}
                    data={selectedOrder}
                    handleClose={this.closeDialogSelected}
                    handleEdit={this.openDialogEdit}
                />
                <ReportOrderDialogEdit 
                    dialogOpen={dialogEdit}
                    data={selectedOrder}
                    handleClose={this.closeDialogEdit}
                    handleSave={this.successDialogEdit}
                />
            </Paper>
        ); 
    }
}


export default compose(connect(null,null))(ReportOrderTable )