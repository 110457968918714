import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  TableSortLabel,
  IconButton,
} from "@material-ui/core";
import { DeleteOutline } from "@material-ui/icons";
import { Loading } from "../../component/loading";
import { firestoreDeleteRoster } from "../../store/actions/roster.action";

class RosterTable extends Component {
  // ====================== Inits ====================== //

  constructor() {
    super();
    this.state = {
      edited: [],
      editError: [],
      header: [
        { id: "createAt", label: "At", align: "left", sortable: true },
        { id: "date", label: "Date", align: "left", sortable: true },
        { id: "staffId", label: "Staff", align: "left", sortable: true },
        { id: "workAt", label: "Start", align: "left", sortable: true },
        { id: "endAt", label: "End", align: "left", sortable: true },
        { id: "#", label: "#", align: "left", sortable: false },
      ],
      dialogSelected: false,
      dialogEdit: false,
      selectedOrder: null,
    };
  }

  // ====================== Functions ====================== //

  isSelected = (data) => {
    const { selected } = this.props;
    const exist = selected && selected.find((item) => item.itemCode === data.itemCode);
    return exist ? true : false;
  };

  isCheckedAll = () => {
    const { selected, reportData } = this.props;
    return selected && reportData && selected.length === reportData.length ? true : false;
  };

  // ====================== Data Functions ====================== //

  // ====================== Firebase Functions ====================== //

  // ====================== Render Component ====================== //

  renderTableHead = () => {
    const { reportData, orderBy, order } = this.props;
    const { header } = this.state;
    const checkAll = this.isCheckedAll();
    const disabled = !(reportData && reportData.length > 0);
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              checked={checkAll}
              disabled={disabled}
              onChange={() => this.props.handleCheckAll()}
              inputProps={{ "aria-label": "select all desserts" }}
              color="primary"
            />
          </TableCell>
          {header &&
            header.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.align}
                padding="default"
                sortDirection={orderBy === headCell.id ? order : false}
              >
                {this.renderTableSortLabel(headCell, disabled)}
              </TableCell>
            ))}
        </TableRow>
      </TableHead>
    );
  };

  renderTableSortLabel = (headCell, disabled) => {
    const { orderBy, order } = this.props;
    if (headCell.sortable) {
      return (
        <TableSortLabel
          disabled={disabled}
          active={orderBy === headCell.id}
          direction={orderBy === headCell.id ? order : "asc"}
          onClick={() => this.props.handleSort(headCell.id)}
        >
          {headCell.label}
        </TableSortLabel>
      );
    }
    return headCell.label;
  };

  renderTableBody = () => {
    const { reportData, loading } = this.props;
    if (!loading) {
      return <TableBody>{reportData && reportData.map((row, index) => this.renderTableRow({ row, index }))}</TableBody>;
    }
    return <TableBody />;
  };

  renderTableRow = ({ row, index }) => {
    const isItemSelected = this.isSelected(row);
    const labelId = `table-checkbox-${index}`;
    return (
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={index}
        style={{ cursor: "pointer" }}
      >
        <TableCell align="left" padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            color="primary"
            inputProps={{ "aria-labelledby": labelId }}
            onChange={() => this.props.handleCheck(row)}
          />
        </TableCell>
        <TableCell align="left">{row?.at}</TableCell>
        <TableCell align="left">{row?.date}</TableCell>
        <TableCell align="left">{row?.fullName}</TableCell>
        <TableCell align="left">{row?.start}</TableCell>
        <TableCell align="left">{row?.end}</TableCell>
        {this.renderDelete({ row })}
      </TableRow>
    );
  };

  renderDelete = ({ row }) => {
    return (
      <TableCell align="center">
        <IconButton aria-label="delete" onClick={() => this.props.firestoreDeleteRoster({ id: row?.id })}>
          <DeleteOutline style={{ color: "red" }} />
        </IconButton>
      </TableCell>
    );
  };

  // ====================== Render Modal ====================== //

  // ====================== Render Main ====================== //

  render() {
    const { loading } = this.props;
    return (
      <Paper style={{ width: "100%", marginTop: 10, marginBottom: 30 }}>
        <TableContainer className="report-table">
          <Table stickyHeader aria-label="sticky table">
            {this.renderTableHead()}
            {this.renderTableBody()}
          </Table>
          <Loading loading={loading} />
        </TableContainer>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.firebaseWeb.users,
});

const mapDispatchToProps = {
  firestoreDeleteRoster,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(RosterTable);
