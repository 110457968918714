import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import moment from "moment";
import { REF_TOPUP, GET_TOPUP_SUCCESS } from "../ref";

export const firestoreGetTopupByClient = ({ branch, date, contact }, callback) => (dispatch) => {
  const currentUser = firebase.auth().currentUser;
  const owner = currentUser?.uid;
  const ordersRef = firebase.firestore().collection(REF_TOPUP);
  const contactId = contact?.id;
  const fromDate = moment(date).startOf("month")._d;
  const toDate = moment(date).endOf("month")._d;
  ordersRef
    .where("owner", "==", owner)
    .where("branch", "==", branch)
    .where("contactId", "==", contactId)
    .where("createAt", ">=", fromDate)
    .where("createAt", "<=", toDate)
    .get()
    .then((snapshot) => {
      if (snapshot) {
        const data = snapshot.docs.map((doc) => doc.data());
        getTopUpSuccess(dispatch, data);
      }
      if (callback) {
        callback();
      }
    });
};

const getTopUpSuccess = (dispatch, data) => {
  dispatch({
    type: GET_TOPUP_SUCCESS,
    payload: data,
  });
};
