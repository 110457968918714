import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { CssBaseline, Container, Grid, Button } from "@material-ui/core";
import { AddBoxOutlined } from "@material-ui/icons";
import Navbar from "../00_Nav/nav_bar";
import RightNav from "../00_Nav/right_nav";
import BranchDetail from "./branch_detail";
import BranchTable from "./branch_table";
import { firestoreGetBranchList } from "../../store/actions/firebase.action";

class BranchManagement extends Component {
  // ====================== Inits ====================== //

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      selectedBranch: null,
    };
  }

  componentDidMount() {
    this.props.firestoreGetBranchList();
  }

  // ====================== Functions ====================== //

  newBranchData = () => {
    this.setState({
      selectedBranch: { id: null },
    });
  };

  onRowClick = (data) => {
    let selectedBranch = { ...data };
    delete selectedBranch.tableData;
    this.setState({
      selectedBranch,
    });
  };

  // ====================== Firebase Functions ====================== //

  // ====================== Render Component ====================== //

  // ====================== Render Modal ====================== //

  // ====================== Render Main ====================== //

  render() {
    const { auth } = this.props;
    const { selectedBranch } = this.state;
    if (!auth.uid) return <Redirect to={"/signin"} />;
    return (
      <div className="root-container">
        <CssBaseline />
        <Navbar title="Branch Management" right={<RightNav />} />
        <Container maxWidth="xl">
          <Grid container spacing={2} direction="row" justify="center" alignItems="stretch">
            <Grid item md={6} xs={12}>
              <h3 className="regular-title">Branch Details</h3>
              <BranchDetail branch={selectedBranch} onCancel={this.newBranchData} />
            </Grid>
            <Grid item md={6} xs={12} className="grid-user-group">
              <div style={{ flexDirection: "row", display: "flex" }}>
                <h3 className="regular-title" style={{ flex: 1 }}>
                  Branch List
                </h3>
                <Button
                  className="add-btn"
                  variant="outlined"
                  color="primary"
                  startIcon={<AddBoxOutlined />}
                  onClick={() => this.newBranchData()}
                >
                  Add Branch
                </Button>
              </div>
              <BranchTable onRowClick={(data) => this.onRowClick(data)} />
            </Grid>
          </Grid>
        </Container>
              
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
});

const mapDispatchToProps = {
  firestoreGetBranchList,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(BranchManagement);
